import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { useCorifyTable } from '@corify/components/table/provider/use-corify-table';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { type Props as UseCollectionFetchArgs } from '@corify/helpers/api/use-collection-fetch';
import { useCollectionFetchPayload } from '@corify/helpers/api/use-collection-fetch-payload';
import { getDateISO } from '@corify/helpers/format-date';
import { addUrlParameters } from '@corify/helpers/url';
import { addDays } from 'date-fns';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  GDVNotification,
  GDVNotificationRawData,
  GDVNotificationRequestSearchParams,
  GDVNotificationsImportedResponse,
  SortingColumns,
} from '../types';
import { NotificationFilters } from './use-notification-filtering';

export type FMDataEndpoint = {
  endpoint:
    | typeof endpoints.cfm.notifications.available
    | typeof endpoints.cfm.notifications.finishedImports
    | typeof endpoints.cfm.notifications.pausedImports;
};

type GDVNotificationRawDataParams = {
  notificationId: GDVNotification['vehicleNotificationId'];
};

export type UseAvailableDataArgs = Omit<
  UseCollectionFetchArgs<GDVNotificationRequestSearchParams & GDVNotificationRawDataParams>,
  'url'
> &
  FMDataEndpoint;

export const useNotificationsDataV2 = ({ notificationId, endpoint }: UseAvailableDataArgs) => {
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const fetchUrl = `${apiUrl(`${addUrlParameters(endpoint, { brokerId })}`)}`;
  const {
    filtering: {
      dateFilter,
      assignedCustomer,
      pauseReason,
      assignedFleet,
      editorName,
      riskObjectVersionState,
      enterpriseName,
      holder,
      notificationTransactionType,
      assignedUsers,
      licensePlate,
    },
    sorting: { sort, sortDirection },
    pagination: { pageIndex, pageSize },
  } = useCorifyTable<SortingColumns, NotificationFilters>();

  const requestPayload = useMemo(
    () => ({
      page: {
        pageSize,
        pageNumber: pageIndex,
      },
      filters: {
        licensePlate: licensePlate || undefined,
        assignedUsers: assignedUsers?.length ? assignedUsers : undefined,
        notificationTransactionType: notificationTransactionType?.length ? notificationTransactionType : undefined,
        holder: holder || undefined,
        enterpriseName: enterpriseName || undefined,
        assignedFleet: assignedFleet || undefined,
        riskObjectVersionState: riskObjectVersionState?.length ? riskObjectVersionState : undefined,
        editors: editorName?.length ? editorName : undefined,
        pauseReason: pauseReason?.length ? pauseReason : undefined,
        assignedCustomer: assignedCustomer || undefined,
        toDate: dateFilter.to ? getDateISO(addDays(new Date(dateFilter.to), 1)) : undefined,
        fromDate: dateFilter.from ? getDateISO(new Date(dateFilter.from)) : undefined,
      },
      sort: {
        sort,
        sortDirection,
      },
    }),
    [
      assignedCustomer,
      assignedFleet,
      assignedUsers,
      dateFilter.from,
      dateFilter.to,
      editorName,
      enterpriseName,
      holder,
      licensePlate,
      notificationTransactionType,
      pageIndex,
      pageSize,
      pauseReason,
      riskObjectVersionState,
      sort,
      sortDirection,
    ]
  );

  const fetchedData = useCollectionFetchPayload<GDVNotificationsImportedResponse>({
    url: fetchUrl,
    method: 'POST',
    requestPayload,
  });

  const { data, ...notificationRawData } = useSWRImmutable<GDVNotificationRawData>(
    notificationId
      ? `${apiUrl(
          `${addUrlParameters(endpoints.cfm.notifications.rawData, {
            brokerId,
            notificationId,
          })}`
        )}`
      : null,
    swrApiFetcher<GDVNotificationRawData>('GET')
  );

  return {
    fetchedData,
    notificationRawData: {
      data: data?.rawData ?? '',
      ...notificationRawData,
    },
  };
};
