import './checkbox.css';

import { Icon } from '@corify/components/icon/icon';
import { Label } from '@corify/components/inputs/field-label/label';
import { cn } from '@corify/helpers/cn';

export interface Props {
  testId?: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  onChange: (checked: boolean) => void;
  classes?: { label?: string; icon?: string };
  filled?: boolean;
}

export const Checkbox = ({ testId, label, checked, disabled, required, tooltip, onChange, classes, filled }: Props) => {
  const getIcon = () => {
    if (filled) {
      return checked ? 'checkbox_checked_filled' : 'checkbox_unchecked';
    }

    return checked ? 'checkbox_checked' : 'checkbox_unchecked';
  };

  return (
    <Label
      label={label}
      classes={{ root: 'flex items-center', label: cn('pl-2 body2', classes?.label) }}
      isRequired={required}
      tooltip={tooltip}
      data-component="checkbox"
      adornmentLeft={
        <Icon
          className={cn('h-[16px] w-[16px]', classes?.icon, { 'opacity-50': disabled })}
          name={getIcon()}
          onClick={() => {
            if (!disabled) {
              onChange(!checked);
            }
          }}
          data-testid={testId}
        />
      }
    />
  );
};
