import { Multiselect } from '@corify/components/fields/multiselect-field/multiselect';
import { DateRange } from '@corify/components/inputs/date-range/date-range';
import { Item } from '@corify/components/risk-object-status/risk-object-status-static/item';
import { RiskObjectStatusStatic } from '@corify/components/risk-object-status/risk-object-status-static/risk-object-status-static';
import { HeaderCell } from '@corify/components/table/components/header-cell';
import { useCorifyTable } from '@corify/components/table/provider/use-corify-table';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { TooltipInfo } from '@corify/components/tooltip/tooltip-info';
import { AvatarUser } from '@corify/components/user-avatar/avatar-user';
import { UserAvatarList } from '@corify/components/user-avatar-list/user-avatar-list';
import { formatDate } from '@corify/helpers/format-date';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyedMutator } from 'swr';

import {
  GDVNotification,
  GDVNotificationImported,
  GDVNotificationsImportedResponse,
  NotificationColumn,
  SortingColumns,
} from '../types';
import { MAX_NUMBER_OF_AVATARS } from './constants';
import { CustomerSelect } from './customer-select/customer-select';
import { EnterpriseSelect } from './enterprise-select/enterprise-select';
import { FleetSelect } from './fleet-select/fleet-select';
import NotificationApproveRiskRadio from './notification-approve-risk-radio';
import { NotificationColumnFilter } from './notification-column-filter';
import { PauseReasonSelect } from './pause-reason-select';
import { NotificationFilters, useNotificationFiltering } from './use-notification-filtering';
import { RiskIndexSelect } from './risk-index-select/risk-index-select';

type Props = {
  isLocked: boolean | undefined;
  lockColumns: (
    | keyof GDVNotification
    | 'editor'
    | 'editorName'
    | 'riskObjectVersionState'
    | 'riskObjectId'
    | 'riskObjectBusinessVersion'
    | 'brokerId'
  )[];
  selectedNotificationIds: string[];
  columnKeys: NotificationColumn[];
  prependColumns?: ColumnDef<GDVNotificationImported>[];
  mutate: KeyedMutator<GDVNotificationsImportedResponse>;
};

export const useNotificationColumns = ({
  isLocked,
  lockColumns,
  mutate,
  selectedNotificationIds,
  columnKeys,
  prependColumns,
}: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    assginedUsersOptions,
    editorOptions,
    transactionTypeOptions,
    reasonForPausingOptions,
    currentStateOfRiskObjectOptions,
  } = useNotificationFiltering();

  const { filtering, handleSortChange, getSortIcon, setFiltering } = useCorifyTable<
    SortingColumns,
    NotificationFilters
  >();

  const { licensePlate, holder, enterpriseName, assignedFleet, assignedCustomer, dateFilter } = filtering;

  // @ts-expect-error: allow for header templates
  const allColumns: ColumnDef<GDVNotificationImported>[] = useMemo(() => {
    return [
      {
        id: 'licensePlate',
        size: 220,
        accessorKey: 'licensePlate',
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('licensePlate')}
            topContent={t('fleetManagement.columns.vehicle')}
            bottomContent={
              <NotificationColumnFilter
                value={licensePlate}
                onChange={value => setFiltering(prev => ({ ...prev, licensePlate: value }))}
              />
            }
            iconName={getSortIcon('licensePlate')}
          />
        ),
        cell: ({ row }) => (
          <span className="whitespace-pre px-6 py-0 font-bold text-purple">{row.original.licensePlate ?? 'N/A'}</span>
        ),
      },
      {
        id: 'assignedUsers',
        accessorKey: 'assignedUsers',
        size: 350,
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('assignedUsers')}
            iconName={getSortIcon('assignedUsers')}
            topContent={t('fleetManagement.columns.userAssignedToFleet.header')}
            bottomContent={
              <Multiselect
                label=""
                shouldHideLabel
                showTotalItemsSelected
                onChange={value => setFiltering(prev => ({ ...prev, assignedUsers: value }))}
                items={assginedUsersOptions}
                values={[]}
                className="m-0 h-full w-full p-0"
                classNameInput="border-0 py-[10px] px-4"
              />
            }
          />
        ),
        cell: ({ row }) => (
          <div className="flex min-w-[230px] flex-row gap-2 px-6 py-2">
            <UserAvatarList
              users={row.original?.assignedUsers?.map(user => ({ ...user, clientType: 'BROKER' })) as AvatarUser[]}
              maxInlineDisplayedUsers={MAX_NUMBER_OF_AVATARS}
            />
          </div>
        ),
      },
      {
        id: 'notificationTransactionType',
        accessorKey: 'notificationTransactionType',
        size: 220,
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('notificationTransactionType')}
            iconName={getSortIcon('notificationTransactionType')}
            topContent={t('fleetManagement.columns.notificationTransaction.header')}
            bottomContent={
              <Multiselect
                label=""
                shouldHideLabel
                showTotalItemsSelected
                onChange={value => setFiltering(prev => ({ ...prev, notificationTransactionType: value }))}
                items={transactionTypeOptions}
                values={[]}
                className="m-0 h-full w-full p-0"
                classNameInput="border-0 py-[10px] px-4"
              />
            }
          />
        ),
        cell: ({ row }) => (
          <div className="px-6 py-2">
            {row.original.notificationTransactionType ? (
              <Item className="flex bg-purpleLighter text-purpleDark shadow-[0_0_0_1px] shadow-purpleDark">
                {t(`fleetManagement.columns.notificationTransaction.type.${row.original.notificationTransactionType}`)}
              </Item>
            ) : (
              'N/A'
            )}
          </div>
        ),
      },
      {
        id: 'holder',
        accessorKey: 'holder',
        size: 350,
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('holder')}
            iconName={getSortIcon('holder')}
            topContent={t('fleetManagement.columns.holder')}
            bottomContent={
              <NotificationColumnFilter
                value={holder}
                onChange={value => setFiltering(prev => ({ ...prev, holder: value }))}
              />
            }
          />
        ),
        cell: ({ row }) => <div className="px-6 py-2">{row.original.holder}</div> || 'N/A',
      },
      {
        id: 'selectedEnterprise',
        accessorKey: 'selectedEnterprise',
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('selectedEnterprise')}
            iconName={getSortIcon('selectedEnterprise')}
            topContent={
              <>
                {t('fleetManagement.columns.enterprise')}
                <span className="ml-1 text-red-500">*</span>
              </>
            }
            bottomContent={
              <NotificationColumnFilter
                value={enterpriseName}
                onChange={value => setFiltering(prev => ({ ...prev, enterpriseName: value }))}
              />
            }
          />
        ),
        cell: ({ row }) => {
          const locked = lockColumns.includes('selectedEnterprise') || isLocked;

          return (
            <EnterpriseSelect
              selectedEnterprise={row.original.selectedEnterprise}
              vehicleNotificationId={row.original.vehicleNotificationId}
              notificationTransactionType={row.original.notificationTransactionType}
              readonly={locked || selectedNotificationIds.includes(row.original.vehicleNotificationId)}
              mutate={locked ? undefined : mutate}
              customBgOnSelected={row?.getIsSelected() ? 'bg-BG' : undefined}
              cfmV2
            />
          );
        },
        size: 350,
      },
      {
        id: 'assignedFleet',
        accessorKey: 'assignedFleet',
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('assignedFleet')}
            iconName={getSortIcon('assignedFleet')}
            topContent={t('fleetManagement.columns.fleet')}
            bottomContent={
              <NotificationColumnFilter
                value={assignedFleet}
                onChange={value => setFiltering(prev => ({ ...prev, assignedFleet: value }))}
              />
            }
          />
        ),
        cell: ({ row }) => {
          const props =
            lockColumns.includes('assignedFleet') || isLocked
              ? { readonly: true }
              : { readonly: selectedNotificationIds.includes(row.original.vehicleNotificationId), mutate };

          return (
            <FleetSelect
              vehicleNotificationId={row.original.vehicleNotificationId}
              assignedFleet={row.original.assignedFleet}
              selectedEnterprise={row.original.selectedEnterprise}
              notificationTransactionType={row.original.notificationTransactionType}
              customBgOnSelected={row?.getIsSelected() ? 'bg-BG' : undefined}
              cfmV2
              {...props}
            />
          );
        },
        size: 350,
      },
      {
        id: 'assignedCustomer',
        accessorKey: 'assignedCustomer',
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('assignedCustomer')}
            iconName={getSortIcon('assignedCustomer')}
            topContent={
              <>
                {t('fleetManagement.columns.assignedCompany')}
                <span className="ml-1 text-red-500">*</span>
              </>
            }
            bottomContent={
              <NotificationColumnFilter
                value={assignedCustomer}
                onChange={value => setFiltering(prev => ({ ...prev, assignedCustomer: value }))}
              />
            }
          />
        ),
        cell: ({ row }) => {
          const props =
            lockColumns.includes('assignedCustomer') || isLocked
              ? { readonly: true }
              : { readonly: selectedNotificationIds.includes(row.original.vehicleNotificationId), mutate };

          return (
            <CustomerSelect
              vehicleNotificationId={row.original.vehicleNotificationId}
              assignedCustomer={row.original.assignedCustomer}
              selectedEnterprise={row.original.selectedEnterprise}
              notificationTransactionType={row.original.notificationTransactionType}
              customBgOnSelected={row?.getIsSelected() ? 'bg-BG' : undefined}
              cfmV2
              {...props}
            />
          );
        },
        size: 350,
      },
      {
        id: 'vehicleRiskIndex',
        accessorKey: 'vehicleRiskIndex',
        header: (
          <HeaderCell
            topContent={
              <>
                {!isLocked && !lockColumns.includes('vehicleRiskIndex') && (
                  <TooltipInfo position="bottom" tooltip={t('fleetManagement.columns.vehicleRiskIndexTooltip')} />
                )}
                <span className="ml-2">{t('fleetManagement.columns.vehicleRiskIndex')}</span>
                <span className="ml-1 text-red-500">*</span>
              </>
            }
          />
        ),
        cell: ({ row }) => {
          const props =
            lockColumns.includes('vehicleRiskIndex') || isLocked
              ? { readonly: true }
              : { readonly: selectedNotificationIds.includes(row.original.vehicleNotificationId), mutate };

          return (
            <RiskIndexSelect
              vehicleNotificationId={row.original.vehicleNotificationId}
              vehicleType={row.original.vehicleType}
              vehicleRiskIndex={row.original.vehicleRiskIndex}
              notificationTransactionType={row.original.notificationTransactionType}
              customBgOnSelected={row?.getIsSelected() ? 'bg-BG' : undefined}
              vehicleRiskIndexProbabilities={row.original.vehicleRiskIndexProbabilities || []}
              cfmV2
              {...props}
            />
          );
        },
        size: 350,
      },
      {
        id: 'pauseReason',
        accessorKey: 'pauseReason',
        header: (
          <HeaderCell
            onTopContentClick={() => handleSortChange('pauseReason')}
            iconName={getSortIcon('pauseReason')}
            topContent={t('fleetManagement.columns.pauseReason')}
            bottomContent={
              <Multiselect
                label=""
                shouldHideLabel
                showTotalItemsSelected
                onChange={value => setFiltering(prev => ({ ...prev, pauseReason: value }))}
                items={reasonForPausingOptions}
                values={[]}
                className="m-0 h-full w-full p-0"
                classNameInput="border-0 py-[10px] px-4"
              />
            }
          />
        ),
        cell: ({ row }) => (
          <PauseReasonSelect
            {...row.original}
            readonly
            shouldHideLabel
            customBgOnSelected={row?.getIsSelected() ? 'bg-BG' : undefined}
            cfmV2
          />
        ),
        size: 350,
      },
      {
        id: 'importTime',
        accessorKey: 'importTime',
        header: (
          <HeaderCell
            topContent={t('fleetManagement.columns.importDate')}
            onTopContentClick={() => handleSortChange('importTime')}
            iconName={getSortIcon('importTime')}
            bottomContent={
              <DateRange
                value={dateFilter}
                onChange={value => setFiltering(prev => ({ ...prev, dateFilter: value }))}
                id="available-data-date"
                dividerType="divider"
                textSize="xs"
                classNames={{
                  containerProps: 'flex justify-center',
                  wrapper: 'min-w-[170px] border-0 p-2 text-darkGrey font-normal',
                  icon: 'w-[14px] h-[14px]',
                }}
              />
            }
          />
        ),
        cell: ({ row }) => (
          <div className="px-6 py-2">{formatDate(row.original.importTime, { language: language })}</div>
        ),
        size: 350,
      },
      {
        id: 'approveAutomatically',
        accessorKey: 'approveAutomatically',
        header: (
          <HeaderCell
            topContent={
              <p className="flex flex-col">
                <span>{t('fleetManagement.columns.approveAutomaticallyLine1')}</span>
                <span>{t('fleetManagement.columns.approveAutomaticallyLine2')}</span>
              </p>
            }
            onTopContentClick={() => handleSortChange('approveAutomatically')}
            iconName={getSortIcon('approveAutomatically')}
          />
        ),
        cell: ({ row }) => (
          <div className="px-6 py-2">
            <NotificationApproveRiskRadio {...row.original} readonly={isLocked} />
          </div>
        ),
        size: 220,
      },
      {
        id: 'editors',
        accessorKey: 'editors',
        header: (
          <HeaderCell
            topContent={<>{t('fleetManagement.columns.importedEditorName')} </>}
            onTopContentClick={() => handleSortChange('editors')}
            iconName={getSortIcon('editors')}
            bottomContent={
              <Multiselect
                label=""
                shouldHideLabel
                showTotalItemsSelected
                onChange={value => setFiltering(prev => ({ ...prev, editorName: value }))}
                items={editorOptions}
                values={[]}
                className="m-0 h-full w-full p-0"
                classNameInput="border-0 py-[10px] px-4"
              />
            }
          />
        ),
        cell: ({ row }) => (
          <div className="px-6 py-2">
            <Tooltip overlay={row.original.editor?.email} mouseEnterDelay={0.3} position="top" maxWidth="400px">
              <span>{row.original.editor?.name}</span>
            </Tooltip>
          </div>
        ),
        size: 250,
      },
      {
        id: 'riskObjectVersionState',
        accessorKey: 'riskObjectVersionState',
        header: (
          <HeaderCell
            topContent={
              <p className="flex flex-col">
                <span>{t('fleetManagement.columns.currentStateOfRiskObjectLine1')}</span>
                <span>{t('fleetManagement.columns.currentStateOfRiskObjectLine2')}</span>
              </p>
            }
            onTopContentClick={() => handleSortChange('riskObjectVersionState')}
            iconName={getSortIcon('riskObjectVersionState')}
            bottomContent={
              <Multiselect
                label=""
                shouldHideLabel
                showTotalItemsSelected
                onChange={value => setFiltering(prev => ({ ...prev, riskObjectVersionState: value }))}
                items={currentStateOfRiskObjectOptions}
                values={[]}
                className="m-0 h-full w-full p-0"
                classNameInput="border-0 py-[10px] px-4"
              />
            }
          />
        ),
        cell: ({ row }) =>
          row.original.riskObjectVersionState && (
            <div className="px-6 py-2">
              <RiskObjectStatusStatic status={row.original.riskObjectVersionState} />
            </div>
          ),
        size: 250,
      },
    ];
  }, [
    t,
    licensePlate,
    getSortIcon,
    assginedUsersOptions,
    transactionTypeOptions,
    holder,
    enterpriseName,
    assignedFleet,
    assignedCustomer,
    reasonForPausingOptions,
    dateFilter,
    editorOptions,
    currentStateOfRiskObjectOptions,
    handleSortChange,
    setFiltering,
    lockColumns,
    isLocked,
    selectedNotificationIds,
    mutate,
    language,
  ]);

  const columns = useMemo(
    () => [
      ...(prependColumns ?? []),
      ...allColumns.filter(column => columnKeys.includes(column.id as NotificationColumn)),
    ],
    [allColumns, columnKeys, prependColumns]
  );

  return columns;
};
