import { Search } from '@corify/components/inputs/search/search';
import { DropdownItem } from '@corify/components/inputs/search/types';
import { Loader } from '@corify/components/loader/loader';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { KeyedMutator } from 'swr';

import { GDVNotification, GDVNotificationsImportedResponse, GDVNotificationTransaction } from '../../types';
import { AssignedFleetPatch, useNotificationPatch } from '../use-notification-patch';
import { CustomersWithFleet, NotificationFleet, NotificationFleetId, NotificationFleetsResponse } from './types';

type Props = {
  vehicleNotificationId: GDVNotification['vehicleNotificationId'];
  assignedFleet: GDVNotification['assignedFleet'];
  selectedEnterprise: GDVNotification['selectedEnterprise'];
  notificationTransactionType: GDVNotification['notificationTransactionType'];
  readonly?: boolean;
  mutate?: KeyedMutator<GDVNotificationsImportedResponse> | undefined;
  cfmV2?: boolean;
  customBgOnSelected?: string;
};

const mapFleetToDropdownItem = (
  fleet?: NotificationFleet,
  enterpriseName = ''
): DropdownItem<NotificationFleetId> | undefined => {
  if (!fleet || !enterpriseName) {
    return undefined;
  }
  const { shortDescription, riskObjectId, riskObjectBusinessVersion } = fleet;

  return {
    label: shortDescription,
    value: {
      riskObjectId,
      riskObjectBusinessVersion,
    },
    groupName: enterpriseName,
  };
};

const mapCompanyToAvailableFleets = (companies?: CustomersWithFleet): DropdownItem<NotificationFleetId>[] => {
  return companies?.fleets.map(fleet => mapFleetToDropdownItem(fleet, companies.customerName)!) ?? [];
};

export const FleetSelect = ({
  vehicleNotificationId,
  assignedFleet,
  selectedEnterprise,
  notificationTransactionType,
  readonly,
  mutate,
  cfmV2,
  customBgOnSelected,
}: Props) => {
  const { t } = useTranslation();
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const [dataFetchInitialized, setDataFetchInitialized] = useState(false);
  const { patchNotification } = useNotificationPatch<AssignedFleetPatch>(
    vehicleNotificationId,
    brokerId,
    'assignedFleet'
  );

  const selectedFleet = useMemo(
    () => mapFleetToDropdownItem(assignedFleet, selectedEnterprise?.enterpriseName),
    [assignedFleet, selectedEnterprise?.enterpriseName]
  );

  const { data, isLoading } = useSWR<NotificationFleetsResponse>(
    dataFetchInitialized
      ? apiUrl(
          addUrlParameters(endpoints.cfm.notifications.availableFleets, {
            notificationId: vehicleNotificationId,
            brokerId,
          })
        )
      : null,
    swrApiFetcher('GET')
  );

  const availableSearchItems = data?.customers?.flatMap(mapCompanyToAvailableFleets) || [];

  const handleChange = async (selectedItem?: DropdownItem<NotificationFleetId> | null) => {
    await patchNotification(
      selectedItem?.value
        ? {
            riskObjectId: selectedItem?.value.riskObjectId,
            businessVersion: selectedItem?.value.riskObjectBusinessVersion,
          }
        : {
            riskObjectId: null,
            businessVersion: null,
          }
    );

    mutate && (await mutate());
  };

  const handleMenuOpen = async (isOpen?: boolean) => {
    setDataFetchInitialized(isOpen === true);
  };

  const isReadonly =
    readonly ||
    selectedEnterprise?.enterpriseId === undefined ||
    notificationTransactionType !== GDVNotificationTransaction.NewRegistration;

  const additionalStyle = () => {
    if (!cfmV2 || (!selectedFleet && !isReadonly)) {
      return;
    }

    if (customBgOnSelected) {
      return customBgOnSelected;
    }

    if (isReadonly) {
      return 'bg-white';
    }
  };

  return (
    <Search
      testId="select-fleet"
      key={selectedFleet?.label}
      label="Fleet Select"
      placeholder={isReadonly ? ' ' : t('components.dropdown.emptyOption')}
      noItemMessage={(isLoading && <Loader />) || undefined}
      inputTooltip={selectedFleet?.label}
      items={isLoading ? [] : availableSearchItems}
      selectedItem={selectedFleet}
      onChange={handleChange}
      onMenuOpen={handleMenuOpen}
      isReadonly={isReadonly}
      clearable={cfmV2 ? !isReadonly : true}
      showExpandIcon={cfmV2 ? !isReadonly : true}
      shouldHideLabel
      shouldHideError={cfmV2}
      adornmentLeft={null}
      matchAlgorithm="includesAnyWord"
      classNames={{ input: additionalStyle() }}
      hideDefaultBorder={cfmV2}
    />
  );
};
