import 'rc-tooltip/assets/bootstrap.css';
import './tooltip.css';

import ReactTooltip from 'rc-tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import { CSSProperties, ReactElement, useRef, useState } from 'react';

import { MarkdownOverlay } from './markdown-overlay/markdown-overlay';

export type Position = 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

interface Props extends TooltipProps {
  children: ReactElement;
  maxWidth?: string;
  onVisibleChange?: (visible: boolean) => void;
  overlayInnerStyle?: CSSProperties;
  position?: Position;
}

export const Tooltip = ({
  children,
  position = 'top',
  maxWidth = '300px',
  overlayInnerStyle,
  overlay,
  ...props
}: Props) => {
  const [updatedPosition, setUpdatedPosition] = useState<Position | null>(null);
  const toolTipRef = useRef(null);

  if (!overlay) {
    return children;
  }
  const shouldUseMarkdownOverlay = typeof overlay === 'string';

  function updateDistanceToEdgeIfNecessary(e: HTMLElement) {
    const currentRef = toolTipRef?.current;
    const content = e && e.childNodes && (e.childNodes[1] as HTMLElement);

    if (!content || !currentRef) {
      return;
    }

    // @ts-expect-error getBoundingClientRect doesn't work directly in this case
    const tooltipBounds = currentRef.nativeElement?.getBoundingClientRect();
    const bodyBounds = document.body.getBoundingClientRect();
    const distanceToTheRight = bodyBounds.width - (tooltipBounds.x + tooltipBounds.width);
    const minDistance = 100;

    if (distanceToTheRight < minDistance) {
      if (position === 'top') {
        setUpdatedPosition('topRight');
      }

      if (position === 'bottom') {
        setUpdatedPosition('bottomRight');
      }
    }
  }

  return (
    <ReactTooltip
      ref={toolTipRef}
      destroyTooltipOnHide
      overlayInnerStyle={{ ...overlayInnerStyle, maxWidth }}
      placement={updatedPosition || position}
      overlay={shouldUseMarkdownOverlay ? <MarkdownOverlay markdownContent={overlay} /> : overlay}
      onPopupAlign={updateDistanceToEdgeIfNecessary}
      {...props}
    >
      {children}
    </ReactTooltip>
  );
};
