import clsx from 'clsx';
import { ReactNode } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

interface Props {
  active?: boolean;
  children: ReactNode;
  to: string;
}

export const HeaderLink = ({ active = undefined, children, to }: Props) => {
  // TODO: Check why end is used with false value, NavLink should handle active state
  const match = Boolean(useMatch({ end: false, path: to }));
  const isActive = active || match;

  return (
    <NavLink
      className={clsx(
        'body2 relative flex flex-col items-center pr-[72px] text-white',
        isActive && 'font-[700] after:absolute after:-bottom-2 after:w-[32px] after:border-b-2 after:border-corifyGreen'
      )}
      to={to}
    >
      {children}
    </NavLink>
  );
};
