import { ClientProvider, useSelectedClient } from '@corify/context/client/client-provider';
import { useUser } from '@corify/context/user/use-user';
import { addUrlParameters } from '@corify/helpers/url';
import { ADMIN_ROUTES } from '@corify/routes/admin/routes';
import { RISK_CONTRIBUTOR_ROUTES } from '@corify/routes/clients/risk-contributor/routes';
import { CLIENTS_ROUTES } from '@corify/routes/clients/routes';
import { MODELER_ROUTES } from '@corify/routes/modeler/routes';
import { NO_CLIENT_ROUTES } from '@corify/routes/no-client/routes';
import { BASE_ROUTES } from '@corify/routes/routes';
import { CustomerRole, isModeler, isRegularUser } from '@corify/types/user';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ClientBasedRedirect: FC<{ pathname: string }> = ({ pathname }) => {
  const { type, userRoles, id, brokerOfCustomer } = useSelectedClient();
  const { brokerId } = brokerOfCustomer ?? {};

  if (type === 'BROKER') {
    if (
      userRoles.includes('BROKER_KEY_ACCOUNT_MANAGER') ||
      userRoles.some(role => ['INSURER_INSURANCE_EXPERT', 'BROKER_INSURANCE_EXPERT'].includes(role))
    ) {
      const customersUrl = addUrlParameters(RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_ROUTE, {
        clientId: id,
      });

      if (pathname === customersUrl) {
        return <Outlet />;
      }

      return <Navigate to={customersUrl} replace />;
    }

    if (
      userRoles.some(role =>
        ['BROKER_MAIN_CLIENT_ADMIN', 'INSURER_MAIN_CLIENT_ADMIN', 'CUSTOMER_MAIN_CLIENT_ADMIN'].includes(role)
      )
    ) {
      if (pathname === CLIENTS_ROUTES.CLIENTS_ROUTE) {
        return <Outlet />;
      }

      return <Navigate to={CLIENTS_ROUTES.CLIENTS_ROUTE} replace />;
    }
  }

  if (type === 'CUSTOMER') {
    const mcaRole: CustomerRole = 'CUSTOMER_MAIN_CLIENT_ADMIN';
    const riskContributorRoles: CustomerRole[] = ['CUSTOMER_RISK_EXPERT', 'CUSTOMER_RISK_MANAGER'];

    if (userRoles.some(role => riskContributorRoles.includes(role as CustomerRole))) {
      const customersUrl = addUrlParameters(RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_BASIC_DATA_ROUTE, {
        clientId: brokerId!,
        customerId: id,
      });

      if (pathname === customersUrl) {
        return <Outlet />;
      }

      return <Navigate to={customersUrl} replace />;
    }

    if (userRoles.includes(mcaRole)) {
      if (pathname === CLIENTS_ROUTES.CLIENTS_ROUTE) {
        return <Outlet />;
      }

      return <Navigate to={CLIENTS_ROUTES.CLIENTS_ROUTE} replace />;
    }
  }

  return <Navigate to={BASE_ROUTES.NOT_FOUND_ROUTE} />;
};

export const ConditionalRedirect = () => {
  const { type } = useUser();
  const { pathname } = useLocation();

  const allowedRoutes = Object.values({
    ...BASE_ROUTES,
    ...ADMIN_ROUTES,
    ...NO_CLIENT_ROUTES,
    ...MODELER_ROUTES,
    ...CLIENTS_ROUTES,
    ...RISK_CONTRIBUTOR_ROUTES,
  });

  if (allowedRoutes.filter(route => route.includes(pathname)).length === 0) {
    return <Navigate to={BASE_ROUTES.NOT_FOUND_ROUTE} />;
  }

  if (pathname === BASE_ROUTES.NOT_FOUND_ROUTE) {
    return <Outlet />;
  }

  if (isModeler(type)) {
    if (pathname === MODELER_ROUTES.MODELER_ROUTE_CROM) {
      return <Outlet />;
    }

    return <Navigate to={MODELER_ROUTES.MODELER_ROUTE_CROM} replace />;
  }

  if (type === 'CORIFY_ADMIN') {
    if (pathname === ADMIN_ROUTES.CLIENTS_ROUTE) {
      return <Outlet />;
    }

    return <Navigate to={ADMIN_ROUTES.CLIENTS_ROUTE} replace />;
  }

  if (isRegularUser(type)) {
    return (
      <ClientProvider>
        <ClientBasedRedirect pathname={pathname} />
      </ClientProvider>
    );
  }

  if (type === 'WITHOUT_CLIENT_SELECTED') {
    if (pathname === NO_CLIENT_ROUTES.NO_CLIENT_SELECTED_ROUTE) {
      return <Outlet />;
    }

    return <Navigate to={NO_CLIENT_ROUTES.NO_CLIENT_SELECTED_ROUTE} replace />;
  }

  if (type === 'WITHOUT_CLIENT_AVAILABLE') {
    if (pathname === NO_CLIENT_ROUTES.NO_CLIENT_DEFINED_ROUTE) {
      return <Outlet />;
    }

    return <Navigate to={NO_CLIENT_ROUTES.NO_CLIENT_DEFINED_ROUTE} replace />;
  }

  return <Navigate to={BASE_ROUTES.NOT_FOUND_ROUTE} />;
};
