import { Checkbox } from '@corify/components/inputs/checkbox/checkbox';
import { HeaderCell } from '@corify/components/table/components/header-cell';
import { RowExpandButton } from '@corify/components/table/components/row-expand-button';
import { withTableProvider } from '@corify/components/table/provider/with-table-provider';
import { endpoints } from '@corify/constants/endpoints';
import { NotificationPageRef } from '@corify/pages/fleet-management/common/notification-page';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { baseColumns, DEFAULT_NOTIFICATION_TABLE_PROPS } from '../common/constants';
import { NotificationPageV2 } from '../common/notification-page-v2';
import NotificationPauseIcon from '../common/notification-pause-icon';
import NotificationWarningAndReload from '../common/notification-warning-reload';
import PausedImportsActionButton from './paused-imports-action-button';

const PausedImportsV2 = () => {
  const { t } = useTranslation();
  const notificationPageRef = useRef<NotificationPageRef>(null);

  return (
    <NotificationPageV2
      ref={notificationPageRef}
      headerTitle={t('fleetManagement.navigation.pausedImports')}
      endpoint={endpoints.cfm.notifications.pausedImports}
      lockColumns={['selectedEnterprise', 'vehicleRiskIndex', 'assignedFleet', 'assignedCustomer']}
      columnKeys={[...baseColumns, 'pauseReason', 'editors', 'importTime']}
      actionButton={({ selectedIdsForAction, resetView }) => (
        <PausedImportsActionButton selectedIdsForAction={selectedIdsForAction} resetView={resetView} />
      )}
      prependColumns={[
        {
          id: 'vehicleNotificationId',
          size: 150,
          header: ({ table }) => (
            <HeaderCell
              topContent={
                <Checkbox
                  label=""
                  classes={{ icon: 'ml-[40px]' }}
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.toggleAllRowsSelected}
                />
              }
            />
          ),
          cell: ({ row }) => (
            <div className="flex w-[150px] justify-between px-6 py-2">
              <div className="flex items-center gap-3 py-2">
                <RowExpandButton
                  onClick={() => row.toggleExpanded()}
                  isExpanded={row.getIsExpanded()}
                  ariaLabel={row.getIsExpanded() ? 'Collapse' : 'Expand'}
                />
                <Checkbox
                  label=""
                  testId={`select-${row.original.vehicleNotificationId}`}
                  checked={row.getIsSelected()}
                  classes={{ label: 'pl-0' }}
                  onChange={() => row.toggleSelected()}
                />
                <NotificationPauseIcon
                  type="pauseStop"
                  notificationId={row.original.vehicleNotificationId}
                  resetView={notificationPageRef.current?.resetView}
                />
                <NotificationWarningAndReload row={row.original} />
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

const PausedImportsWrapV2 = withTableProvider(PausedImportsV2, DEFAULT_NOTIFICATION_TABLE_PROPS);

export default PausedImportsWrapV2;
