import { Auth0ContextInterface } from '@auth0/auth0-react';

export const isJsonResponse = (response: Response): boolean => {
  const contentType = response.headers.get('content-type');

  return Boolean(contentType && contentType.indexOf('application/json') !== -1);
};

export const logoFetcher =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) => async (args: Request) => {
    const token = await getAccessTokenSilently();

    return fetch(args, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(async res => {
      if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.info = await res.json();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.status = res.status;
        throw error;
      }

      switch (res.status) {
        case 200: {
          const blob = await res.blob();

          // It is a very dirty fix of vitest issue TypeError: The "obj" argument must be an instance of Blob. Received an instance of Blob
          return new Blob([await blob.arrayBuffer()], { type: blob.type });
        }
        case 204: {
          return undefined;
        }
        default: {
          throw new Error(`${res.status} status is not handled`);
        }
      }
    });
  };
