import { Header } from '@corify/components/header/header';
import { HeaderLinks } from '@corify/components/header-links/header-links';
import { Icon } from '@corify/components/icon/icon';
import { useLegalContext } from '@corify/context/legal/legal-context';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { LanguageSelect } from './components/legal/language-select';

type LegalLayoutProps = {
  navigationItems: Array<{ name: string; path: string }>;
};

export const LegalLayout: FC<LegalLayoutProps> = ({ navigationItems }) => {
  const { languageOptions, language, changeLanguage } = useLegalContext();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="flex h-screen w-screen flex-col justify-between overflow-y-auto bg-purpleDarker">
      <div className="mx-auto mb-12 mt-8 w-full max-w-4xl">
        <Header
          fullWidth={false}
          brand={<Icon className="h-11 w-44" name="corify_logo_white" />}
          brandClasses="w-auto"
          links={
            <HeaderLinks
              justify="center"
              links={navigationItems.map(({ path: to, name }) => ({
                to,
                label: t(`pages.legal.${name}`),
                active: location.pathname === to,
              }))}
            />
          }
          actions={<LanguageSelect onChange={changeLanguage} options={languageOptions} value={language} />}
        />

        <div className="prose prose-sm relative mt-8 w-full max-w-none rounded-br-[80px] rounded-tl-[80px] bg-white px-16 pb-10 pt-14 prose-h1:text-xl prose-h1:text-darkPurple prose-h2:text-base prose-h2:text-black prose-p:text-black prose-a:text-purple">
          <Outlet />
        </div>
      </div>
      <footer className="pb-6 pl-8 text-[14px] font-[500] text-white">© {new Date().getFullYear()} Corify GmbH</footer>
    </div>
  );
};
