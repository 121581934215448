import { DropdownRenderOptionProps } from '@corify/components/inputs/search/types';
import clsx from 'clsx';
import { Fragment } from 'react';

import { EnterpriseSearchItem } from './enterprise-select-utils';

export const EnterpriseSelectOption = ({
  index,
  isActiveItem,
  highlightedIndex,
  getItemProps,
  item,
}: DropdownRenderOptionProps<EnterpriseSearchItem>) => (
  <Fragment key={`${item.groupName}-${item.name}-${item.value}`}>
    {item.groupName && index === 0 && (
      <li
        className={clsx('px-4 pb-3 pt-2 text-xs font-bold leading-4 text-purpleLight', {
          'border-t-[1px] border-lightGrey': index !== 0,
        })}
        key={`${item.groupName}-${item.name}`}
      >
        {item.groupName}
      </li>
    )}
    <li
      className={clsx('flex cursor-pointer flex-col px-4 py-2 text-left hover:bg-BG', {
        'bg-BG text-purpleDarker': (isActiveItem && !item.isSubsidiary) || highlightedIndex === index,
      })}
      key={item.name}
      {...getItemProps({ index, item })}
    >
      <div className="text-sm font-semibold">{item.label}</div>
      <div className="text-xs leading-5">{item.name}</div>
    </li>
  </Fragment>
);
