import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  brand?: ReactNode;
  links?: ReactNode;
  actions: ReactNode;
  fullWidth?: boolean;
  brandClasses?: string;
  linksClasses?: string;
  actionsClasses?: string;
}

export const Header = ({
  brand,
  links,
  actions,
  fullWidth = true,
  brandClasses = '',
  linksClasses = '',
  actionsClasses = '',
}: Props) => {
  return (
    <nav className={clsx('flex h-[72px] items-center bg-purpleDarker', fullWidth && 'w-screen')}>
      {brand && <div className={clsx('flex h-full w-[320px] items-center pl-8', brandClasses)}>{brand}</div>}

      <div className={clsx('grow pl-8', linksClasses)}>{links}</div>

      <div className={clsx('flex h-full items-center gap-2 pr-4', actionsClasses)}>{actions}</div>
    </nav>
  );
};
