import { Search } from '@corify/components/inputs/search/search';
import { DropdownItem } from '@corify/components/inputs/search/types';
import { Loader } from '@corify/components/loader/loader';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { KeyedMutator } from 'swr';

import { GDVNotification, GDVNotificationsImportedResponse, GDVNotificationTransaction } from '../../types';
import { GDVEnterpriseSimple } from '../enterprise-select/types';
import { AssignedCustomerPatch, useNotificationPatch } from '../use-notification-patch';
import { NotificationCustomer, NotificationCustomersResponse } from './types';

type Props = {
  vehicleNotificationId: GDVNotification['vehicleNotificationId'];
  assignedCustomer: GDVNotification['assignedCustomer'];
  selectedEnterprise: GDVNotification['selectedEnterprise'];
  notificationTransactionType: GDVNotification['notificationTransactionType'];
  readonly?: boolean;
  mutate?: KeyedMutator<GDVNotificationsImportedResponse> | undefined;
  cfmV2?: boolean;
  customBgOnSelected?: string;
};

const mapCustomerToDropdownItem = (
  customer?: NotificationCustomer,
  enterprise?: GDVEnterpriseSimple | null
): DropdownItem | undefined =>
  customer && enterprise?.enterpriseName
    ? {
        label: customer.name,
        value: customer.customerId,
        percentage: customer.percentage,
      }
    : undefined;

export const CustomerSelect = ({
  vehicleNotificationId,
  assignedCustomer,
  selectedEnterprise,
  notificationTransactionType,
  readonly,
  mutate,
  cfmV2,
  customBgOnSelected,
}: Props) => {
  const { t } = useTranslation();
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const [dataFetchInitialized, setDataFetchInitialized] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { patchNotification } = useNotificationPatch<AssignedCustomerPatch>(
    vehicleNotificationId,
    brokerId,
    'assignedCustomer'
  );

  const selectedCustomer = useMemo(
    () => mapCustomerToDropdownItem(assignedCustomer, selectedEnterprise),
    [assignedCustomer, selectedEnterprise]
  );

  const { data, isLoading } = useSWR<NotificationCustomersResponse>(
    dataFetchInitialized
      ? apiUrl(
          addUrlParameters(endpoints.cfm.notifications.availableCustomers, {
            notificationId: vehicleNotificationId,
            brokerId,
          })
        )
      : null,
    swrApiFetcher('GET')
  );

  const availableSearchItems =
    (data?.subsidiaryCustomers
      ?.map(customer => mapCustomerToDropdownItem(customer, selectedEnterprise))
      ?.filter(Boolean) as DropdownItem[]) || [];

  const handleChange = async (selectedItem?: DropdownItem | null) => {
    setError(selectedItem ? undefined : t('fleetManagement.required'));

    await patchNotification({ assignedCustomerId: selectedItem?.value ? selectedItem?.value : null });
    mutate && (await mutate());
  };

  const handleMenuOpen = async (isOpen?: boolean) => {
    setDataFetchInitialized(isOpen === true);
  };

  const isReadonly =
    readonly ||
    notificationTransactionType !== GDVNotificationTransaction.NewRegistration ||
    !selectedEnterprise?.enterpriseName;

  const additionalStyle = () => {
    if (!cfmV2 || (!selectedCustomer && !isReadonly)) {
      return;
    }

    if (customBgOnSelected) {
      return customBgOnSelected;
    }

    if (isReadonly) {
      return 'bg-white';
    }
  };

  return (
    <Search
      testId="select-customer"
      label="Customer Select"
      placeholder={isReadonly ? ' ' : t('components.dropdown.emptyOption')}
      noItemMessage={(isLoading && <Loader />) || undefined}
      inputTooltip={selectedCustomer?.label}
      items={isLoading ? [] : availableSearchItems}
      selectedItem={selectedCustomer}
      onChange={handleChange}
      onMenuOpen={handleMenuOpen}
      isReadonly={isReadonly}
      isRequired
      clearable={cfmV2 ? !isReadonly : true}
      showExpandIcon={cfmV2 ? !isReadonly : true}
      shouldHideLabel
      shouldHideError={cfmV2}
      adornmentLeft={null}
      matchAlgorithm="includesAnyWord"
      error={error}
      maxTopMatches={3}
      classNames={{ input: additionalStyle() }}
      hideDefaultBorder={cfmV2}
    />
  );
};
