import { Icon } from '@corify/components/icon/icon';
import { cn } from '@corify/helpers/cn';
import { ComponentPropsWithoutRef } from 'react';
import {
  Button,
  ButtonProps,
  Collection,
  Header,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  ListBoxProps,
  Popover,
  PopoverProps,
  Section,
  Select,
  SelectValue,
  SelectValueProps,
  Separator,
  SeparatorProps,
} from 'react-aria-components';

const _Select = Select;

const SelectSection = Section;

const SelectCollection = Collection;

const _SelectValue = <T extends object>({ className, ...props }: SelectValueProps<T>) => (
  <SelectValue
    className={values =>
      cn('data-[placeholder]:text-muted-foreground', typeof className === 'function' ? className(values) : className)
    }
    {...props}
  />
);

const SelectTrigger = ({
  className,
  children,
  iconClass,
  isOpen,
  ...props
}: ButtonProps & { iconClass?: string; isOpen?: boolean }) => {
  return (
    <Button
      className={values =>
        cn(
          'border-input ring-offset-background placeholder:text-muted-foreground focus:ring-ring flex h-9 w-full items-center justify-between border bg-transparent px-3 py-2 text-sm shadow-sm focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
          typeof className === 'function' ? className(values) : className
        )
      }
      {...props}
    >
      {values => (
        <>
          {typeof children === 'function' ? children(values) : children}
          {isOpen !== undefined && (
            <Icon name={!isOpen ? 'expand_more' : 'expand_less'} className={cn('h-4 w-4', iconClass)} />
          )}
        </>
      )}
    </Button>
  );
};

const SelectPopover = ({ className, offset = 0, ...props }: PopoverProps) => (
  <Popover
    offset={offset}
    className={values =>
      cn(
        'bg-popover text-popover-foreground data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in-0 data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2 relative z-50 max-h-[350px] w-[--trigger-width] min-w-[8rem] overflow-auto overflow-y-auto rounded-md border shadow-[0_2px_4px_0] shadow-purpleLighter',
        'data-[placement=bottom]:translate-y-1 data-[placement=left]:-translate-x-1 data-[placement=right]:translate-x-1 data-[placement=top]:-translate-y-1',
        typeof className === 'function' ? className(values) : className
      )
    }
    {...props}
  />
);

const SelectContent = <T extends object>({ className, ...props }: ListBoxProps<T>) => (
  <ListBox className={cn('relative mt-2 w-full rounded pb-2 outline-none', className)} {...props} />
);

const SelectHeader = ({ className, ...props }: ComponentPropsWithoutRef<typeof Header>) => (
  <Header className={cn('px-2 py-1.5 text-sm font-semibold', className)} {...props} />
);

const SelectItem = ({ className, children, ...props }: ListBoxItemProps) => (
  <ListBoxItem
    className={values =>
      cn(
        'focus:bg-accent focus:text-accent-foreground body2 relative flex w-full cursor-pointer select-none items-center justify-between p-4 py-1.5 pl-2 pr-8 text-left text-sm outline-none transition-all duration-75 ease-in-out hover:bg-BG hover:font-semibold data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        typeof className === 'function' ? className(values) : className
      )
    }
    {...props}
  >
    {values => (
      <>
        {values.isSelected && (
          <span className="absolute right-2 flex h-4 w-4 items-center justify-center">
            <Icon name="check" className="fill-accent-foreground h-3 w-3" />
          </span>
        )}
        {typeof children === 'function' ? children(values) : children}
      </>
    )}
  </ListBoxItem>
);

const SelectSeparator = ({ className, ...props }: SeparatorProps) => (
  <Separator className={cn('bg-muted -mx-1 my-1 h-px', className)} {...props} />
);

export {
  // eslint-disable-next-line no-barrel-files/no-barrel-files
  _Select as Select,
  SelectCollection,
  SelectContent,
  SelectHeader,
  SelectItem,
  SelectPopover,
  SelectSection,
  SelectSeparator,
  SelectTrigger,
  // eslint-disable-next-line no-barrel-files/no-barrel-files
  _SelectValue as SelectValue,
};
