import { cn } from '@corify/helpers/cn';
import { Dialog as HUIDialog, Portal, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../button/button';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  hideCloseButton?: boolean;
  variant?: 'default' | 'notification' | 'error';
  size?: 'sm' | 'md';
  id?: string;
  isHidden?: boolean;
}

export const Dialog = ({
  children,
  isOpen = false,
  onClose = () => {},
  title,
  variant = 'default',
  size,
  hideCloseButton = false,
  isHidden = false,
  id,
}: Props) => {
  const { t } = useTranslation();
  const initialFocus = useRef(null);

  return (
    <Portal>
      <Transition show={isOpen} as={Fragment}>
        <HUIDialog
          hidden={isHidden}
          className="relative z-[70]"
          initialFocus={variant === 'notification' ? initialFocus : undefined}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
          </Transition.Child>

          {/* Full-screen container to center the panel */}
          <div id={id} className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HUIDialog.Panel
                className={cn(
                  'relative w-full rounded-br-[80px] rounded-tl-[80px] border-b-4 border-r-4 bg-white px-11 py-8',
                  {
                    'max-w-xl border-corifyGreen bg-gradient-to-r from-[#32005A] to-purple': variant === 'notification',
                    'max-w-[640px] border-purple': variant === 'default',
                    'max-w-[640px] border-corifyRed': variant === 'error',
                    'max-w-[480px]': size === 'sm',
                  }
                )}
                ref={initialFocus}
              >
                {variant === 'default' && typeof onClose === 'function' && !hideCloseButton && (
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <Button onClick={onClose} icon="close" variant="text" iconClassName="fill-purple">
                      <span className="sr-only">{t('close')}</span>
                    </Button>
                  </div>
                )}

                {title && (
                  <HUIDialog.Title
                    as="h1"
                    className="flex flex-col items-center px-10 pb-8 text-center after:w-1/6 after:border-b-2 after:border-corifyGreen after:pt-2"
                  >
                    {title}
                  </HUIDialog.Title>
                )}

                {children}
              </HUIDialog.Panel>
            </Transition.Child>
          </div>
        </HUIDialog>
      </Transition>
    </Portal>
  );
};

const Actions = ({ children, className }: PropsWithChildren & { className?: string }) => (
  <div className={cn('flex justify-center gap-8 pt-12', className)}>{children}</div>
);

interface ContentProps {
  children?: ReactNode;
  className?: string;
  id?: string;
}

const Content = ({ children, ...props }: ContentProps) => (
  <section {...props} className={cn('relative', props.className)}>
    {children}
  </section>
);

const Title = ({ children, classNames }: PropsWithChildren & { classNames?: string }) => (
  <HUIDialog.Title
    as="h1"
    className={cn(
      'flex flex-col items-center px-10 pb-8 text-center after:w-1/6 after:border-b-2 after:border-corifyGreen after:pt-2',
      classNames
    )}
  >
    {children}
  </HUIDialog.Title>
);

Dialog.Actions = Actions;
Dialog.Content = Content;
Dialog.Title = Title;
