import { UserStatus } from '@corify/types/client';
import { UserType } from '@corify/types/user';

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

interface UserWithType extends User {
  clientType: UserType;
}

interface UserWithInvitationStatus extends User {
  status: UserStatus;
}

export type AvatarUser = UserWithType | UserWithInvitationStatus | string;

export const isUserWithType = (user: AvatarUser): user is UserWithType => {
  return Boolean((user as UserWithType).clientType);
};

export const isUserWithInvitationStatus = (user: AvatarUser): user is UserWithInvitationStatus => {
  return Boolean((user as UserWithInvitationStatus).status);
};
