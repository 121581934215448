import { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GDVNotificationImported, GDVNotificationTransaction } from '../types';

type NotificationWarning = {
  canReload: boolean;
  warning: ReactNode;
};

export const useNotificationWarnings = ({
  notificationTransactionType,
  matchedEnterprises,
  selectedEnterprise,
  matchedVehicles,
  importStatus,
  importError,
}: GDVNotificationImported) => {
  const { t } = useTranslation();

  const { isNewRegistration, isDeregister, isChange } = {
    isNewRegistration: notificationTransactionType === GDVNotificationTransaction.NewRegistration,
    isChange: notificationTransactionType === GDVNotificationTransaction.Change,
    isDeregister: notificationTransactionType === GDVNotificationTransaction.Deregister,
  };

  const warnings = useMemo((): NotificationWarning[] => {
    const warnings: NotificationWarning[] = [];

    if (['FAILED', 'FAILED_LOADING'].includes(importStatus) && importError) {
      warnings.push({
        canReload: false,
        warning: (
          <>
            <strong>{t(`fleetManagement.import.errors.${importStatus}.title`)}</strong>
            <br />
            <Trans i18nKey={`fleetManagement.import.errors.${importStatus}.${importError}`}>
              <p>Something went wrong</p>
              <p>(Error code: UNKNOWN_ERROR)</p>
            </Trans>
          </>
        ),
      });
    }

    if (isNewRegistration && !selectedEnterprise) {
      if (!matchedEnterprises || matchedEnterprises?.length === 0) {
        warnings.push({
          canReload: true,
          warning: (
            <Trans i18nKey="fleetManagement.import.errors.FAILED.ENTERPRISE_NOT_FOUND">
              <strong>Loading failed</strong>
              <br />
              <p className="pb-4">The enterprise couldn&apos;t be identified.</p>
              <br />
              <strong>(Permanent) eVB</strong>
              <br />
              <p className="pb-4">
                Please add the (permanent) eVB from the GDV message to a fleet manually. Then reload the GDV message.
              </p>
              <br />
              <strong>(Single) eVB</strong>
              <br />
              <p>In this case, please select an Enterprise from the selection list.</p>
            </Trans>
          ),
        });
      }

      if (matchedEnterprises?.length > 1) {
        warnings.push({
          canReload: true,
          warning: (
            <>
              <strong>{t('fleetManagement.import.errors.FAILED_LOADING.title')}</strong>
              <br />
              <span>{t('fleetManagement.import.errors.FAILED.AMBIGUOUS_ENTERPRISES_1')}</span>
              <br />

              <ul className="list-disc pl-4">
                {matchedEnterprises.map((enterprise, index) => (
                  <li key={index}>{enterprise.enterpriseName}</li>
                ))}
              </ul>

              <br />
              <span>{t('fleetManagement.import.errors.FAILED.AMBIGUOUS_ENTERPRISES_2')}</span>
            </>
          ),
        });
      }
    }

    if (isNewRegistration) {
      if (matchedVehicles?.length) {
        warnings.push({
          canReload: true,
          warning: (
            <>
              <strong>{t('fleetManagement.import.errors.FAILED_LOADING.title')}</strong>
              <br />
              <span>{t('fleetManagement.import.errors.FAILED.VEHICLE_ALREADY_EXISTS')}</span>
            </>
          ),
        });
      }
    }

    if (isDeregister || isChange) {
      if ((matchedVehicles?.length ?? -1) > 1) {
        warnings.push({
          canReload: true,
          warning: (
            <>
              <strong>{t('fleetManagement.import.errors.FAILED_LOADING.title')}</strong>
              <br />
              <span>{t('fleetManagement.import.errors.FAILED.AMBIGUOUS_VEHICLE_1')}</span>
              <br />

              <ul className="list-disc pl-4">
                {matchedVehicles?.map((vehicle, index) => <li key={index}>{vehicle.shortDescription}</li>)}
              </ul>

              <br />
              <span>{t('fleetManagement.import.errors.FAILED.AMBIGUOUS_VEHICLE_2')}</span>
            </>
          ),
        });
      }

      if (matchedVehicles?.length === 0) {
        warnings.push({
          canReload: true,
          warning: (
            <>
              <strong>{t('fleetManagement.import.errors.FAILED_LOADING.title')}</strong>
              <br />
              <span>{t('fleetManagement.import.errors.FAILED.VEHICLE_NOT_FOUND')}</span>
            </>
          ),
        });
      }
    }

    // TODO: Dirty fix for paused state
    if (importError) {
      warnings.push({
        canReload: false,
        warning: (
          <>
            <strong>{t(`fleetManagement.import.errors.FAILED_LOADING.title`)}</strong>
            <br />
            <Trans i18nKey="fleetManagement.import.errors.FAILED_LOADING.UNKNOWN_ERROR">
              <p>Something went wrong</p>
              <p>(Error code: UNKNOWN_ERROR)</p>
            </Trans>
          </>
        ),
      });
    }

    return warnings;
  }, [
    importError,
    importStatus,
    isChange,
    isDeregister,
    isNewRegistration,
    matchedEnterprises,
    matchedVehicles,
    selectedEnterprise,
    t,
  ]);

  return { warnings };
};
