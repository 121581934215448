import { Icon } from '@corify/components/icon/icon';
import { DOMAttributes } from 'react';

export interface Props extends DOMAttributes<HTMLDivElement> {
  error?: string | undefined;
}

export const ErrorMessageContainer = ({ error, ...props }: Props) => (
  <div className="mt-1 min-h-[18px]">
    {error && (
      <div className="flex items-center" role="alert" {...props}>
        <Icon name="error_outline" className="mr-2 flex-shrink-0 fill-corifyRed" />
        <div className="body3 text-corifyRed">{error}</div>
      </div>
    )}
  </div>
);
