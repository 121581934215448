import { Loader } from '@corify/components/loader/loader';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { CodeBlock, ghcolors } from '@react-email/code-block';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import xmlFormat from 'xml-formatter';

import { GDVNotificationRawData } from '../../types';

type RawDataExpandableRowProps = {
  notificationId: string;
};

const corify = {
  ...ghcolors,
  base: {
    ...ghcolors.base,
    fontFamily: 'inherit',
    color: '#4b00cd',
    border: 'none',
    padding: '0',
    margin: '0',
  },
  tag: {
    color: '#000',
  },
  punctuation: {
    color: '#000',
  },
  string: {
    color: '#4b00cd',
  },
  'attr-value': {
    color: '#4b00cd',
  },
  'attr-name': {
    color: '#4b00cd',
  },
  entity: {
    color: '#4b00cd',
  },
  url: {
    color: '#4b00cd',
  },
  symbol: {
    color: '#4b00cd',
  },
  number: {
    color: '#4b00cd',
  },
  boolean: {
    color: '#4b00cd',
  },
  variable: {
    color: '#4b00cd',
  },
  constant: {
    color: '#4b00cd',
  },
  property: {
    color: '#4b00cd',
  },
  regex: {
    color: '#4b00cd',
  },
  inserted: {
    color: '#4b00cd',
  },
  atrule: {
    color: '#4b00cd',
  },
  keyword: {
    color: '#4b00cd',
  },
  selector: {
    color: '#4b00cd',
  },
};

export const RawDataExpandableContent = ({ notificationId }: RawDataExpandableRowProps) => {
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const { data, isLoading } = useSWRImmutable<GDVNotificationRawData>(
    notificationId
      ? `${apiUrl(
          `${addUrlParameters(endpoints.cfm.notifications.rawData, {
            brokerId,
            notificationId,
          })}`
        )}`
      : null,
    swrApiFetcher<GDVNotificationRawData>('GET')
  );
  const formattedXML = useMemo(() => {
    const { rawData } = data ?? {};
    if (!rawData) {
      return;
    }

    let xmlOutput = rawData;

    try {
      xmlOutput = xmlFormat(rawData, { indentation: '\t' });
    } catch {}

    return xmlOutput;
  }, [data]);

  return (
    <div className="sticky left-0 inline-block py-4 align-middle text-sm leading-5">
      <div className="h-72 overflow-auto px-6" style={{ direction: 'rtl' }}>
        {isLoading ? <Loader /> : <CodeBlock code={formattedXML as string} language="xml" theme={corify} />}
      </div>
    </div>
  );
};
