import { useDateSegment } from '@react-aria/datepicker';
import { DatePickerBase, DateValue } from '@react-types/datepicker';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { useRef } from 'react';
import { DateFieldState as DateFieldStateProps, DateSegment as DateSegmentProps } from 'react-stately';

type LiteralSegmentProps = {
  segment: DateSegmentProps;
  id?: string;
  textSize?: 'sm' | 'xs';
};

const LiteralSegment = ({ segment, id, textSize }: LiteralSegmentProps) => {
  return (
    <span
      data-segment-id={id}
      aria-hidden="true"
      className={clsx(
        'group box-content rounded-sm px-0.5 text-center text-sm oldstyle-nums text-black outline-none focus:bg-violet-600 focus:text-white',
        { 'text-xs': textSize === 'xs' }
      )}
      data-testid={segment.type === 'literal' ? 'datepicker-placeholder' : kebabCase(segment.type)}
      style={{ height: '1.5em' }}
    >
      {segment.text}
    </span>
  );
};

interface Props extends DatePickerBase<DateValue> {
  segment: DateSegmentProps;
  state: DateFieldStateProps;
  id?: string;
  disableKeyboard?: boolean;
  textSize?: 'sm' | 'xs';
}

const EditableSegment = ({ segment, state, id, textSize }: Props) => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth: segment.maxValue != null ? String(segment.maxValue).length + 'ch' : undefined,
        height: '1.5em',
      }}
      className={clsx(
        'group box-content rounded-sm px-0.5 text-center text-sm oldstyle-nums outline-none focus:bg-violet-600 focus:text-white',
        !segment.isEditable ? 'text-black' : 'text-gray-800',
        'datepicker-segment',
        { 'text-xs': textSize === 'xs' }
      )}
      data-testid={kebabCase(segment.type)}
      data-segment-id={id}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className={clsx('is-placeholder block w-full text-center text-sm text-gray-500 group-focus:text-white', {
          'text-xs': textSize === 'xs',
        })}
        style={{
          visibility: segment.isPlaceholder ? undefined : 'hidden',
          height: segment.isPlaceholder ? '' : 0,
          pointerEvents: 'none',
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
};

export const DateSegment = ({ segment, state, id, disableKeyboard = false, ...otherProps }: Props) => {
  if (segment.type === 'literal' || disableKeyboard) {
    return <LiteralSegment segment={segment} id={id} {...otherProps} />;
  }
  return <EditableSegment segment={segment} state={state} {...otherProps} id={id} />;
};
