/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { Icon } from '@corify/components/icon/icon';
import { Checkbox } from '@corify/components/inputs/checkbox/checkbox';
import { Label } from '@corify/components/inputs/field-label/label';
import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { cn } from '@corify/helpers/cn';
import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import { ReactNode, useRef, useState } from 'react';
import { Button, Dialog, DialogTrigger, Popover } from 'react-aria-components';
import { useTranslation } from 'react-i18next';

import { Chip } from '../../chip/chip';

export type MultiselectItem = { label: string; value: string; customListLabel?: ReactNode };

interface Props {
  id?: string;
  label: string;
  shouldHideLabel?: boolean;
  values: string[];
  items: MultiselectItem[];
  isReadOnly?: boolean;
  error?: string | undefined;
  required?: boolean;
  onChange: (selectedItems: string[]) => void;
  onBlur?: (value: string[]) => void;
  width?: string;
  placeholder?: string;
  showTotalItemsSelected?: boolean;
  tooltip?: string;
  className?: string;
  classNameInput?: string;
  classNameInputText?: string;
  compact?: boolean;
}

export const Multiselect = ({
  id,
  label,
  shouldHideLabel = false,
  values: initialValues,
  items: itemOptions,
  required,
  isReadOnly,
  tooltip,
  error,
  onChange,
  placeholder,
  showTotalItemsSelected,
  width,
  className,
  classNameInput,
  onBlur,
  compact,
  ...props
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const items = uniqBy(itemOptions, item => item.value);
  const values = [...new Set(initialValues)].filter(Boolean);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(values);

  const noItemsDefined = items.length === 0;
  const noItemsSelected = selectedItems.length === 0;

  const deleteIcon = isReadOnly ? 'clear_grey' : 'clear';
  const expandIcon = isOpen ? 'expand_less' : 'expand_more';

  const reset = () => {
    setSelectedItems([]);
    onChange([]);
  };

  const toggle = (currentValue: string) => {
    if (selectedItems.includes(currentValue)) {
      const newSelectedItems = [...selectedItems.filter(value => value !== currentValue)];
      setSelectedItems(newSelectedItems);
      onChange(newSelectedItems);
    }

    if (!selectedItems.includes(currentValue)) {
      const newSelectedItems = [...selectedItems, currentValue];
      setSelectedItems(newSelectedItems);
      onChange(newSelectedItems);
    }
  };

  const displayValues = () => {
    if (noItemsDefined) {
      return <div className="flex h-[28px] items-center text-darkGrey">{t('components.dropdown.noOptionsText')}</div>;
    }

    if (!noItemsSelected && showTotalItemsSelected) {
      return (
        <Chip
          aria-label={`chip-total-selected-${selectedItems.length}`}
          icon={
            <Icon
              onClickCapture={e => {
                if (isReadOnly) {
                  return;
                }
                reset();
                e.stopPropagation();
              }}
              className={clsx('ml-4', !isReadOnly && 'cursor-pointer', isReadOnly && 'cursor-default text-black')}
              name={deleteIcon}
              aria-label="Reset values"
            />
          }
          text={`${selectedItems.length} ${t('components.multiselect.selected')}`}
          className={clsx('select-text bg-white', isReadOnly && '!border-grey !text-black hover:!border-purple')}
          variant="outlined"
        />
      );
    }

    if (!noItemsSelected) {
      return selectedItems.map((selectedItem, index) => (
        <Chip
          aria-label={`chip-${selectedItem}`}
          key={`selected-item-${index}`}
          icon={
            <Icon
              onClickCapture={e => {
                if (isReadOnly) {
                  return;
                }
                e.stopPropagation();
                toggle(selectedItem);
              }}
              className={clsx('ml-4', !isReadOnly && 'cursor-pointer', isReadOnly && 'cursor-default text-black')}
              name={deleteIcon}
              aria-label="Icon delete"
            />
          }
          text={items.find(item => item.value === selectedItem)?.label || ''}
          className={clsx('select-text bg-white', isReadOnly && '!border-grey !text-black hover:!border-purple')}
          variant="outlined"
        />
      ));
    }

    return (
      <div className="flex h-[28px] items-center text-darkGrey">
        {placeholder || t('components.dropdown.emptyOption')}
      </div>
    );
  };

  const isDropdownDefaultBorder = !isOpen && !error && !isReadOnly;
  const isDropdownErrorBorder = error && !isOpen;

  return (
    <div className={cn('relative', className)} style={{ width }} data-component="multiselect" {...props}>
      <DialogTrigger onOpenChange={setIsOpen}>
        {!shouldHideLabel && (
          <Label
            label={label}
            isRequired={required}
            tooltip={tooltip}
            classes={{ root: 'flex mb-1 min-h-[2rem] w-full', label: 'body3' }}
          />
        )}
        <Button
          aria-label="Show options"
          ref={buttonRef}
          onBlur={() => onBlur?.(selectedItems)}
          isDisabled={isReadOnly || items.length === 0}
          className={cn(
            'body2 group flex h-full w-full items-center justify-between p-4',
            'px-4 py-[10px]',
            'w-full cursor-pointer border bg-white text-black outline-none',
            'hover:border-purple focus:border-purple',
            'disabled:cursor-default disabled:border-lighterGrey disabled:bg-lighterGrey',
            {
              'border-grey': isDropdownDefaultBorder,
              'border-corifyRed': isDropdownErrorBorder,
              'bg-corifyFormError focus:bg-white': isDropdownErrorBorder || (required && !selectedItems.length),
              'border-purple': isOpen,
              'cursor-default': noItemsDefined,
              'hover:bg-white': required && !isReadOnly,
              'min-h-[50px] px-2': compact,
            },
            classNameInput
          )}
        >
          <div className="flex w-[calc(100%-50px)] flex-wrap gap-2">{displayValues()}</div>
          <div
            className={clsx('flex items-end', {
              'ml-2': compact,
              'ml-4': !compact,
            })}
          >
            {!noItemsSelected && !showTotalItemsSelected && (
              <Icon
                aria-label="Reset values"
                name={deleteIcon}
                onClickCapture={e => {
                  if (isReadOnly) {
                    return;
                  }
                  reset();
                  e.stopPropagation();
                }}
              />
            )}
            <Icon
              aria-label={expandIcon}
              name={expandIcon}
              className={clsx('ml-4 h-4 w-4 shrink-0', {
                'text-darkGrey': isReadOnly && !isDropdownErrorBorder,
                'text-purple group-hover:text-purple': !isReadOnly,
                'ml-2': compact,
              })}
            />
          </div>
        </Button>
        <Popover className="focus:border-0" style={{ width: buttonRef?.current?.clientWidth || undefined }}>
          <Dialog className="focus:border-0">
            <ul className={clsx(`max-h-[350px] w-full overflow-auto rounded border bg-white pb-2 pt-2 outline-none`)}>
              {items.map((item, index) => {
                const isSelected = selectedItems.includes(item.value);

                return (
                  <li
                    className={clsx('flex cursor-pointer select-none py-3 text-sm hover:bg-BG', {
                      'font-medium': isSelected,
                      'px-4': !compact,
                      'px-2': compact,
                    })}
                    key={`${item}${index}`}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        toggle(item.value);
                      }
                    }}
                    onClick={() => toggle(item.value)}
                    tabIndex={0}
                  >
                    <Checkbox checked={isSelected} label="" onChange={() => {}} />
                    {item.customListLabel || item.label}
                  </li>
                );
              })}
            </ul>
          </Dialog>
        </Popover>
      </DialogTrigger>
      <ErrorMessageContainer error={error} />
    </div>
  );
};
