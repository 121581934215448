import { DatePicker } from '@corify/components/fields/date-picker/date-picker';
import { Icon } from '@corify/components/icon/icon';
import { Props as TextFieldProps, TextField } from '@corify/components/inputs/text-field/text-field';
import { Select, SelectContent, SelectItem, SelectPopover } from '@corify/components/select';
import { Key } from '@react-types/shared';
import { Column } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const { t } = useTranslation();
  const { filterVariant } = column.columnDef.meta ?? {};

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort().slice(0, 5000),
    [column.getFacetedUniqueValues(), filterVariant]
  );

  switch (filterVariant) {
    case 'date':
      return (
        <DatePicker
          label=""
          aria-label={column.id + ' date filter'}
          shouldHideLabel
          onChange={value => {
            column.setFilterValue(value ? new Date(value as string) : '');
          }}
          value={columnFilterValue ? format(new Date(columnFilterValue as string), 'yyyy-MM-dd') : ''}
          classNames={{
            wrapper: 'border-0',
            root: 'w-full',
          }}
        />
      );
    case 'select':
      return (
        <Select
          aria-label={column.id + ' select filter'}
          onSelectionChange={(value: Key) => {
            column.setFilterValue(value as string);
          }}
          selectedKey={columnFilterValue?.toString()}
        >
          <SelectPopover className="left-0 bg-white">
            <SelectContent aria-label="items">
              {sortedUniqueValues
                .sort((a, b) => a.localeCompare(b))
                .map(value => (
                  <SelectItem key={value} textValue={value}>
                    {value}
                  </SelectItem>
                ))}
            </SelectContent>
          </SelectPopover>
        </Select>
      );
    case 'text':
      return (
        <DebouncedInput
          aria-label={column.id + ' text filter'}
          type="text"
          label=""
          value={(columnFilterValue ?? '') as string}
          onChange={value => column.setFilterValue(value)}
          placeholder={t('components.filterField.placeholder')}
          className="w-36 rounded border shadow"
          classes={{
            root: 'w-full',
            input: 'border-0 text-sm placeholder:text-darkGrey text-darkGrey',
          }}
          adornmentLeft={
            <Icon aria-hidden="true" className="h-4 w-4" name="search" aria-label="search license plate" />
          }
          adornmentRight={
            columnFilterValue ? (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <Icon
                  aria-label={t('components.filterField.clearFilter') ?? ''}
                  className="h-4 w-4"
                  name="clear"
                  onClick={() => column.setFilterValue('')}
                />
              </div>
            ) : undefined
          }
        />
      );
    default:
      return null;
  }
};

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  label?: string;
} & Omit<TextFieldProps, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <TextField
      {...props}
      shouldHideLabel
      value={value}
      onChange={value => setValue(value)}
      classes={{
        input: 'border-0 w-full',
        root: 'w-full',
      }}
    />
  );
};
