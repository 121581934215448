import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import useSWR from 'swr';
import { useDebounce } from 'usehooks-ts';

export type PageProps = {
  method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
};

export type CollectionApiResponse<T> = {
  totalItems: number;
  pageSize: number;
  pageNumber: number;
  items: T[];
};

export type Props = PageProps & {
  url: string;
  debounceTime?: number | boolean;
  requestPayload: object;
};

export const useCollectionFetchPayload = <T extends CollectionApiResponse<unknown>>({
  method = 'POST',
  debounceTime = 500,
  requestPayload,
  url,
}: Props) => {
  const swrApiFetcher = useSwrApiFetcher();

  const debouncedRequestPayload = useDebounce(requestPayload, typeof debounceTime === 'number' ? debounceTime : 0);

  const { data, isValidating, error, mutate } = useSWR<T>(
    { url, payload: debouncedRequestPayload },
    swrApiFetcher(method)
  );

  return {
    data,
    isValidating,
    error,
    mutate,
  };
};
