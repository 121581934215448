import { Column, Row } from '@tanstack/react-table';
import { ClassValue } from 'clsx';
import { isBefore } from 'date-fns';
import { CSSProperties } from 'react';

import { Order } from '../sort-field/sort-field';

/**
 * @deprecated Use compareStringsFn with tenstack table instead
 */
export const compareStrings = (order: Order) => (a: string, b: string) =>
  order === 'ASCENDING' ? a.localeCompare(b) : b.localeCompare(a);

/**
 * @deprecated Use compareNumbersFn  with tenstack table instead
 */
export const compareNumbers = (order: Order) => (a: number, b: number) => (order === 'ASCENDING' ? a - b : b - a);

/**
 * @deprecated Use compareDates  with tenstack table instead
 */
export const compareDates = (order: Order) => (a: Date | string, b: Date | string) => {
  const dateA = new Date(a ?? '');
  const dateB = new Date(b ?? '');
  if (order === 'ASCENDING') {
    return isBefore(dateA, dateB) ? -1 : 1;
  }
  return isBefore(dateB, dateA) ? -1 : 1;
};

// TODO: Rename after compareStrings is no longer needed
export const compareStringsFn = <TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
  const a = rowA.getValue(columnId);
  return typeof a === 'string' ? a.localeCompare(rowB.getValue(columnId)) : 0;
};

// TODO: Rename after compareNumbers is no longer needed
export const compareNumbersFn = <TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  const bothNumbers = typeof a === 'number' && typeof b === 'number';
  return bothNumbers ? a - b : 0;
};

// TODO: Rename after compareDates is no longer needed
export const compareDatesFn = <TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
  const a = rowA.original[columnId];
  const b = rowB.original[columnId];

  const isDateLike = (value: unknown): value is Date | string => typeof value === 'string' || value instanceof Date;

  if (!isDateLike(a) || !isDateLike(b)) {
    return 0;
  }

  const dateA = new Date(a ?? '');
  const dateB = new Date(b ?? '');

  return isBefore(dateA, dateB) ? -1 : 1;
};

export const getColumnPinningStyles = <TData>(column: Column<TData>): CSSProperties => {
  let left = column.getIsPinned() ? column.getStart('left') : undefined;

  if (column.getIsPinned() && !column.getStart('left') && left !== undefined && left !== 0) {
    left = left - 1;
  }

  return {
    left: `${left}px`,
  };
};

export const getCommonPinningClasses = <TData>(
  column: Column<TData>,
  isNotLast: boolean,
  oldDesign: boolean,
  showStickyCellSeparatorAt?: number
): ClassValue => {
  if (!column.getIsPinned()) {
    return;
  }

  const leftColumnClass =
    column.getIsPinned() &&
    showStickyCellSeparatorAt !== undefined &&
    column.getStart('left') >= showStickyCellSeparatorAt &&
    'drop-shadow-[3px_0_1px_rgba(224,225,255,1)]';

  return [
    'sticky z-[8] bg-white',
    leftColumnClass,
    !oldDesign &&
      column.getIsPinned() &&
      isNotLast &&
      showStickyCellSeparatorAt &&
      column.getStart('left') <= showStickyCellSeparatorAt &&
      // This is a hack, the sticky class, is sometimes buggy in some browser engines
      'after:z-1 after:absolute after:right-[1px] after:top-0 after:h-full after:w-[1px] after:bg-lightGrey after:content-[""]',
  ];
};
