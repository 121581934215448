import { DropDownButton } from '@corify/components/button/dropdown-button/dropdown-button';
import { endpoints } from '@corify/constants/endpoints';
import { useTranslation } from 'react-i18next';

import { useTriggerAction } from '../common/use-trigger-action';
import { NotificationId } from '../types';

type Props = {
  selectedIdsForAction: NotificationId[];
  onNotificationsPause: () => void;
  resetView: () => void;
  allSelectedNotificationsReadyForImport: boolean;
};

const AvailableDataActionButton = ({
  selectedIdsForAction,
  onNotificationsPause,
  resetView,
  allSelectedNotificationsReadyForImport,
}: Props) => {
  const { t } = useTranslation();

  const { handleAction: triggerImport } = useTriggerAction({
    endpoint: endpoints.cfm.notifications.triggerImport,
    payload: { vehicleNotificationIds: selectedIdsForAction },
    resetView,
  });

  return (
    <DropDownButton
      isDisabled={selectedIdsForAction.length < 1}
      title={t('fleetManagement.overview.actionButtonTitle')}
      message={allSelectedNotificationsReadyForImport ? undefined : t('fleetManagement.overview.actionButtonMessage')}
      dropDownSections={[
        {
          icon: 'download',
          title: t('fleetManagement.overview.actionButtonImport'),
          onClick: triggerImport,
          isDisabled: !allSelectedNotificationsReadyForImport,
        },
        {
          icon: 'pause',
          title: t('fleetManagement.overview.actionButtonPause'),
          onClick: onNotificationsPause,
          isDisabled: false,
        },
      ]}
    />
  );
};

export default AvailableDataActionButton;
