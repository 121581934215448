import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { RISK_CONTRIBUTOR_ROUTES } from './routes';

const CustomersV1 = lazy(() => import('@corify/pages/clients/customers/v1/customers'));
const CustomersV2 = lazy(() => import('@corify/pages/clients/customers/v2/customers'));
const RootLayout = lazy(() => import('@corify/layout/root-layout'));
const BrokerLeftNavigationLayout = lazy(() => import('@corify/layout/risk-contributor-layout'));
const CustomerStructure = lazy(() => import('@corify/pages/customer/structure/customer-structure'));

export const getBrokerRiskContributorRoutes = (newCustomerList: boolean) => [
  <Route key="broker-risk-contributor-list" element={<RootLayout />}>
    {newCustomerList ? (
      <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_ROUTE} element={<CustomersV2 />} />
    ) : (
      <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_ROUTE} element={<CustomersV1 />} />
    )}
  </Route>,
  <Route key="broker-risk-contributor" element={<BrokerLeftNavigationLayout />}>
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_STRUCTURE_ROUTE} element={<CustomerStructure />} />
  </Route>,
];
