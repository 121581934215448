import { Icon, IconName } from '@corify/components/icon/icon';
import { TextField } from '@corify/components/inputs/text-field/text-field';
import { distanceMap } from '@corify/components/popup/popup';
import { Spinner } from '@corify/components/spinner/spinner';
import { cn } from '@corify/helpers/cn';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { isEqual } from 'lodash-es';
import { Fragment, MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'react-aria-components';
import { useTranslation } from 'react-i18next';

import { extractMatchedGroup, formatPercentage, getFilteredItems, getHighlightedParts } from './search-field-utils';
import { DropdownItem, DropdownRenderOptionProps, MatchAlgorithm, UniqueFilterFn } from './types';

interface Props<TItem extends DropdownItem<unknown>> {
  id?: string;
  clearable?: boolean;
  label: string;
  shouldHideLabel?: boolean;
  shouldHideError?: boolean;
  selectedItem: TItem | undefined;
  items: TItem[];
  error?: string | undefined;
  isRequired?: boolean;
  isReadonly?: boolean;
  onChange: (selectedItem?: TItem | null) => void;
  onBlur?: (selectedItem?: TItem | null) => void;
  onMenuOpen?: (isOpen?: boolean) => void;
  placeholder?: string;
  tooltip?: string;
  inputTooltip?: ReactNode;
  noItemMessage?: ReactNode;
  customRenderOption?: (props: DropdownRenderOptionProps<TItem>) => ReactNode;
  adornmentLeft?: IconName | null;
  showExpandIcon?: boolean;
  showSpinner?: boolean;
  matchAlgorithm?: MatchAlgorithm<TItem>;
  uniqueFilterFn?: UniqueFilterFn<TItem>;
  highlightSearchTerm?: boolean;
  maxTopMatches?: number;
  allowCustomValues?: boolean;
  validateCustomValue?: (value: string) => boolean;
  testId?: string;
  classNames?: { input?: string };
  compact?: boolean;
  hideDefaultBorder?: boolean;
}

export const Search = <TItem extends DropdownItem<unknown>>({
  id,
  clearable,
  label,
  shouldHideLabel = false,
  shouldHideError = false,
  selectedItem,
  items: initialItems,
  isRequired,
  tooltip,
  inputTooltip,
  error,
  onChange,
  onBlur,
  onMenuOpen,
  isReadonly,
  placeholder,
  noItemMessage,
  customRenderOption,
  adornmentLeft = isReadonly ? 'search_readonly' : 'search',
  showExpandIcon = false,
  showSpinner = false,
  matchAlgorithm,
  highlightSearchTerm = false,
  maxTopMatches,
  uniqueFilterFn,
  allowCustomValues,
  validateCustomValue,
  testId,
  classNames,
  compact,
  hideDefaultBorder,
}: Props<TItem>) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const label = selectedItem?.label || '';

    setFilterValue(highlightSearchTerm ? '' : label);
    setInputValue(label);
  }, [highlightSearchTerm, selectedItem]);

  noItemMessage = noItemMessage ?? t('components.riskObjectDropdownWidget.noItems');

  const items = useMemo(() => {
    const filteredItems = getFilteredItems<TItem>({
      itemOptions: initialItems,
      inputValue: highlightSearchTerm ? filterValue : inputValue,
      matchAlgorithm: matchAlgorithm ?? 'includesAnyWord',
      uniqueFilterFn,
    });
    return extractMatchedGroup(filteredItems, maxTopMatches);
  }, [filterValue, highlightSearchTerm, initialItems, inputValue, matchAlgorithm, maxTopMatches, uniqueFilterFn]);

  const initialInputValue = useMemo(
    () => initialItems.find(item => isEqual(item.value, selectedItem?.value))?.label ?? '',
    [selectedItem, initialItems]
  );

  const lastMatchedIndex = items.findLastIndex(item => typeof item.percentage === 'number');

  const popupAnchorRef = useRef<HTMLInputElement>(null);

  const {
    isOpen,
    getLabelProps,
    getItemProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    toggleMenu,
    closeMenu,
    reset,
  } = useCombobox({
    id,
    initialInputValue,
    itemToString(item) {
      return item ? item.label : '';
    },
    items,
    onInputValueChange: ({ inputValue }) => {
      setFilterValue(inputValue ?? '');
      setInputValue(inputValue ?? '');
    },
    onIsOpenChange: ({ isOpen, type, inputValue }) => {
      if (!isOpen || type === '__input_blur__') {
        if (allowCustomValues) {
          const currentValueTrimmed = inputValue?.trim();
          if (currentValueTrimmed) {
            setFilterValue(currentValueTrimmed);
            const existingItem = initialItems.find(item => isEqual(item.label, currentValueTrimmed));

            if (existingItem || (validateCustomValue?.(currentValueTrimmed) ?? true)) {
              onChange(existingItem ?? ({ label: currentValueTrimmed, value: currentValueTrimmed } as TItem));
            } else {
              setInputValue('');
              setFilterValue('');
              onChange(undefined);
            }
          } else {
            onChange(undefined);
          }
        } else {
          setInputValue(selectedItem?.label ?? '');
        }
      }

      onMenuOpen?.(isOpen);
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      onChange(newSelectedItem || null);
      setInputValue(newSelectedItem?.label || '');
    },
  });

  const displayClearIcon = clearable && inputValue;
  const clearIcon = isReadonly ? 'clear_grey' : 'clear';

  const handleClear = (e: MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();
    onChange(undefined);
    setInputValue('');
    reset();
    setFilterValue('');
  };

  useEffect(() => {
    if (!selectedItem) {
      reset();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isOpen && selectedItem) {
      document.getElementById(`${JSON.stringify(selectedItem.value)}-${selectedItem.groupName}`)?.scrollIntoView();
    }
  }, [isOpen, selectedItem]);

  const buildItem = (item: TItem, index: number, usedGroupNames: string[]) => {
    const isActiveItem = isEqual(item.value, selectedItem?.value);

    if (customRenderOption) {
      return customRenderOption({ index, isActiveItem, highlightedIndex, getItemProps, item, t });
    }

    let groupNameElement: ReactNode = null;

    if (item?.groupName && !usedGroupNames.includes(item.groupName)) {
      usedGroupNames.push(item.groupName);

      groupNameElement = (
        <li
          className={clsx('body2 text-left text-xs font-bold text-purple', {
            'border-t-[1px] border-lightGrey': index !== 0,
            'p-2': compact,
            'p-4': !compact,
          })}
        >
          {item.groupName}
        </li>
      );
    }

    const itemContent = (
      <span
        className={lastMatchedIndex >= 0 ? 'w-8/12' : 'w-full'}
        dangerouslySetInnerHTML={{
          __html: highlightSearchTerm ? getHighlightedParts(item.label, filterValue, matchAlgorithm) : item.label,
        }}
      />
    );

    return (
      <Fragment key={`${item.label}-${JSON.stringify(item.value)}-${item.groupName}${index}`}>
        {groupNameElement}
        <li
          title={item.label}
          className={clsx(
            'body2 group flex cursor-pointer items-center justify-between gap-4 px-4 py-3 text-left hover:bg-BG',
            {
              'bg-BG font-semibold text-purpleDarker': isActiveItem || highlightedIndex === index,
              'border-t-[1px] border-lightGrey': index === lastMatchedIndex + 1 && !item?.groupName,
            }
          )}
          key={`${item.label}-${JSON.stringify(item.value)}`}
          {...getItemProps({ index, item })}
        >
          {itemContent}
          {lastMatchedIndex >= 0 && item.percentage && (
            <span className="body2 flex h-[24px] min-w-[58px] items-center justify-center rounded bg-purpleLighter px-2 font-semibold text-purpleDark group-hover:bg-purple group-hover:text-white">
              {formatPercentage(item.percentage)}
            </span>
          )}
          {isActiveItem && !!item.value ? (
            <Icon id={`${JSON.stringify(item.value)}-${item.groupName}`} name="check" className="h-4 w-4 fill-purple" />
          ) : (
            <span className="block w-4"></span>
          )}
        </li>
      </Fragment>
    );
  };

  const displayItems = (inputs: TItem[]) => {
    if (inputs.length === 0) {
      return <li className="flex select-none px-4 py-3 text-sm">{noItemMessage}</li>;
    }
    const usedGroupNames: string[] = [];

    return inputs.map((item: TItem, index: number) => buildItem(item, index, usedGroupNames));
  };

  return (
    <div
      key="search"
      className="relative"
      {...getLabelProps()}
      onBlur={() => onBlur?.(selectedItem)}
      data-component="search"
    >
      <TextField
        data-testid={testId}
        compact={compact}
        adornmentLeft={adornmentLeft ? <Icon className="h-4 w-4" name={adornmentLeft} /> : null}
        label={label}
        shouldHideLabel={shouldHideLabel}
        tooltip={tooltip}
        inputTooltip={inputTooltip}
        isReadOnly={isReadonly}
        errorMessage={error}
        hideError={shouldHideError}
        hideDefaultBorder={hideDefaultBorder}
        classes={{
          input: cn('truncate', classNames?.input, {
            'border-purple': isOpen,
          }),
        }}
        isRequired={isRequired}
        inputProps={getInputProps({
          placeholder: placeholder || t('components.search.placeholder'),
          value: inputValue,
          ref: popupAnchorRef,
        })}
        adornmentRight={
          <div className="flex items-center justify-center gap-4">
            {displayClearIcon && (
              <Icon
                name={clearIcon}
                className="h-4 w-4"
                aria-label="Clear search"
                onClick={isReadonly ? undefined : e => handleClear(e)}
              />
            )}
            {showExpandIcon && (
              <Icon
                name={isOpen ? 'expand_less' : 'expand_more'}
                aria-label={isOpen ? 'collapse' : 'expand'}
                className={clsx('h-4 w-4', isReadonly ? 'text-darkGrey' : 'text-purple')}
                onClick={isReadonly ? undefined : toggleMenu}
              />
            )}
            {showSpinner && <Spinner />}
          </div>
        }
      />

      <Popover
        triggerRef={popupAnchorRef}
        isOpen={isOpen}
        offset={distanceMap['small']}
        style={{ width: popupAnchorRef?.current?.clientWidth || undefined, zIndex: 60 }}
        onOpenChange={value => !value && closeMenu()}
      >
        <ul
          className={clsx({
            'search-dropdown max-h-[350px] overflow-auto rounded border bg-white pb-2 pt-2 outline-none': isOpen,
          })}
          {...getMenuProps({}, { suppressRefError: true })}
        >
          {isOpen && displayItems(items)}
        </ul>
      </Popover>
    </div>
  );
};
