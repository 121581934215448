import { cn } from '@corify/helpers/cn';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import { Icon, IconName } from '../icon/icon';

export interface DefaultButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isDisabled?: boolean;
  icon?: IconName;
  loading?: boolean;
  inverted?: boolean;
  size?: 'large' | 'small' | 'xsmall';
  variant?: 'primary' | 'secondary' | 'text' | 'error' | 'default';
  testId?: string;
}

interface Props extends DefaultButtonProps {
  iconClassName?: string;
  inverted?: boolean;
}

export const Button = ({
  children,
  className,
  iconClassName,
  isDisabled,
  icon,
  loading,
  inverted = false,
  size = 'large',
  type = 'button',
  variant = 'default',
  testId,
  ...props
}: Props) => {
  let styles = '';

  if (variant !== 'text') {
    styles +=
      ' border-purple border-2 rounded-3xl font-semibold hover:opacity-70 focus:opacity-70 disabled:hover:opacity-100 disabled:focus:opacity-100 px-6';

    if (size === 'large') {
      styles += ' text-md h-10 py-3';
    } else if (size === 'small') {
      styles += ' text-xs h-8 py-2';
    } else if (size === 'xsmall') {
      styles += ' text-xs h-6 py-1';
    }
  }

  if (variant === 'primary') {
    if (inverted) {
      styles += ' bg-white text-purple border-white';
    } else {
      styles += ' bg-purple text-white';

      if (isDisabled) {
        styles += ' bg-purpleLighter border-transparent';
      } else if (loading) {
        styles += ' bg-purpleLight disabled:bg-purpleLight hover:opacity-100 focus:opacity-100';
      }
    }
  } else if (variant === 'secondary') {
    if (inverted || isDisabled) {
      styles += ' disabled:opacity-50 hover:opacity-50 disabled:hover:opacity-50';
    } else {
      styles += ' text-purple';
    }

    if (loading) {
      styles += ' bg-purpleLight disabled:bg-purpleLight hover:opacity-100 focus:opacity-100';
    }
  } else if (variant === 'error') {
    if (inverted) {
      styles += ' bg-white text-corifyRed border-corifyRed';

      if (isDisabled) {
        styles += ' bg-white text-corifyRed border-corifyRed opacity-50';
      }
    } else {
      styles += ' bg-corifyRed text-white border-transparent';
    }
  } else if (variant === 'text') {
    styles = 'bg-inherit border-none text-purple text-sm font-semibold text-sm';
  }

  let iconSize = 'w-5 h-5';

  if (size === 'small') {
    iconSize = 'w-4 h-4';
  } else if (size === 'xsmall') {
    iconSize = 'w-3 h-3';
  }

  return (
    <button
      className={cn('inline-flex items-center justify-center gap-2 text-center', styles, className)}
      type={type}
      data-testid={testId}
      {...props}
      disabled={isDisabled || loading}
    >
      {loading && <Icon className={cn('animate-spin', iconSize)} name="progress" />}
      {!loading && icon && <Icon className={cn(iconSize, iconClassName)} name={icon} />}
      {children}
    </button>
  );
};
