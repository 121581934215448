import { Icon } from '@corify/components/icon/icon';
import { RiskObjectStatus } from '@corify/types/risk-object';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Item } from './item';

interface Props {
  status: RiskObjectStatus;
  onClick?: () => void;
  isOpen?: boolean;
}

export const RiskObjectStatusStatic = forwardRef<HTMLButtonElement, Props>(({ status, onClick, isOpen }, ref) => {
  const { t } = useTranslation();

  const icon = onClick && (
    <Icon
      className={clsx('ml-3 h-4 w-4')}
      aria-label={isOpen ? 'expanded' : 'collapsed'}
      name={isOpen ? 'expand_less' : 'expand_more'}
    />
  );
  switch (status) {
    case 'IN_PROGRESS': {
      return (
        <Item
          className="flex bg-purpleLighter text-purpleDark shadow-[0_0_0_1px] shadow-purpleDark"
          onClick={onClick}
          ref={ref}
        >
          {t('components.status.IN_PROGRESS')}
          {icon}
        </Item>
      );
    }
    case 'APPROVED': {
      return (
        <Item className="flex bg-corifyDarkGreen text-white" onClick={onClick} ref={ref}>
          {t('components.status.APPROVED')}
          {icon}
        </Item>
      );
    }
    case 'READY_FOR_APPROVAL': {
      return (
        <Item
          className="flex bg-corifyGreenLighter text-corifyDarkGreen shadow-[0_0_0_1px] shadow-corifyDarkGreen"
          onClick={onClick}
          ref={ref}
        >
          {t('components.status.READY_FOR_APPROVAL')}
          {icon}
        </Item>
      );
    }
    default: {
      throw new Error(`Status ${status} is not handled`);
    }
  }
});

RiskObjectStatusStatic.displayName = 'RiskObjectStatus';
