import { DropdownItem, MatchAlgorithmFn, UniqueFilterFn } from '@corify/components/inputs/search/types';

import { GDVEnterprise, GDVEnterpriseSimple, GDVEnterpriseWithSubsidiaries } from './types';

export type EnterpriseSearchItem = DropdownItem & {
  name: string;
  isSubsidiary: boolean;
};

export const getEnterpriseFullName = (name: GDVEnterprise['enterpriseName']) =>
  name.name1.concat(name.name2 ? ` ${name.name2}` : '');

export const mapSimpleEnterpriseToGDVEnterprise = (
  enterprise?: GDVEnterpriseSimple | null
): GDVEnterprise | undefined => {
  if (!enterprise) {
    return undefined;
  }
  const { enterpriseId, enterpriseName } = enterprise;

  return {
    enterpriseId,
    enterpriseName: {
      name1: enterpriseName,
      name2: '',
    },
  };
};

export const mapEnterpriseToDropdownItem = (
  groupName: string,
  enterprise?: GDVEnterprise | null
): EnterpriseSearchItem | undefined =>
  enterprise
    ? {
        label: getEnterpriseFullName(enterprise.enterpriseName),
        name: getEnterpriseFullName(enterprise.enterpriseName),
        value: enterprise.enterpriseId,
        isSubsidiary: false,
        groupName,
      }
    : undefined;

export const getMappedSearchItems = (availableEnterprises: GDVEnterpriseWithSubsidiaries[], groupName: string) =>
  availableEnterprises.reduce<EnterpriseSearchItem[]>((enterprises, enterprise) => {
    const mappedEnterprise = mapEnterpriseToDropdownItem(groupName, enterprise);
    if (mappedEnterprise) {
      enterprises.push(mappedEnterprise);
    }

    if (enterprise.subsidiaryCompanies.length > 0) {
      const subsidiaryCompanies = enterprise.subsidiaryCompanies.map<EnterpriseSearchItem>(name => ({
        groupName,
        label: mappedEnterprise?.label || getEnterpriseFullName(enterprise.enterpriseName),
        name: getEnterpriseFullName(name),
        value: enterprise.enterpriseId,
        isSubsidiary: true,
      }));
      enterprises.push(...subsidiaryCompanies);
    }

    return enterprises;
  }, []);

export const checkEnterpriseItem: MatchAlgorithmFn<EnterpriseSearchItem> = (item, inputValue) => {
  const lowerCasedInputValue = inputValue.toLowerCase();
  return (
    item.label.toLowerCase().includes(lowerCasedInputValue) || item.name.toLowerCase().includes(lowerCasedInputValue)
  );
};

export const enterpriseUniqueFilterFn: UniqueFilterFn<EnterpriseSearchItem> = item => item.name;
