import { useContext } from 'react';

import { TableContext } from './corify-table-provider';

export const useCorifyTable = <TColumns, TFiltering>() => {
  const context = useContext<TableContext<TColumns, TFiltering> | null>(TableContext);

  if (!context) {
    throw new Error('useCorifyTable must be used within a TableProvider');
  }

  return context;
};
