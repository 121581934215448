import { SortProps } from '@corify/helpers/api/use-collection-fetch';
import { PaginationState } from '@tanstack/react-table';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

import { useTableSort } from '../use-table-sort';

export type TableContext<TColumns, TFiltering> = {
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  goToFirstPage: () => void;
  sorting: SortProps<TColumns>;
  handleSortChange: (column: TColumns) => void;
  getSortIcon: (column: TColumns) => 'ascending' | 'descending' | undefined;
  filtering: TFiltering;
  setFiltering: Dispatch<SetStateAction<TFiltering>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableContext = createContext<TableContext<any, any> | null>(null);

type Props<TColumns, TFiltering> = PropsWithChildren & {
  defaultSorting?: SortProps<TColumns>;
  defaultFiltering?: TFiltering;
  defaultPagination?: PaginationState;
};

export const CorifyTableProvider = <TColumns extends string, TFiltering>({
  defaultSorting = {},
  defaultFiltering,
  defaultPagination = { pageIndex: 0, pageSize: 10 },
  children,
}: Props<TColumns, TFiltering>) => {
  const [pagination, setPagination] = useState<PaginationState>(defaultPagination);
  const [filtering, setFiltering] = useState<TFiltering | undefined>(defaultFiltering);
  const { sorting, handleSortChange, getSortIcon } = useTableSort<TColumns>(defaultSorting);

  return (
    <TableContext.Provider
      value={{
        pagination,
        setPagination,
        goToFirstPage: () => setPagination({ ...pagination, pageIndex: 0 }),
        sorting,
        handleSortChange,
        getSortIcon,
        filtering,
        setFiltering,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
