// eslint-disable-next-line check-file/filename-naming-convention
import { Icon } from '@corify/components/icon/icon';
import { HeaderCell } from '@corify/components/table/components/header-cell';
import { RowExpandButton } from '@corify/components/table/components/row-expand-button';
import { withTableProvider } from '@corify/components/table/provider/with-table-provider';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { endpoints } from '@corify/constants/endpoints';
import { addUrlParameters } from '@corify/helpers/url';
import { RISK_CONTRIBUTOR_ROUTES } from '@corify/routes/clients/risk-contributor/routes';
import { RiskClass, RiskModuleType } from '@corify/types/risk-module';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { baseColumns, DEFAULT_NOTIFICATION_TABLE_PROPS } from '../common/constants';
import { NotificationPageV2 } from '../common/notification-page-v2';

const FinishedImportsV2 = () => {
  const { t } = useTranslation();
  const linkClass: RiskClass = 'vehicle';
  const linkModule: RiskModuleType = 'vehicles';

  return (
    <NotificationPageV2
      headerTitle={t('fleetManagement.navigation.finishedImports')}
      endpoint={endpoints.cfm.notifications.finishedImports}
      isLocked
      hideNotificationHeader
      columnKeys={[...baseColumns, 'approveAutomatically', 'editors', 'importTime', 'riskObjectVersionState']}
      prependColumns={[
        {
          id: 'vehicleNotificationId',
          header: () => <HeaderCell />,
          size: 100,
          cell: ({ row }) => (
            <div className="flex justify-between px-6 py-2">
              <div className="flex items-center gap-3 py-2">
                <RowExpandButton
                  onClick={() => row.toggleExpanded()}
                  isExpanded={row.getIsExpanded()}
                  ariaLabel={row.getIsExpanded() ? 'Collapse' : 'Expand'}
                />
                <Tooltip position="top" overlay={t('fleetManagement.columns.openRiskObject')}>
                  <Link
                    to={
                      row.original.assignedCustomer?.customerId
                        ? addUrlParameters(RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_RISK_OBJECTS_VERSIONS_ITEM_ROUTE, {
                            riskObjectId: row.original.riskObjectId,
                            version: row.original.riskObjectBusinessVersion?.toString(),
                            clientId: row.original.brokerId,
                            customerId: row.original.assignedCustomer?.customerId,
                            type: linkModule,
                            riskClass: linkClass,
                          })
                        : ''
                    }
                  >
                    <Icon
                      name="link"
                      aria-label="link"
                      className="h-[18px] w-[18px] text-purple hover:cursor-pointer"
                    />
                  </Link>
                </Tooltip>
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

const FinishedImportsWrapV2 = withTableProvider(FinishedImportsV2, DEFAULT_NOTIFICATION_TABLE_PROPS);

export default FinishedImportsWrapV2;
