import { escapeRegExp, uniqBy } from 'lodash-es';

import { DropdownItem, MatchAlgorithm, SearchItemsFilterFunction, UniqueFilterFn } from './types';

export const getHighlightedParts = <TItem extends DropdownItem<unknown>>(
  text: string,
  search: string,
  strategy: MatchAlgorithm<TItem> = 'includesExact'
) => {
  if (!search) {
    return text;
  }

  switch (strategy) {
    case 'includesExact':
      return text.replace(
        new RegExp(escapeRegExp(search), 'gi'),
        match => `<mark data-testid="search-highlight">${match}</mark>`
      );
    case 'includesAnyWord':
      const regexStr = search
        .split(/\s+/)
        .reduce((str, word) => `${str}${escapeRegExp(word)}|`, '')
        .slice(0, -1);
      return text.replaceAll(
        new RegExp(`(${regexStr})`, 'gi'),
        match => `<mark data-testid="search-highlight">${match}</mark>`
      );
    default:
      return text;
  }
};

const defaultUniqueFilterFn: UniqueFilterFn<DropdownItem<unknown>> = item =>
  item?.groupName ? `${JSON.stringify(item.value)}-${item.groupName}` : JSON.stringify(item.value);

export const getFilteredItems: SearchItemsFilterFunction = options => {
  const { itemOptions, inputValue, matchAlgorithm, uniqueFilterFn = defaultUniqueFilterFn } = options;
  const lowerCasedInputValue = inputValue?.toLowerCase() ?? '';

  return uniqBy(itemOptions, uniqueFilterFn).filter(item => {
    switch (true) {
      case matchAlgorithm === 'includesAnyWord':
        return lowerCasedInputValue.split(/\s+/).some(word => item.label?.toLowerCase().includes(word));
      case typeof matchAlgorithm === 'function':
        return matchAlgorithm(item, lowerCasedInputValue);
      case matchAlgorithm === 'includesExact':
      default:
        return item.label.toLowerCase().includes(lowerCasedInputValue);
    }
  });
};

export const extractMatchedGroup = <TItem extends DropdownItem<unknown>>(items: TItem[], maxTopMatches?: number) => {
  if (!maxTopMatches) {
    return items;
  }

  const topMatches = items
    .filter(item => typeof item.percentage === 'number')
    .sort((a, b) => (b.percentage as number) - (a.percentage as number))
    .slice(0, maxTopMatches);

  const remainingItems = items
    .filter(item => !topMatches.includes(item))
    .map(item => {
      item.percentage = undefined;

      return item;
    });

  return [...topMatches, ...remainingItems];
};

/**
 * Formats a percentage value to a string with a percentage sign.
 *  @param percentage - The percentage value to format from 0 to 1.
 *  @returns The formatted percentage value.
 *  @example
 *  formatPercentage(0.1236) // '12.4%'
 *  formatPercentage(0.12) // '12%'
 **/
export const formatPercentage = (percentage: number) => {
  if (percentage < 0) {
    return '';
  }
  const percentageValue = parseFloat((percentage * 100).toFixed(1));
  return `${percentageValue}%`;
};
