export const endpoints = {
  cfm: {
    notifications: {
      available: '/brokers/:brokerId/cfm/notifications/available',
      availableCustomers: '/brokers/:brokerId/cfm/notifications/:notificationId/customers',
      availableFleets: '/brokers/:brokerId/cfm/notifications/:notificationId/fleets',
      availableEnterprises: '/brokers/:brokerId/enterprise-customers',
      triggerImport: '/brokers/:brokerId/cfm/notifications/import',
      triggerPause: '/brokers/:brokerId/cfm/notifications/pause',
      triggerUnPause: '/brokers/:brokerId/cfm/notifications/unpause',
      finishedImports: '/brokers/:brokerId/cfm/notifications/imports/finished',
      pausedImports: '/brokers/:brokerId/cfm/notifications/imports/paused',
      enterprise: '/brokers/:brokerId/cfm/notifications/:notificationId/enterprise',
      assignedFleet: '/brokers/:brokerId/cfm/notifications/:notificationId/assigned-fleet',
      assignedCustomer: '/brokers/:brokerId/cfm/notifications/:notificationId/assigned-customer',
      approveAutomatically: '/brokers/:brokerId/cfm/notifications/:notificationId/approve-automatically',
      vehicleRiskIndex: '/brokers/:brokerId/cfm/notifications/:notificationId/vehicle-risk-index',
      triggerReload: '/brokers/:brokerId/cfm/notifications/:notificationId/reload',
      rawData: '/brokers/:brokerId/cfm/notifications/:notificationId/raw-data',
      notificationsUpload: '/brokers/:brokerId/cfm/notifications/gdv-notifications',
    },
  },
  myTasks: {
    assignedRiskObjectsBrokers: '/brokers/:brokerId/assigned-risk-objects',
    assignedRiskObjectsCustomers: '/customers/:customerId/assigned-risk-objects',
  },
  clients: {
    address: '/clients/:clientId/address',
    data: '/clients/:clientId',
    existingParentClients: '/clients/:mainClientId/possible-parents/:clientId',
    hierarchy: '/clients/:clientId/hierarchy',
    list: '/clients',
    logo: '/clients/:clientId/logo',
    mainData: '/clients/:clientId/main-data',
    newParentClients: '/clients/:mainClientId/possible-parents',
    users: {
      assignments: {
        list: '/clients/:clientId/user-assignments',
        possibleUserAssignments: '/clients/:clientId/possible-user-assignments',
        edit: '/clients/:clientId/users/:userId/assignments',
      },
      details: '/clients/:clientId/users/:userId',
      list: '/clients/:clientId/users',
      users: '/clients/:clientId/users',
    },
  },
  crom: {
    latestVersion: '/crom',
    version: '/crom/:version',
    creator: '/crom/csv',
    hsnTsnDictionary: '/dictionaries/hsn-tsn',
  },
  customer: {
    basicData: '/customers/:customerId/basic-data',
    hierarchy: '/customers/:customerId/hierarchy',
    list: '/brokers/:brokerId/customers',
  },
  brokers: {
    customers: {
      list: '/brokers/:brokerId/customers',
      put: '/brokers/:brokerId/customers/:customerId',
    },
  },
  modules: {
    list: '/customers/:customerId/modules',
    state: '/customers/:customerId/modules/:module/state',
    possibleUserAssignment: '/customers/:customerId/modules/:moduleType/possible-user-assignments',
    adjustUserAssignments: '/customers/:customerId/modules/:moduleType/user-assignments',
  },
  riskObjects: {
    create: `/customers/:customerId/risk-objects`,
    item: '/customers/:customerId/risk-objects/:riskObjectId',
    approved: '/customers/:customerId/risk-objects/:riskObjectId/approved',
    transition: '/customers/:customerId/risk-objects/:riskObjectId',
    update: `/customers/:customerId/risk-objects/:riskObjectId`,
    finishedEditing: `/customers/:customerId/risk-objects/:riskObjectId/finished-editing`,
    possibleUserAssignment: '/customers/:customerId/risk-objects/:riskObjectId/possible-user-assignments',
    adjustUserAssignments: '/customers/:customerId/risk-objects/:riskObjectId/user-assignments',
    lock: '/customers/:customerId/risk-objects/:riskObjectId/lock',
    validityDates: '/customers/:customerId/risk-objects/:riskObjectId/validity-dates',
    list: '/customers/:customerId/risk-objects',
    searchReferencableObjects: '/customers/:customerId/risk-objects/:riskObjectId/referencable-objects',
    versions: {
      list: '/customers/:customerId/risk-objects/:riskObjectId/versions',
      create: '/customers/:customerId/risk-objects/:riskObjectId/versions',
      item: '/customers/:customerId/risk-objects/:riskObjectId/versions/:version',
      delete: '/customers/:customerId/risk-objects/:riskObjectId',
    },
    documents: {
      list: '/customers/:customerId/risk-objects/:riskObjectId/documents',
      create: '/customers/:customerId/risk-objects/:riskObjectId/documents',
      item: '/customers/:customerId/risk-objects/:riskObjectId/documents/:riskObjectDocumentId',
      delete: '/customers/:customerId/risk-objects/:riskObjectId/documents/:riskObjectDocumentId',
      content: '/customers/:customerId/risk-objects/:riskObjectId/documents/:riskObjectDocumentId/content',
    },
    contractRelevant: '/customers/:customerId/risk-objects/:riskObjectId/contract-relevant',
    import: {
      building: '/customers/:customerId/risk-objects-commands/import-buildings-command',
    },
  },
  dictionaries: {
    hsn: '/dictionaries/hsn',
    tsn: '/dictionaries/hsn/:hsn/tsn',
    notificationsFilter: '/dictionaries/brokers/:brokerId/notifications',
  },
  userPreferences: '/user-preferences',
  userRoles: '/user-roles',
} as const;
