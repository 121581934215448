import { toast as toastify, ToastContainer as ToastifyToastContainer, ToastOptions } from 'react-toastify';

import { Icon } from '../icon/icon';

export const toast = (message: string, options: ToastOptions = {}) => {
  const config: ToastOptions = {
    type: 'success',
    position: 'top-center',
    className: 'cursor-pointer flex justify-between overflow-hidden p-4 font-montserrat bg-white text-black text-sm',
    bodyClassName: 'p-0 m-0 flex gap-2',
    hideProgressBar: true,
    closeButton: false,
    autoClose: 3000,
    ...options,
  };

  switch (config.type) {
    case 'error': {
      return toastify.error(message, {
        ...config,
        icon: <Icon data-testid="toast-error" name="error_outline" className="h-5 w-5 fill-corifyRed" />,
      });
    }

    case 'success': {
      return toastify.success(message, {
        ...config,
        icon: <Icon data-testid="toast-success" name="check_outline" className="h-5 w-5 text-corifyDarkGreen" />,
      });
    }

    default: {
      throw new Error(`${config.type} type is not handled`);
    }
  }
};

export const errorToast = (message: string, options: ToastOptions = {}) =>
  toast(message, { type: 'error', ...options });

export const ToastContainer = () => <ToastifyToastContainer role="dialog" className="w-max text-sm" />;
