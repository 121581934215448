import clsx from 'clsx';

export interface Props {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  variant?: 'light' | 'dark';
}

export const Divider = ({ className, orientation = 'horizontal', variant = 'light' }: Props) => {
  return (
    <div
      className={clsx(
        orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
        variant === 'light' ? 'bg-lightGrey' : 'bg-grey',
        className
      )}
    />
  );
};
