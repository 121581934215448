import { Icon } from '@corify/components/icon/icon';
import { Dropdown } from '@corify/components/inputs/dropdown/dropdown';
import { Paper } from '@corify/components/paper/paper';
import { Sidebar } from '@corify/components/sidebar/sidebar';
import { useCorifyTable } from '@corify/components/table/provider/use-corify-table';
import { Table } from '@corify/components/table/table';
import { NotificationMessage } from '@corify/pages/fleet-management/common/notification-fetch-message';
import { FMDataEndpoint } from '@corify/pages/fleet-management/common/use-notifications-data';
import {
  GDVNotificationImported,
  GDVNotificationsImportedResponse,
  NotificationColumn,
  NotificationId,
  SortingColumns,
} from '@corify/pages/fleet-management/types';
import { ColumnDef, ColumnPinningState } from '@tanstack/react-table';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PAGE_SIZE, notificationPageOptions } from './constants';
import NotificationDataHeaderV2 from './notification-data-header-v2';
import { RawDataExpandableContent } from './raw-data-expandable-row/raw-data-expandable-content';
import { useNotificationColumns } from './use-notification-columns';
import { NotificationFilters } from './use-notification-filtering';
import { useNotificationsHeaderV2 } from './use-notifications-header-v2';

type NotificationPageProps = {
  headerTitle: string;
  actionButton?: ({
    selectedIdsForAction,
    resetView,
    data,
  }: {
    selectedIdsForAction: NotificationId[];
    resetView: () => void;
    data?: GDVNotificationsImportedResponse;
  }) => ReactNode;
  prependColumns?: ColumnDef<GDVNotificationImported>[];
  dialog?: ({ resetView }: { resetView: () => void }) => ReactNode;
  isLocked?: boolean;
  lockColumns?: (keyof GDVNotificationImported)[];
  columnKeys: NotificationColumn[];
  hideNotificationHeader?: boolean;
} & FMDataEndpoint;

export type NotificationPageRef = {
  reloadPage: () => void;
  resetView: () => void;
};

export const NotificationPageV2 = forwardRef<NotificationPageRef, NotificationPageProps>(
  (
    {
      headerTitle,
      endpoint,
      actionButton,
      prependColumns = [],
      dialog,
      isLocked,
      lockColumns = [],
      columnKeys,
      hideNotificationHeader,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const cfmConfigPanelEnabled = useFlag('cfm-config-panel');

    const {
      setPagination,
      pagination: { pageSize },
    } = useCorifyTable<SortingColumns, NotificationFilters>();

    // 'cfm-config-panel'
    const onPaginationChanged = (selectedItem?: string | null) =>
      setPagination({
        pageIndex: 0,
        pageSize: selectedItem ? parseInt(selectedItem) : DEFAULT_PAGE_SIZE,
      });
    // 'cfm-config-panel'

    const {
      isValidating,
      localData,
      error,
      data,
      mutate,
      resetView,
      rowSelectionState,
      tableSelectionProps,
      selectedNotificationIds,
    } = useNotificationsHeaderV2({ endpoint, allowReloadInterval: true });

    useImperativeHandle(
      ref,
      () => ({
        reloadPage: mutate,
        resetView,
      }),
      [mutate, resetView]
    );

    const columns = useNotificationColumns({
      isLocked,
      lockColumns,
      mutate,
      selectedNotificationIds,
      columnKeys,
      prependColumns,
    });

    const [columnPinning] = useState<ColumnPinningState>({
      left: ['vehicleNotificationId', 'licensePlate'],
    });

    return (
      <>
        <div id="layout-title" className="relative mb-8 flex h-5 items-center">
          <h2>{headerTitle}</h2>
          {cfmConfigPanelEnabled && (
            <Sidebar
              toggle={<Icon className="ml-auto h-5 w-5 text-purple" name="cog" />}
              title={t('fleetManagement.configuration.sidebarHeader')}
              containerClassNames="ml-auto"
            >
              <Dropdown
                items={notificationPageOptions}
                label={t('fleetManagement.configuration.numberPerPage')}
                value={pageSize.toString()!}
                isClearable={false}
                onChange={onPaginationChanged}
              />
            </Sidebar>
          )}
        </div>
        {!hideNotificationHeader && (
          <NotificationDataHeaderV2
            numberOfSelectedIdsForImport={selectedNotificationIds.length}
            actionButton={actionButton?.({ selectedIdsForAction: selectedNotificationIds, resetView, data })}
          />
        )}
        <Paper
          className={clsx('flex grow flex-col p-0', {
            'h-[calc(100vh-116px)]': hideNotificationHeader,
            'h-[calc(100vh-181px)]': !hideNotificationHeader,
          })}
        >
          {error && <NotificationMessage message={t('fleetManagement.errorWhileFetching')} />}

          {!error && (
            <>
              <Table
                data={localData?.items || []}
                oldDesign={false}
                columns={columns}
                manualPagination
                manualFiltering
                manualSorting
                enableStickyHeader
                showStickyCellSeparatorAt={100}
                isLoading={isValidating}
                rowCount={localData?.totalItems ?? 0}
                state={{ rowSelection: rowSelectionState }}
                initialState={{ columnPinning }}
                {...tableSelectionProps}
                pageSize={50}
                noDataMessage={
                  !isValidating && <NotificationMessage message={t('fleetManagement.noDataForAppliedFilter')} />
                }
                expandedRowContent={row => (
                  <RawDataExpandableContent notificationId={row.original.vehicleNotificationId} />
                )}
                classNames={{
                  root: localData?.items === undefined ? 'h-[calc(100vh-240px)] overflow-hidden' : 'overflow-x-auto',
                  table: 'overflow-y-auto',
                  tableBodyCell: 'p-0 pt-[1px] pr-[1px]',
                  tableHeaderCell: 'p-0',
                  rowSelectedBg: 'bg-BG',
                }}
              />
            </>
          )}
        </Paper>
        {dialog?.({ resetView })}
      </>
    );
  }
);

NotificationPageV2.displayName = 'NotificationPage';
