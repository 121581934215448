import { Dropdown, DropdownItem } from '@corify/components/inputs/dropdown/dropdown';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CalendarProps } from 'react-date-range';

interface Props {
  currFocusedDate: Date;
  changeShownDate: (value: Date | number | string, mode?: 'set' | 'setYear' | 'setMonth' | 'monthOffset') => void;
  calendarProps: CalendarProps;
  formatMinimum?: string;
  formatMaximum?: string;
  id?: string;
}

export const Navigator = ({
  currFocusedDate,
  changeShownDate,
  calendarProps: { locale },
  formatMinimum,
  formatMaximum,
  id,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const currentYear = new Date().getFullYear();

  const minDate = useMemo(() => new Date(formatMinimum ?? `${currentYear - 100}-01-01`), [formatMinimum]);
  const maxDate = useMemo(() => new Date(formatMaximum ?? `${currentYear + 20}-12-31`), [formatMaximum]);

  const yearItems: DropdownItem[] = useMemo(() => {
    const years: number[] = [];

    for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++) {
      years.push(i);
    }

    return years
      .map(year => ({
        label: year.toString(),
        value: year.toString(),
      }))
      .reverse();
  }, [maxDate, minDate]);

  const getYear = useCallback((): string => {
    let year: string | undefined;

    yearItems.forEach(item => {
      if (!year && item.label === currFocusedDate.getFullYear().toString()) {
        year = item.value as string;
      }
    });

    return year || (yearItems[yearItems.length - 1].value as string);
  }, [currFocusedDate, yearItems]);

  const monthItems: DropdownItem[] = useMemo(() => {
    const selectedYear = getYear();

    const months = Array(selectedYear === maxDate.getFullYear().toString() ? maxDate.getMonth() + 1 : 12)
      .fill(null)
      .map((_, month) => ({
        label: locale?.localize?.month(month),
        value: month.toString(),
      }));

    if (selectedYear === minDate.getFullYear().toString()) {
      months.splice(0, minDate.getMonth());
    }

    return months;
  }, [getYear, minDate, maxDate, locale?.localize]);

  const getMonth = useCallback((): string => {
    let month: string | undefined;

    monthItems.forEach(item => {
      if (!month && item.value === currFocusedDate.getMonth().toString()) {
        month = item.value as string;
      }
    });

    return month || (monthItems[monthItems.length - 1].value as string);
  }, [currFocusedDate, monthItems]);

  useEffect(() => {
    changeShownDate(getMonth(), 'setMonth');
    changeShownDate(Number(getYear()), 'setYear');
    // This is fine, because we only want to run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [ref]);

  return (
    <div ref={ref} className="flex justify-between p-4">
      <Dropdown
        classNameInput="p-2 w-[180px] h-[35px] dropdown-month"
        classNameInputText="font-semibold"
        items={monthItems}
        label="month"
        onChange={value => {
          changeShownDate(value ?? currFocusedDate.getMonth().toString(), 'setMonth');
        }}
        value={getMonth()}
        shouldHideLabel
        zIndex="z-[67]"
        id={id && `${id}-month`}
        isClearable={false}
      />
      <Dropdown
        classNameInput="p-2 h-[35px] dropdown-year"
        classNameInputText="font-semibold"
        items={yearItems}
        label="year"
        onChange={value => {
          const year = value ? parseInt(value) : currFocusedDate.getFullYear();

          changeShownDate(year, 'setYear');
        }}
        value={getYear()}
        shouldHideLabel
        zIndex="z-[67]"
        id={id && `${id}-year`}
        isClearable={false}
      />
    </div>
  );
};
