import './markdown-overlay.css';

import ReactMarkdown from 'react-markdown';

interface Props {
  markdownContent: string;
}

export const MarkdownOverlay = ({ markdownContent }: Props) => (
  <ReactMarkdown className="tooltip">{markdownContent}</ReactMarkdown>
);
