import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

interface Props {
  className: string;
  children: ReactNode;
  onClick?: () => void;
}

export const Item = forwardRef<HTMLButtonElement, Props>(({ children, className, onClick }, ref) => (
  <button
    ref={ref}
    data-testid="risk-object-status-button"
    className={clsx(
      'body3 w-fit whitespace-nowrap rounded-full px-4 py-1 font-semibold uppercase leading-4',
      className,
      !onClick && 'cursor-default'
    )}
    onClick={onClick}
  >
    {children}
  </button>
));

Item.displayName = 'Item';
