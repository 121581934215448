import { Icon } from '@corify/components/icon/icon';

type DataListRowExpandButtonProps = {
  onClick: () => void;
  isExpanded?: boolean;
  ariaLabel: string;
};
export const RowExpandButton = ({ onClick, isExpanded, ariaLabel }: DataListRowExpandButtonProps) => (
  <button onClick={onClick} type="button">
    <Icon className="h-4 w-4 text-purple" aria-label={ariaLabel} name={isExpanded ? 'expand_less' : 'expand_more'} />
  </button>
);
