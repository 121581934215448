import { cn } from '@corify/helpers/cn';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  compact?: boolean;
}

export const Paper = forwardRef(({ children, className, compact }: Props, ref: ForwardedRef<HTMLDivElement | null>) => {
  return (
    <div
      ref={ref}
      className={cn(
        'bg-white p-6',
        {
          'p-4': compact,
        },
        className
      )}
    >
      {children}
    </div>
  );
});

Paper.displayName = 'Paper';
