import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@corify/components/loader/loader';
import { endpoints } from '@corify/constants/endpoints';
import { apiUrl } from '@corify/helpers/api/api';
import { ClientType } from '@corify/types/client';
import { Roles } from '@corify/types/user';
import { PropsWithChildren } from 'react';
import useSWR from 'swr/immutable';

import { mapUserRole } from './helpers';
import { UserContext } from './user-context';

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth0();
  const { data, isValidating } = useSWR<Roles>(apiUrl(endpoints.userRoles));

  if (isValidating || !data || !user) {
    return (
      <div className="h-screen w-screen">
        <Loader data-testid="user-provider-loader" />
      </div>
    );
  }

  const availableClients =
    data.userRoles.map(userRole => ({
      id: userRole.clientId,
      name: userRole.clientName,
      type: data.clientType as ClientType,
      userRoles: userRole.roles,
    })) ?? [];

  return (
    <UserContext.Provider
      value={{
        firstName: user.given_name || '',
        lastName: user.family_name || '',
        email: user.email || '',
        userId: data.userId || '',
        type: mapUserRole(
          data.globalUserRole,
          data.clientType,
          availableClients.length,
          Boolean(data.selectedClientId)
        ),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
