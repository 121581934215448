import { OverlayArrow } from 'react-aria-components';

export const PopupArrow = () => {
  return (
    <OverlayArrow>
      <svg width={12} height={12} viewBox="0 0 12 12" className="-translate-x-2.5 translate-y-0.5 rotate-180 transform">
        <path d="M0 0 L6 6 L12 0" fill="white" />
      </svg>
    </OverlayArrow>
  );
};
