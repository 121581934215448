import { useAuth0 } from '@auth0/auth0-react';
import { StatusError } from '@corify/components/error-boundary/status-error';
import { useErrorBoundary } from '@corify/components/error-boundary/use-error-boundary';
import { Loader } from '@corify/components/loader/loader';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { endpoints } from '@corify/constants/endpoints';
import { apiUrl } from '@corify/helpers/api/api';
import { ClientType } from '@corify/types/client';
import { Roles, UserClient } from '@corify/types/user';
import { jwtDecode } from 'jwt-decode';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import { getSelectedClient } from './helpers';

type Client = {
  selectedClient?: UserClient;
  availableClients: UserClient[];
  changeSelectedClient: (selectedClientId: string) => void;
  mainClientId?: string;
};

const UserContext = createContext<Client | null>(null);

export const ClientProvider = ({ children }: PropsWithChildren) => {
  const { getAccessTokenSilently } = useAuth0();
  const swrApiFetcher = useSwrApiFetcher();
  const { data, error, isValidating, mutate } = useSWR<Roles>(apiUrl(endpoints.userRoles));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useErrorBoundary(error);
  const [isCFMAdmin, setIsCFMAdmin] = useState(false);

  const { trigger, isMutating } = useSWRMutation(
    apiUrl(`${endpoints.userPreferences}?selectedClientId=:selectedClientId`),
    swrApiFetcher('PUT')
  );

  const changeSelectedClient = useCallback(
    async (selectedClientId: string) => {
      try {
        await trigger({
          query: { selectedClientId },
        });

        navigate('/');

        const newData = await mutate(undefined, { revalidate: true });

        if (newData) {
          const newSelectedClient = getSelectedClient(newData);

          toast(t('components.clientProvider.toast', { client: newSelectedClient?.name }));
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast(t('errors.somethingWentWrong'), { type: 'error' });

        if (error instanceof StatusError) {
          handleError(error);
        }
      }
    },
    [handleError, mutate, navigate, t, trigger]
  );

  /**
   * Temporary solution to check if user has the permission to be a CFM_ADMIN
   *
   * TODO: Remove when the permissions are available in the userRoles endpoint
   */
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently?.();
        const decodedToken = jwtDecode<{ permissions: string[] }>(token);
        decodedToken?.permissions?.includes('cfm_admin') ? setIsCFMAdmin(true) : setIsCFMAdmin(false);
      } catch (error: unknown) {}
    })();
  }, [getAccessTokenSilently]);

  const selectedClient = useMemo(() => {
    const currentClient = getSelectedClient(data);

    // append FLEET_MANAGER role to userRoles if feature flag is enabled and userRoles have BROKER_KEY_ACCOUNT_MANAGER
    if (
      currentClient?.userRoles.includes('BROKER_KEY_ACCOUNT_MANAGER') &&
      !currentClient?.userRoles.includes('FLEET_MANAGER')
    ) {
      currentClient?.userRoles.push('FLEET_MANAGER');
    }
    // append CFM_ADMIN role to userRoles if user has the permission
    if (isCFMAdmin && !currentClient?.userRoles.includes('CFM_ADMIN')) {
      currentClient?.userRoles.push('CFM_ADMIN');
    }
    return currentClient;
  }, [data, isCFMAdmin]);

  useEffect(() => {
    if (selectedClient === undefined && !isMutating && data && data.userRoles.length >= 1) {
      (async () => {
        await changeSelectedClient(data.userRoles[0].clientId);
      })();
    }
  }, [changeSelectedClient, data, isMutating, selectedClient]);

  if (isValidating || !data || isMutating) {
    return (
      <div className="h-screen w-screen">
        <Loader data-testid="client-provider-loader" />
      </div>
    );
  }

  let mainClientId: string | undefined;

  const availableClients =
    data.userRoles.map(userRole => {
      if (
        userRole.roles.includes('BROKER_MAIN_CLIENT_ADMIN') ||
        userRole.roles.includes('INSURER_MAIN_CLIENT_ADMIN') ||
        userRole.roles.includes('CUSTOMER_MAIN_CLIENT_ADMIN')
      ) {
        mainClientId = userRole.clientId;
      }

      return {
        id: userRole.clientId,
        name: userRole.clientName,
        type: data.clientType as ClientType,
        userRoles: userRole.roles,
      } satisfies UserClient;
    }) ?? [];

  return (
    <UserContext.Provider
      value={{
        availableClients,
        changeSelectedClient,
        selectedClient,
        mainClientId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAvailableClients = (): UserClient[] => {
  const value = useContext(UserContext);

  if (value === null) {
    throw new Error(`${useAvailableClients.name} must be used within <${ClientProvider.name} />`);
  }

  return value.availableClients;
};

export const useSelectedClient = (): UserClient & {
  changeSelectedClient: (selectedClientId: string) => void;
  mainClientId?: string;
} => {
  const value = useContext(UserContext);
  const changeSelectedClient = useChangeSelectedClient();

  if (value === null) {
    throw new Error(`${useSelectedClient.name} must be used within <${ClientProvider.name} />`);
  }

  if (value.selectedClient === undefined) {
    throw new Error('There is no valid client defined');
  }

  return { ...value.selectedClient, mainClientId: value.mainClientId, changeSelectedClient };
};

export const useChangeSelectedClient = (): ((selectedClientId: string) => void) => {
  const value = useContext(UserContext);

  if (value === null) {
    throw new Error(`${useChangeSelectedClient.name} must be used within <${ClientProvider.name} />`);
  }

  return value.changeSelectedClient;
};
