import { Icon } from '@corify/components/icon/icon';
import { Spinner } from '@corify/components/spinner/spinner';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { Tooltip } from '@corify/components/tooltip/tooltip';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSWRMutation from 'swr/mutation';

import { GDVNotificationImported } from '../types';
import { useNotificationWarnings } from './use-notification-warnings';

const NotificationWarningAndReload = ({
  row,
  reloadPage,
}: {
  row: GDVNotificationImported;
  reloadPage?: () => void;
}) => {
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const swrApiFetcher = useSwrApiFetcher();
  const [isPending, setIsPending] = useState(false);

  const { warnings } = useNotificationWarnings(row);

  const { trigger } = useSWRMutation(
    apiUrl(
      addUrlParameters(endpoints.cfm.notifications.triggerReload, {
        brokerId,
        notificationId: row.vehicleNotificationId,
      })
    ),
    swrApiFetcher('POST')
  );

  const handleSave = useCallback(async () => {
    setIsPending(true);

    try {
      await trigger({});
      reloadPage?.();
    } catch {
      toast(<span className="px-2">{t('fleetManagement.triggerReload.error')}</span>, { type: 'error' });
    }

    setIsPending(false);
  }, [reloadPage, t, trigger]);

  const showReload = !isPending && reloadPage && warnings?.[0]?.canReload;
  const showSpinner = isPending || (reloadPage && row.importStatus === 'RELOADING');

  return (
    <>
      {warnings?.[0]?.warning && (
        <Tooltip overlay={warnings[0].warning} position="top" zIndex={10} maxWidth="280px">
          <Icon className="h-[16px] w-[16px] cursor-pointer" name="error_outline" aria-label="Error" />
        </Tooltip>
      )}

      {!showSpinner && showReload && (
        <Tooltip overlay={t('fleetManagement.import.reload')} position="top" zIndex={10} maxWidth="280px">
          <Icon
            name="reload"
            className="mr-2 h-[16px] w-[16px] cursor-pointer"
            aria-label="Reload"
            onClick={handleSave}
          />
        </Tooltip>
      )}

      {showSpinner && <Spinner className="h-[16px] w-[16px]" />}
    </>
  );
};

export default NotificationWarningAndReload;
