import { ResponseErrorCode } from '@corify/helpers/api/error-code-map';
import { isJsonResponse } from '@corify/helpers/api/helper';

import { StatusError } from '../error-boundary/status-error';

export type SwrApiError = Error & {
  status?: number;
  errorCode?: ResponseErrorCode;
  body?: string;
  requestUrl?: string;
};

export const fetcher = async (input: RequestInfo | URL, init?: RequestInit) => {
  return fetch(input, init).then(async response => {
    const data = isJsonResponse(response) ? await response.json() : await response.text();

    if (!response.ok) {
      if (response.status === 401) {
        throw new StatusError('SESSION_EXPIRED');
      }

      const error: SwrApiError = new Error(data?.message || response.statusText);

      error.status = response.status;
      error.errorCode = data.errorCode;
      error.body = data;
      error.requestUrl = typeof input !== 'string' ? ('url' in input ? input.url : input.toString()) : input;

      throw error;
    }

    return data;
  });
};
