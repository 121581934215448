import { Checkbox } from '@corify/components/inputs/checkbox/checkbox';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  actionButton: ReactNode;
  numberOfSelectedIdsForImport?: number;
};

const NotificationDataHeaderV2 = ({ actionButton, numberOfSelectedIdsForImport }: Props) => {
  const { t } = useTranslation();

  return (
    <div id="notification-header" className="flex w-full overflow-visible border-b bg-white">
      {actionButton && (
        <div className="flex items-center gap-8 px-6 py-4">
          <div className="ml-[33px] flex gap-1">
            <Checkbox
              label=""
              checked={true}
              filled={true}
              classes={{ icon: 'hover:cursor-default', label: 'font-semibold text-sm text-darkGrey' }}
              onChange={() => {}}
            />
            <span className="min-w-[18px] text-sm font-semibold">{`${numberOfSelectedIdsForImport}`}</span>
            <span className="text-sm font-semibold">{t('fleetManagement.overview.selected')}</span>
          </div>
          {actionButton}
        </div>
      )}
    </div>
  );
};

export default NotificationDataHeaderV2;
