import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { RISK_CONTRIBUTOR_ROUTES } from './routes';

const RiskObject = lazy(() => import('@corify/pages/risk-object/risk-object'));
const RiskObjectList = lazy(() => import('@corify/pages/risk-object-list/risk-object-list'));
const BrokerLeftNavigationLayout = lazy(() => import('@corify/layout/risk-contributor-layout'));
const RiskObjectVersions = lazy(() => import('@corify/pages/risk-object-versions/risk-object-versions'));
const RiskObjectVersion = lazy(() => import('@corify/pages/risk-object-version/risk-object-version'));
const RiskObjectAvailabilityChecker = lazy(() => import('@corify/layout/security/risk-object-availability-checker'));

export const getRiskContributorObjectsRoutes = () => [
  <Route key="risk-contributor-objects" element={<BrokerLeftNavigationLayout />}>
    <Route element={<RiskObjectAvailabilityChecker />}>
      <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_RISK_OBJECTS_ROUTE} element={<RiskObjectList />} />
    </Route>
  </Route>,
  <Route key="risk-contributor-objects-items" element={<BrokerLeftNavigationLayout noPadding />}>
    <Route element={<RiskObjectAvailabilityChecker />}>
      <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_RISK_OBJECTS_ITEM_ROUTE} element={<RiskObject />} />
      <Route
        path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_RISK_OBJECTS_VERSIONS_ROUTE}
        element={<RiskObjectVersions />}
      />
      <Route
        path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_RISK_OBJECTS_VERSIONS_ITEM_ROUTE}
        element={<RiskObjectVersion />}
      />
    </Route>
  </Route>,
];
