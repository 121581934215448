import { cn } from '@corify/helpers/cn';
import { HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  text: string;
  size: 'small' | 'medium' | 'big';
  className?: string;
  textClassName?: string;
}

export const Avatar = ({ text, size, className, textClassName, children, ...props }: Props) => {
  const avatarDefaultClassName = 'relative rounded-full';
  const textDefaultClassName = 'absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] font-bold';

  switch (size) {
    case 'big': {
      return (
        <div className={cn('h-20 w-20 flex-shrink-0 text-xl', avatarDefaultClassName, className)} {...props}>
          <span className={cn(textDefaultClassName, textClassName)}>{text}</span>
          {children}
        </div>
      );
    }
    case 'medium': {
      return (
        <div
          className={cn('h-[32px] w-[32px] flex-shrink-0 text-[11px]', avatarDefaultClassName, className)}
          {...props}
        >
          <span className={cn(textDefaultClassName, textClassName)}>{text}</span>
          {children}
        </div>
      );
    }
    case 'small': {
      return (
        <div className={cn('h-6 w-6 flex-shrink-0 text-[8px]', avatarDefaultClassName, className)} {...props}>
          <span className={cn(textDefaultClassName, textClassName)}>{text}</span>
          {children}
        </div>
      );
    }
  }
};
