import { ReactNode, useMemo } from 'react';

interface PaginationItem {
  pageIndex: number;
  pageLabel: ReactNode;
  disabled: boolean;
  active: boolean;
  renderMoreIcon?: 'left' | 'right';
}

interface Props {
  pageIndex: number;
  totalPages: number;
  onPageChange?: (page: number) => void;
}

export const usePagination = ({ pageIndex, totalPages }: Props) => {
  const pages: PaginationItem[] = useMemo(() => {
    const autoGeneratePagination = () =>
      Array(totalPages)
        .fill(null)
        .map((_, index) => {
          return {
            pageIndex: index,
            pageLabel: index + 1,
            disabled: pageIndex === index,
            active: pageIndex === index,
          };
        });

    const createPaginationWithHiddenElements = () => {
      const pageItems: PaginationItem[] = [];
      const firstPage = pageIndex === 0;
      const lastPage = pageIndex === totalPages - 1;
      const lastPageIndex = totalPages - 1;

      pageItems.push({
        pageIndex: 0,
        pageLabel: 1,
        disabled: firstPage,
        active: firstPage,
      });

      if (firstPage) {
        pageItems.push({
          pageIndex: 1,
          pageLabel: 2,
          disabled: false,
          active: false,
          renderMoreIcon: 'right',
        });
      }

      if (!firstPage && !lastPage) {
        const secondPage = pageIndex === 1;
        const renderMorePaginationItemsFromLeftSide = pageIndex > 2;
        if (!secondPage) {
          pageItems.push({
            pageIndex: pageIndex - 1,
            pageLabel: pageIndex,
            disabled: false,
            active: false,
            renderMoreIcon: renderMorePaginationItemsFromLeftSide ? 'left' : undefined,
          });
        }
        pageItems.push({
          pageIndex: pageIndex,
          pageLabel: pageIndex + 1,
          disabled: true,
          active: true,
        });
      }

      const penultimatePage = totalPages - 2;
      const renderMorePaginationItemsFromRightSide = pageIndex < penultimatePage;

      if (!firstPage && pageIndex !== lastPageIndex - 1 && !lastPage) {
        pageItems.push({
          pageIndex: pageIndex + 1,
          pageLabel: pageIndex + 2,
          disabled: false,
          active: false,
          renderMoreIcon: renderMorePaginationItemsFromRightSide ? 'right' : undefined,
        });
      }

      if (lastPage) {
        pageItems.push({
          pageIndex: totalPages - 2,
          pageLabel: totalPages - 1,
          disabled: false,
          active: false,
          renderMoreIcon: 'left',
        });
      }

      pageItems.push({
        pageIndex: totalPages - 1,
        pageLabel: totalPages,
        disabled: lastPage,
        active: lastPage,
      });

      return pageItems;
    };

    if (totalPages <= 3) {
      return autoGeneratePagination();
    }

    return createPaginationWithHiddenElements();
  }, [pageIndex, totalPages]);

  return { pages };
};
