export const popupClassnames =
  'rounded-[4px] border border-lighterGrey bg-white shadow-[0px_2px_4px_0px] shadow-purpleLighter outline-none';

const Distances = 'none' || 'small' || 'medium' || 'large';
export type Distance = (typeof Distances)[number];

export const distanceMap: Record<Distance, number> = {
  none: 0,
  small: 8,
  medium: 16,
  large: 24,
};
