import clsx from 'clsx';
import { HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '../spinner/spinner';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'purple';
}

export const Loader = ({ color = 'purple', ...props }: Props) => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const { t } = useTranslation();

  return (
    <div {...props} className={twMerge(clsx('flex h-full w-full items-center justify-center', props.className))}>
      <Spinner className="mr-4" color={color} />
      <div
        className={clsx('text-[20px]', {
          'text-white': color === 'white',
          'text-purple': color === 'purple',
        })}
      >
        {t('loading')}
      </div>
    </div>
  );
};
