import { Button } from '@corify/components/button/button';
import { endpoints } from '@corify/constants/endpoints';
import { useTranslation } from 'react-i18next';

import { useTriggerAction } from '../common/use-trigger-action';
import { NotificationId } from '../types';

type Props = {
  selectedIdsForAction: NotificationId[];
  resetView: () => void;
};

const PausedImportsActionButton = ({ selectedIdsForAction, resetView }: Props) => {
  const { t } = useTranslation();

  const { handleAction } = useTriggerAction({
    endpoint: endpoints.cfm.notifications.triggerUnPause,
    payload: { vehicleNotificationIds: selectedIdsForAction },
    resetView,
  });

  return (
    <Button
      variant="primary"
      isDisabled={!selectedIdsForAction || selectedIdsForAction.length < 1}
      onClick={handleAction}
      size="small"
    >
      <span>{t('fleetManagement.stopPausing')}</span>
    </Button>
  );
};

export default PausedImportsActionButton;
