import { useLegalContext } from '@corify/context/legal/legal-context';
import { Language } from '@corify/types/language';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as EnglishContent } from './english.md';
import { ReactComponent as GermanContent } from './german.md';

const Imprint = () => {
  const { language } = useLegalContext();

  return (
    <>
      <Helmet>
        <title>{language === Language.DE ? 'Impressum' : 'Imprint'} | corify</title>
      </Helmet>
      {language === Language.DE ? <GermanContent /> : <EnglishContent />}
    </>
  );
};

export default Imprint;
