import { GDVNotification } from '../../types';
import { riskIndexList } from './risk-index-list';
import { ClearName, RiskIndexItem, VehicleRiskInformation, VehicleType } from './types';

export const getRiskIndexListInRange = (
  list: RiskIndexItem[],
  from: number,
  to: number,
  groupName: ClearName
): RiskIndexItem[] => {
  return list
    .filter((item: RiskIndexItem) => item.value >= from && item.value < to)
    .map(item => ({ ...item, groupName }));
};

export const vehicleRiskInformations: VehicleRiskInformation[] = [
  {
    vehicleType: 'Fahrzeugart 1',
    clearName: 'Leichtfahrzeug',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 0, 100, 'Leichtfahrzeug'),
  },
  {
    vehicleType: 'Fahrzeugart 2',
    clearName: 'Personenkraftwagen',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 100, 199, 'Personenkraftwagen'),
  },
  {
    vehicleType: 'Fahrzeugart 3',
    clearName: 'Kraftomnibus',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 600, 699, 'Kraftomnibus'),
  },
  {
    vehicleType: 'Fahrzeugart 4',
    clearName: 'Lastkraftwagen',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 200, 399, 'Lastkraftwagen'),
  },
  {
    vehicleType: 'Fahrzeugart 5',
    clearName: 'Zugmaschine',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 400, 499, 'Zugmaschine'),
  },
  {
    vehicleType: 'Fahrzeugart 7',
    clearName: 'Anhänger',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 500, 599, 'Anhänger'),
  },
  {
    vehicleType: 'Fahrzeugart 8',
    clearName: 'Sonstiges Kraftfahrzeug',
    riskIndexList: getRiskIndexListInRange(riskIndexList, 700, 806, 'Sonstiges Kraftfahrzeug'),
  },
];

export const getValuesOfRiskIndexList = () => riskIndexList.map(item => item.value);

const extractDropdownItemsFromVehicleRiskInformation = (arr: VehicleRiskInformation[]) => {
  return [
    ...arr
      .map(item => item.riskIndexList)
      .flat()
      .map(item => ({ ...item, value: item.value.toString() })),
  ];
};

export const getSortedVehicleRiskDropdownItems = (
  vehicleType: VehicleType | null | undefined,
  vehicleRiskInformation: VehicleRiskInformation[],
  suggestionText: string
) => {
  const suggestedGroupCopy = [
    ...vehicleRiskInformation
      .filter(item => item.vehicleType === vehicleType)
      .map(item => ({
        ...item,
        riskIndexList: item.riskIndexList.map(riskIndexListItem => ({
          ...riskIndexListItem,
          groupName: `${suggestionText} (${vehicleType})`,
        })),
      })),
  ];

  return extractDropdownItemsFromVehicleRiskInformation([...suggestedGroupCopy, ...vehicleRiskInformation]);
};

export const applyProbabilitiesToSortedVehicleRiskDropDownItems = (
  riskDropDownItems: ReturnType<typeof getSortedVehicleRiskDropdownItems>,
  vehicleRiskIndexProbabilities: GDVNotification['vehicleRiskIndexProbabilities']
) => {
  if (vehicleRiskIndexProbabilities?.length === 0) {
    return riskDropDownItems;
  }

  const valuesWithProbabilities = vehicleRiskIndexProbabilities?.map(item => item?.vehicleRiskIndex?.toString());
  const itemsWithProbabilities: ReturnType<typeof getSortedVehicleRiskDropdownItems> = [];

  riskDropDownItems.forEach(item => {
    if (valuesWithProbabilities?.includes(item.value)) {
      itemsWithProbabilities.push(item);
    }
  });

  return [
    ...itemsWithProbabilities.map(item => ({
      ...item,
      groupName: '',
      percentage: vehicleRiskIndexProbabilities?.find(prob => prob?.vehicleRiskIndex?.toString() === item?.value)
        ?.percentage,
    })),
    ...riskDropDownItems,
  ];
};

export const getGroupNameByIndex = (index: string) => {
  return vehicleRiskInformations.find(group => group.riskIndexList.some(item => item.value === Number.parseInt(index)))
    ?.clearName;
};
