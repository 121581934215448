import { RadioField } from '@corify/components/inputs/radio/radio-field';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GDVNotification } from '../types';
import { ApproveAutomaticallyPatch, useNotificationPatch } from './use-notification-patch';

type Props = { readonly?: boolean } & Pick<GDVNotification, 'approveAutomatically' | 'vehicleNotificationId'>;

const NotificationApproveRiskRadio = ({ approveAutomatically, vehicleNotificationId, readonly }: Props) => {
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const [automatically, setAutomatically] = useState(approveAutomatically);
  const { patchNotification } = useNotificationPatch<ApproveAutomaticallyPatch>(
    vehicleNotificationId,
    brokerId,
    'approveAutomatically'
  );

  const handleChange = async (checked: boolean) => {
    setAutomatically(checked);

    await patchNotification({ approveAutomatically: checked });
  };

  return (
    <div className="flex gap-8">
      <RadioField
        label={t('common.yes')}
        checked={automatically}
        onChange={() => handleChange(true)}
        isReadOnly={readonly}
      />
      <RadioField
        label={t('common.no')}
        checked={!automatically}
        onChange={() => handleChange(false)}
        isReadOnly={readonly}
      />
    </div>
  );
};

export default NotificationApproveRiskRadio;
