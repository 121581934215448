import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { cn } from '@corify/helpers/cn';
import { ValidationResult } from '@react-types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '../../divider/divider';
import { DatePicker } from '../../fields/date-picker/date-picker';

export type DateRangeValue = {
  from: string;
  to: string;
};

type DateRangeProps = {
  onChange: (value: DateRangeValue) => void;
  label?: string;
  value: DateRangeValue;
  disableKeyboard?: boolean;
  id: string;
  dividerType?: 'toLabel' | 'divider';
  textSize?: 'sm' | 'xs';
  classNames?: { containerProps?: string; wrapper?: string; icon?: string };
};

export const DateRange = ({
  label,
  value,
  onChange,
  id,
  disableKeyboard,
  classNames,
  dividerType,
  textSize,
}: DateRangeProps) => {
  const { t } = useTranslation();
  const [firstEntry, setFirstEntry] = useState<'to' | 'from' | undefined>(undefined);
  const [isValid, setIsValid] = useState<boolean>(true);

  const toLabel = t('fleetManagement.overview.dateFilter.to');

  const handleChange = (key: keyof DateRangeValue, updatedValue?: string) => {
    if (updatedValue && firstEntry === undefined) {
      setFirstEntry(key);
    } else if (!updatedValue && firstEntry === key) {
      setFirstEntry(undefined);
    }

    onChange({ ...value, [key]: updatedValue ?? '' });
  };

  const onValidation = (validation: ValidationResult) => {
    setIsValid(validation.isInvalid);

    if (!validation.isInvalid) {
      setFirstEntry(undefined);
    }
  };

  return (
    <div className={cn('flex flex-col items-start gap-2', classNames?.containerProps)}>
      {label && <span className="text-xs font-semibold text-darkGrey">{label}</span>}
      <div className="flex items-center justify-center gap-2">
        <DatePicker
          label={t('fleetManagement.overview.dateFilter.from')}
          shouldHideLabel
          onChange={val => handleChange('from', val)}
          value={value.from}
          formatMaximum={value.to || undefined}
          textSize={textSize}
          classNames={{
            icon: classNames?.icon,
            wrapper: cn('rounded-full px-4 py-3 uppercase items-center h-[40px] min-w-[200px]', classNames?.wrapper),
          }}
          popupPlacement="bottom start"
          disableKeyboard={disableKeyboard}
          shouldHideErrorPlaceholder
          id={`${id}-from`}
          onValidation={onValidation}
        />

        {(!dividerType || dividerType === 'toLabel') && (
          <span className="text-xs font-semibold text-darkGrey">{toLabel}</span>
        )}
        {dividerType === 'divider' && <Divider orientation="vertical" variant="dark" className="bg-lightGrey" />}

        <DatePicker
          label={toLabel}
          shouldHideLabel
          onChange={val => handleChange('to', val)}
          value={value.to}
          formatMinimum={value.from || undefined}
          textSize={textSize}
          classNames={{
            icon: classNames?.icon,
            wrapper: cn('rounded-full px-4 py-3 uppercase items-center h-[40px] min-w-[200px]', classNames?.wrapper),
          }}
          popupPlacement="bottom end"
          disableKeyboard={disableKeyboard}
          shouldHideErrorPlaceholder
          id={`${id}-to`}
          onValidation={onValidation}
        />
      </div>
      {firstEntry && isValid && (
        <ErrorMessageContainer error={t(`fleetManagement.overview.dateFilter.error.${firstEntry}`)} />
      )}
    </div>
  );
};
