import { logException } from '@corify/logging/log';
import { assertCrom, CromFormContext, CromJsonSchema, CromUnits } from '@corify/types/crom';
import { RiskClass } from '@corify/types/risk-module';
import { findSchemaDefinition, FormContextType, REF_KEY, RJSFSchema, RJSFValidationError } from '@rjsf/utils';
import { cloneDeep, forOwn, unset } from 'lodash-es';

const rootPrefixRegex = /\.(.*?)\./;

export const correctErrorId = (property: string) =>
  property.replace(rootPrefixRegex, '.').replace('[', '.').replace(']', '').toLowerCase();

export const mapCrom = (crom: CromJsonSchema, propertyName: RiskClass) => {
  // We need to remove $schema to disable schema validation on submit button.
  // We need to remove $id for a proper conditional behaviour working. It has no effect for the frontend.
  const { $id, $schema, ...rest } = crom;
  assertCrom(rest.properties);

  rest.properties = {
    [propertyName]: rest.properties[propertyName],
  };

  return makeCromSync(rest);
};

export const makeCromSync = (crom: CromJsonSchema) => {
  const iterate = (cromJsonSchema: CromJsonSchema) => {
    return forOwn<CromJsonSchema>(cromJsonSchema, (_, key: string) => {
      if (key === '$async') {
        cromJsonSchema.$async = false;
      }

      const cromJsonSchemaProperty = cromJsonSchema[key as keyof CromJsonSchema];
      if (typeof cromJsonSchemaProperty === 'object') {
        iterate(cromJsonSchemaProperty as CromJsonSchema);
      }
    });
  };

  return iterate(cloneDeep(crom));
};

const getGridSize = (width: number) => {
  if (width < 1200) {
    return 9;
  }

  return 12;
};

export const widthCalculator = (width: number, columnSize = 3) => {
  const gridSize = getGridSize(width);
  let calculateWidth = (columnSize / gridSize) * 100;

  if (calculateWidth > 100) {
    calculateWidth = 100;
  }
  return `${calculateWidth}%`;
};

const relevantErrorNames = ['required', 'type'];

export const getRelevantErrors = (errors: RJSFValidationError[], formContext: FormContextType) => {
  return errors.filter(error => {
    if (!error.property || !relevantErrorNames.includes(error.name || '')) {
      return false;
    }

    const errorPropertyWithoutRoot = error.property.replace(/^\.[^.]+/, '');

    return Object.entries(formContext).some(([key, value]) => value.isRelevant && key === errorPropertyWithoutRoot);
  });
};

export const getItemsSchema = (schema: CromJsonSchema, rootSchema: CromJsonSchema): CromJsonSchema => {
  assertCrom(schema.items);

  const itemsHaveRef = REF_KEY in schema.items;

  return itemsHaveRef
    ? (findSchemaDefinition(schema.items.$ref, rootSchema as RJSFSchema) as CromJsonSchema)
    : schema.items;
};

export const transformFormData = (formData: Record<string, unknown>, formContext: CromFormContext) => {
  // !! Don't trust your IDE, this cant be simplified. !!
  const keysToIgnore = Object.keys(formContext).filter(key => formContext[key].isRelevant === false);

  keysToIgnore.forEach(keyToIgnore => {
    // We can assume that the first formData key is the risk module type (building, location etc.)
    const rootSchemaKey = Object.keys(formData)[0];

    unset(formData, `${rootSchemaKey}${keyToIgnore}`);
  });

  return formData;
};

export const unitMapper = (unit: CromUnits | undefined) => {
  if (!unit) {
    return undefined;
  }

  switch (unit) {
    case 'PERCENT':
      return '%';
    case 'CENTIMETER':
      return 'cm';
    case 'METER':
      return 'm';
    case 'KILOMETER':
      return 'km';
    case 'SQ_CENTIMETER':
      return 'cm²';
    case 'SQ_METER':
      return 'm²';
    case 'CUBIC_METER':
      return 'm³';
    case 'SQ_KILOMETER':
      return 'km²';
    case 'EURO':
      return '(EUR)';
    case 'CELSIUS':
      return '°C';
    case 'FAHRENHEIT':
      return 'F';
    case 'KELVIN':
      return 'K';
    case 'KILOBYTE':
      return 'KB';
    case 'MEGABYTE':
      return 'MB';
    case 'GIGABYTE':
      return 'GB';
    case 'TERABYTE':
      return 'TB';
    case 'GRAM':
      return 'g';
    case 'KILOGRAM':
      return 'kg';
    case 'TON':
      return 't';
    case 'LITER':
      return 'l';
    case 'SECOND':
      return 's';
    case 'MINUTE':
      return 'mi';
    case 'HOUR':
      return 'h';
    case 'DAY':
      return 'd';
    case 'MONTH':
      return 'mo';
    case 'YEAR':
      return 'y';
    default: {
      logException(`Unit ${unit} is not handled`);
      return undefined;
    }
  }
};

export const idNormalizer = (id: string) => id.toLowerCase();
