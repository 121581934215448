import { ClientType, UserStatus } from '@corify/types/client';
import { UserType } from '@corify/types/user';

import { AvatarUser, isUserWithInvitationStatus, isUserWithType } from './avatar-user';

interface AvatarColors {
  textStyle: string;
  backgroundStyle: string;
}

export const getAvatarColor = (user: AvatarUser, clientType?: ClientType): AvatarColors => {
  const defaultColoring: AvatarColors = { backgroundStyle: 'bg-corifyDarkGreen', textStyle: 'text-white' };

  if (isUserWithInvitationStatus(user)) {
    if (!clientType) {
      throw new Error('Client type needs to be defined in the context of user invitation');
    }

    return getColorsByInvitationStatus(user.status, clientType);
  }

  if (isUserWithType(user)) {
    return getColorsByType(user.clientType);
  }

  return defaultColoring;
};

const getColorsByType = (type: UserType): AvatarColors => {
  switch (type) {
    case 'BROKER': {
      return { backgroundStyle: 'bg-purpleLighter', textStyle: 'text-purple' };
    }
    case 'INSURER': {
      return { backgroundStyle: 'bg-corifyGreenLighter', textStyle: 'text-corifyDarkGreen' };
    }
    case 'CORIFY_ADMIN':
    case 'MODEL_MANAGER':
    case 'MODEL_VALIDATOR':
    case 'WITHOUT_CLIENT_SELECTED':
    case 'WITHOUT_CLIENT_AVAILABLE': {
      return { backgroundStyle: 'bg-darkPurple', textStyle: 'text-white' };
    }
    case 'CUSTOMER': {
      return { backgroundStyle: 'bg-corifyRedLighter', textStyle: 'text-corifyRed' };
    }
  }
};

const getColorsByInvitationStatus = (status: UserStatus, clientType: ClientType): AvatarColors => {
  const { backgroundStyle: clientTypeBackgroundStyle, textStyle: clientTypeTextStyle } = getColorsByType(clientType);

  const textStyle = ['DEACTIVATED', 'EXPIRED', 'PENDING', 'REVOKED'].includes(status)
    ? 'text-white'
    : clientTypeTextStyle;
  const backgroundStyle = ['DEACTIVATED', 'EXPIRED', 'PENDING', 'REVOKED'].includes(status)
    ? 'bg-grey'
    : clientTypeBackgroundStyle;

  return { backgroundStyle, textStyle };
};
