import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { logRequestException } from '@corify/logging/log';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { NotificationId } from '../types';

type Props = {
  endpoint: string;
  payload: { vehicleNotificationIds: NotificationId[] };
  resetView?: () => void;
};
export const useTriggerAction = ({ endpoint, payload, resetView }: Props) => {
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const { t } = useTranslation();

  const { trigger } = useSWRMutation(
    apiUrl(
      addUrlParameters(endpoint, {
        brokerId,
      })
    ),
    swrApiFetcher()
  );

  const onTriggerSuccess = () => {
    resetView?.();

    if (endpoint === endpoints.cfm.notifications.triggerUnPause) {
      toast(t('fleetManagement.stopPausingSuccessfully', { count: payload?.vehicleNotificationIds?.length }), {
        type: 'success',
      });
    }
  };

  const onTriggerError = (error: Error) => {
    toast(t('fleetManagement.errorWhileUpdating'), { type: 'error' });

    logRequestException(
      endpoint,
      apiUrl(
        addUrlParameters(endpoint, {
          brokerId,
        })
      ),
      error
    );
  };

  const handleAction = async () => {
    try {
      await trigger({ payload });
      onTriggerSuccess();
    } catch (error: unknown) {
      onTriggerError(error as Error);
    }
  };

  return { handleAction };
};
