import { format } from 'date-fns';

type DateFormatOptions = { language: string };

export const formatDate = (date: string, { language }: DateFormatOptions = { language: 'de' }) => {
  const dateFormat = language === 'en' ? 'dd/MM/yyyy' : 'dd.MM.yyyy';

  let dateInstance = new Date(date);
  if (!date.match(/z/i)) {
    dateInstance = new Date(dateInstance.getUTCFullYear(), dateInstance.getUTCMonth(), dateInstance.getUTCDate());
  }

  return format(dateInstance, dateFormat);
};

export const getDateISO = (date: Date): string => {
  // usecase: we need to convert UTC ISO short date string into local Date and then convert it back to actual UTC ISO Date/Time string
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
};
