import { useCallback, useRef, useState, useTransition } from 'react';
import { useEventListener, useLocalStorage } from 'usehooks-ts';

import { idNormalizer } from '../json-form/helpers';

type UseHelpModeConfig = {
  triggerSelectors?: TooltipTriggerSelector[];
  containerElement?: HTMLElement | null;
};

export type TooltipTriggerSelector = {
  isId?: boolean;
  value: string;
  skipContainerCheck?: boolean;
};

const elementMatchesSelectors = (
  element: Element | null,
  selectors: TooltipTriggerSelector[],
  containerElement: HTMLElement
) =>
  element
    ? selectors.some(({ isId, value, skipContainerCheck = false }) => {
        const isContained = skipContainerCheck ? true : containerElement.contains(element);
        let isMatched = true;
        // we cannot use element.matches() because we're setting invalid values for the id atttribute
        if (isId) {
          isMatched = idNormalizer(element.id).includes(idNormalizer(value));
        } else {
          isMatched = element.matches(value);
        }
        return isContained && isMatched;
      }, true)
    : false;

export const useHelpMode = ({ triggerSelectors = [], containerElement }: UseHelpModeConfig = {}) => {
  const [isHelpModeActive, setIsHelpModeActive] = useLocalStorage('help-mode', false);
  const [shouldTriggerTooltip, setShouldTriggerTooltip] = useState<boolean | undefined>(false);
  const [, startTransition] = useTransition();
  const documentRef = useRef(document);

  const toggleHelpMode = () => {
    setIsHelpModeActive(isActive => !isActive);
  };

  const setHelpMode = (isActive: boolean) => {
    setIsHelpModeActive(isActive);
  };

  const checkActiveElement = useCallback(() => {
    if (isHelpModeActive && containerElement) {
      startTransition(() => {
        if (triggerSelectors.length) {
          setShouldTriggerTooltip(elementMatchesSelectors(document.activeElement, triggerSelectors, containerElement));
        } else {
          setShouldTriggerTooltip(containerElement.contains(document.activeElement));
        }
      });
    }
  }, [isHelpModeActive, containerElement, triggerSelectors]);

  useEventListener('focusin', checkActiveElement, documentRef, true);

  return {
    isHelpModeActive,
    toggleHelpMode,
    setHelpMode,
    shouldTriggerTooltip,
  };
};
