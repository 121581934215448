import { Button } from '@corify/components/button/button';
import { cn } from '@corify/helpers/cn';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  isActive?: boolean;
  className?: string;
  name?: string;
}

export const PaginationButton = ({ isDisabled, children, onClick, className, isActive, name }: Props) => (
  <Button
    aria-label={name}
    className={cn(
      'body2 h-[32px] w-[32px] rounded-full border p-0 text-center',
      {
        'bg-purple text-white': isActive,
        'text-purple': !isActive,
        'border-lightGrey text-lightGrey': isDisabled,
      },
      className
    )}
    onClick={onClick}
    isDisabled={isDisabled}
    size="small"
  >
    {children}
  </Button>
);
