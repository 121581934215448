import { Search } from '@corify/components/inputs/search/search';
import { DropdownItem } from '@corify/components/inputs/search/types';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyedMutator } from 'swr';

import { GDVNotification, GDVNotificationsImportedResponse, GDVNotificationTransaction } from '../../types';
import { RiskIndexPatch, useNotificationPatch } from '../use-notification-patch';
import {
  applyProbabilitiesToSortedVehicleRiskDropDownItems,
  getGroupNameByIndex,
  getSortedVehicleRiskDropdownItems,
  vehicleRiskInformations,
} from './helpers';

type Props = {
  vehicleNotificationId: GDVNotification['vehicleNotificationId'];
  vehicleType: GDVNotification['vehicleType'];
  vehicleRiskIndex: GDVNotification['vehicleRiskIndex'];
  notificationTransactionType: GDVNotification['notificationTransactionType'];
  readonly?: boolean;
  mutate?: KeyedMutator<GDVNotificationsImportedResponse> | undefined;
  cfmV2?: boolean;
  customBgOnSelected?: string;
  vehicleRiskIndexProbabilities: GDVNotification['vehicleRiskIndexProbabilities'];
};

export const RiskIndexSelect = ({
  vehicleNotificationId,
  vehicleType,
  vehicleRiskIndex,
  notificationTransactionType,
  readonly,
  mutate,
  cfmV2,
  customBgOnSelected,
  vehicleRiskIndexProbabilities = [],
}: Props) => {
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const [error, setError] = useState<string | undefined>(undefined);
  const { patchNotification } = useNotificationPatch<RiskIndexPatch>(
    vehicleNotificationId,
    brokerId,
    'vehicleRiskIndex'
  );

  const sortedRiskDropDownItems = useMemo(
    () =>
      getSortedVehicleRiskDropdownItems(vehicleType, vehicleRiskInformations, t('fleetManagement.suggestionRiskIndex')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicleType]
  );

  const sortedRiskDropDownItemsWithProbabilities = useMemo(
    () => applyProbabilitiesToSortedVehicleRiskDropDownItems(sortedRiskDropDownItems, vehicleRiskIndexProbabilities),
    [sortedRiskDropDownItems, vehicleRiskIndexProbabilities]
  );

  const selectedRiskIndexItem = useMemo(() => {
    const existingItem = sortedRiskDropDownItemsWithProbabilities.find(
      item => item.value === vehicleRiskIndex?.toString()
    );
    if (!existingItem && vehicleRiskIndex != null) {
      const customValue = vehicleRiskIndex.toString().padStart(3, '0');
      return { label: customValue, value: vehicleRiskIndex.toString() };
    }
    return existingItem;
  }, [sortedRiskDropDownItemsWithProbabilities, vehicleRiskIndex]);

  const tooltip = useMemo(
    () =>
      selectedRiskIndexItem && (
        <>
          <p className="pb-2 font-bold">{selectedRiskIndexItem?.label}</p>
          {getGroupNameByIndex(selectedRiskIndexItem.value)}
        </>
      ),
    [selectedRiskIndexItem]
  );

  const handleChange = async (selectedItem?: DropdownItem | null) => {
    const value = selectedItem?.value.padStart(3, '0');
    const isValid = /^[0-9]{3}$/.test(value ?? '');

    setError(!isValid ? t('fleetManagement.errorRiskIndexPattern') : undefined);

    await patchNotification({ vehicleRiskIndex: selectedItem && isValid ? parseInt(selectedItem.value) : null });
    await mutate?.();
  };

  const isReadonly =
    readonly || (!!vehicleRiskIndex && notificationTransactionType !== GDVNotificationTransaction.NewRegistration);

  const additionalStyle = () => {
    if (!cfmV2 || (!selectedRiskIndexItem && !isReadonly)) {
      return;
    }

    if (customBgOnSelected) {
      return customBgOnSelected;
    }

    if (isReadonly) {
      return 'bg-white';
    }
  };

  return (
    <Search
      testId="select-risk-index"
      error={error}
      clearable={cfmV2 ? !isReadonly : true}
      label="Risk Index Select"
      shouldHideLabel={true}
      shouldHideError={cfmV2}
      items={sortedRiskDropDownItemsWithProbabilities}
      selectedItem={selectedRiskIndexItem}
      isRequired={true}
      placeholder={isReadonly ? ' ' : t('components.dropdown.emptyOption')}
      onChange={handleChange}
      inputTooltip={tooltip}
      showExpandIcon={cfmV2 ? !isReadonly : true}
      adornmentLeft={null}
      matchAlgorithm="includesAnyWord"
      isReadonly={isReadonly}
      highlightSearchTerm
      classNames={{ input: additionalStyle() }}
      allowCustomValues
      hideDefaultBorder={cfmV2}
    />
  );
};
