import { RiskIndexItem } from './types';

export const riskIndexList: RiskIndexItem[] = [
  { value: 1, label: '001 - Kleinkrafträder' },
  { value: 3, label: '003 - Krafträder, Kraftroller' },
  { value: 4, label: '004 - Elektronische Mobilitätshilfe (z. B. Segway)' },
  { value: 5, label: '005 - Fahrzeuge mit Versicherungskennz.' },
  { value: 6, label: '006 - Krankenfahrstühle' },
  { value: 7, label: '007 - Leichtmofa' },
  { value: 8, label: '008 - Leichtkraftfahrzeuge' },
  { value: 9, label: '009 - Pedelecs' },
  { value: 10, label: '010 - Elektrokleinstfahrzeuge' },
  { value: 11, label: '011 - Leichtes Quad' },
  { value: 12, label: '012 - Leichtkraftroller bis 80 ccm' },
  { value: 14, label: '014 - Leichtkraftroller gem. WKZ 024, die eindeutig als LK-Roller zu identifizieren sind' },
  {
    value: 16,
    label: '016 - Leichtkraftroller, Höchstgeschwindigkeit bis zu 80 km/h, Führerscheinerlaubnis ab 16 Jahre',
  },
  { value: 18, label: '018 - Leichtkraftroller, Führerscheinerlaubnis ab 18 Jahre' },
  { value: 22, label: '022 - Leichtkrafträder bis 80 ccm' },
  {
    value: 24,
    label: '024 - Leichtkraftroller gem. §2 Nr. 10 FZV (Hubraum mehr als 50 ccm bis 125ccm, Nennleistung <= 11 kW',
  },
  {
    value: 26,
    label: '026 - Leichtkrafträder, Höchstgeschwindigkeit bis zu 80 km/h, Führerscheinerlaubnis ab 16 Jahre',
  },
  { value: 28, label: '028 - Leichtkrafträder, Führerscheinerlaubnis ab 18 Jahre' },
  { value: 30, label: '030 - Trikes' },
  { value: 31, label: '031 - Quads / 4-rädriges leichtes Kfz bzw.Quad-ähnliches Kfz' },
  { value: 72, label: '072 - Miet-Motorräder für Selbstfahrer' },
  { value: 112, label: '112 - Personenkraftwagen' },
  { value: 127, label: '127 - Campingkraftfahrzeuge, Wohnmobile' },
  { value: 140, label: '140 - Miet-PKW mit Fahrer' },
  { value: 141, label: '141 - Personenmietwagen in Standorten bis 50.000 Einwohner (auslaufend)' },
  { value: 142, label: '142 - Personenmietwagen in Standorten über 50.000 Einwohner (auslaufend)' },
  { value: 150, label: '150 - Kraftdroschken (außerhalb Berlins)' },
  { value: 152, label: '152 - Taxi (Selbstfahrervermiet)' },
  { value: 159, label: '159 - Kraftdroschken in Berlin (auslaufend)' },
  { value: 162, label: '162 - Miet-PKW für Selbstfahrer' },
  { value: 172, label: '172 - Miet-Campingfahrzeuge und -Wohnmobile für Selbstfahrer' },
  { value: 601, label: '601 - Kraftomnibusse (auslaufend)' },
  { value: 602, label: '602 - Hotelomnibusse (auslaufend)' },
  { value: 603, label: '603 - Werkomnibusse (auslaufend)' },
  { value: 604, label: '604 - Lehromnibusse (auslaufend)' },
  { value: 621, label: '621 - Sonstige Busse (Hotel-, Lehr-, Werk-, Krankentransportbusse)' },
  { value: 622, label: '622 - Hotelomnibusse mit zweiteiliger Frontscheibe (auslaufend)' },
  { value: 631, label: '631 - Werkomnibusse mit einteiliger Frontscheibe (auslaufend)' },
  { value: 632, label: '632 - Werkomnibusse mit zweiteiliger Frontscheibe (auslaufend)' },
  { value: 641, label: '641 - Lehromnibusse mit einteiliger Frontscheibe (auslaufend)' },
  { value: 642, label: '642 - Lehromnibusse mit zweiteiliger Frontscheibe (auslaufend)' },
  { value: 651, label: '651 - Busse im Linienverkehr' },
  { value: 652, label: '652 - Kraftomnibus im Linienverkehr mit zweiteiliger Frontscheibe (auslaufend)' },
  { value: 661, label: '661 - Busse im Gelegenheitsverkehr' },
  { value: 662, label: '662 - Kraftomnibus im Gelegenheitsverkehr mit zweiteiliger Frontscheibe (auslaufend)' },
  { value: 202, label: '202 - Lkw bis 1 t Nutzlast (Lieferwagen) (auslaufend)' },
  { value: 204, label: '204 - Lkw zur Beförderung von Treibstoff (auslaufend)' },
  {
    value: 251,
    label:
      '251 - Lastkraftwagen mit zulässiger Gesamtmasse bis 3,5t (Lieferwagen nach neuer Definition) im Werkverkehr',
  },
  {
    value: 261,
    label:
      '261 - Lastkraftwagen mit zulässiger Gesamtmasse bis 3,5t (Lieferwagen nach neuer Definition) im gewerblichen Güterverkehr',
  },
  { value: 262, label: '262 - Selbstfahrervermietlieferwagen (auslaufend)' },
  { value: 272, label: '272 - Miet-Lkw für Selbstfahrer' },
  { value: 301, label: '301 - Lkw über 1 t Nutzlast Werknahverkehr (auslaufend)' },
  { value: 302, label: '302 - Lkw Werkfernverkehr (auslaufend)' },
  { value: 303, label: '303 - Lkw Werknahverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 304, label: '304 - Lkw Werkfernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 309, label: '309 - Lehr-Lkw (auslaufend)' },
  { value: 311, label: '311 - Lkw Nahverkehr (auslaufend)' },
  { value: 313, label: '313 - Lkw Nahverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 314, label: '314 - Lkw Nahverkehr mit gelegentlichem Einsatz im Fernverkehr (auslaufend)' },
  {
    value: 316,
    label: '316 - Lkw Nahverkehr mit gelegentlichem Einsatz im Fernverkehr zur Beförderung von Treibstoff (auslaufend)',
  },
  { value: 322, label: '322 - Lkw Fernverkehr (auslaufend)' },
  { value: 324, label: '324 - Lkw Fernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 332, label: '332 - Lkw Umzugsverkehr (auslaufend)' },
  { value: 351, label: '351 - Lastkraftwagen mit zulässiger Gesamtmasse über 3,5t im Werkverkehr' },
  { value: 359, label: '359 - Lehrlastkraftwagen mit zulässiger Gesamtmasse über 3,5t' },
  { value: 361, label: '361 - Lastkraftwagen mit zulässiger Gesamtmasse über 3,5t im gewerblichen Güterverkehr' },
  { value: 362, label: '362 - Selbstfahrervermietlastkraftwagen (auslaufend)' },
  { value: 372, label: '372 - Miet-Lkw für Selbstfahrer' },
  { value: 382, label: '382 - Lastkraftwagen mit zulässiger Gesamtmasse über 3,5t im Umzugsverkehr' },
  { value: 401, label: '401 - Zugmaschinen im Werkverkehr' },
  { value: 402, label: '402 - Zugmaschinen Werkfernverkehr (auslaufend)' },
  { value: 403, label: '403 - Zugmaschinen Werknahverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 404, label: '404 - Zugmaschinen Werkfernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 411, label: '411 - Zugmaschinen im gewerblichen Güterverkehr' },
  { value: 413, label: '413 - Zugmaschinen Nahverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 414, label: '414 - Zugmaschine Nahverkehr mit gelegentlichem Einsatz im Fernverkehr (auslaufend)' },
  {
    value: 416,
    label:
      '416 - Zugmaschine Nahverkehr mit gelegentlichem Einsatz im Fernverkehr zur Beförderung von Treibstoff (auslaufend)',
  },
  { value: 422, label: '422 - Zugmaschinen Fernverkehr (auslaufend)' },
  { value: 424, label: '424 - Zugmaschinen Fernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 432, label: '432 - Zugmaschinen Umzugsverkehr (auslaufend)' },
  { value: 451, label: '451 - Landwirtschaftliche Zugmaschinen und Raupenschlepper' },
  { value: 459, label: '459 - Zugmaschinen Schaustellerbetriebe' },
  { value: 472, label: '472 - Selbstfahrervermiet Zug-,Sattelzugmasch.,Raupenschlepper (GGNV)' },
  { value: 501, label: '501 - Anhänger Werknah- und Privatverkehr (auslaufend)' },
  { value: 502, label: '502 - Anhänger Werkfernverkehr (auslaufend)' },
  { value: 503, label: '503 - Anhänger Werknah- und Privatverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 504, label: '504 - Anhänger Werkfernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 511, label: '511 - Anhänger Nahverkehr (auslaufend)' },
  { value: 513, label: '513 - Anhänger Nahverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 514, label: '514 - Anhänger Nahverkehr mit gelegentlichem Einsatz im Fernverkehr (auslaufend)' },
  {
    value: 516,
    label:
      '516 - Anhänger Nahverkehr mit gelegentlichem Einsatz im Fernverkehr zur Beförderung von Treibstoff (auslaufend)',
  },
  { value: 522, label: '522 - Anhänger Fernverkehr (auslaufend)' },
  { value: 524, label: '524 - Anhänger im Fernverkehr zur Beförderung von Treibstoff (auslaufend)' },
  { value: 532, label: '532 - Anhänger Umzugsverkehr' },
  { value: 541, label: '541 - Wohnwagenanhänger' },
  { value: 542, label: '542 - Anhänger in Sonderausführung' },
  { value: 551, label: '551 - Anhänger zu landwirtschirtschaftlichen Zugmaschinen' },
  { value: 561, label: '561 - Anhänger des DRK und ähnlicher Organisationen' },
  { value: 571, label: '571 - Wechselaufbauten' },
  { value: 572, label: '572 - Selbstfahrervermiet-Anhänger' },
  { value: 581, label: '581 - Anhänger im Werk- und Privatverkehr' },
  { value: 591, label: '591 - Anhänger im gewerblichen Güterverkehr' },
  { value: 702, label: '702 - Arbeitsmaschine' },
  {
    value: 703,
    label:
      '703 - Fahrbare Küchen-, Feuerwehrmannschafts- und Gerätewagen, Polizeimannschaftswagen, Mannschaftswagen des DRK, des ASB oder ähnlicher Organisationen',
  },
  {
    value: 704,
    label:
      '704 - Melkwagen, Milchsammeltankwagen (nicht Milchtankwagen), landwirtschaftliche Sonderfahrzeuge (Milchtankwagen sind als Güterfahrzeuge entsprechend ihrer Verwendung einzustufen -WKZ 301, 311 und 332-) (auslaufend)',
  },
  {
    value: 705,
    label:
      '705 - Straßenreinigungs- und Sprengwagen, Müll- und Fäkalienabfuhrwagen sowie Schneepflüge, auch wenn diese als selbstfahrende Arbeitsmaschinen anerkannt sind gemäß Dienstanweisung 13, 49, 75 oder 88',
  },
  { value: 706, label: '706 - Krankenomnibusse (auslaufend)' },
  { value: 707, label: '707 - Krankenwagen' },
  { value: 708, label: '708 - Hub- und Gabelstapler' },
  { value: 709, label: '709 - Leichenwagen' },
  { value: 710, label: '710 - Autoschütter (auslaufend)' },
  { value: 711, label: '711 - Mannschaftswagen der Polizei und des DRK (auslaufend)' },
  { value: 712, label: '712 - Auto-/Mobilkräne inkl. Bergungsfahrzeuge gemäß Dienstanweisung 50, 53 oder 64' },
  { value: 713, label: '713 - Betonpumpen, Betonmischmaschinen gemäß Dienstanweisung 3 oder 36' },
  { value: 715, label: '715 - Selbstfahrervermietfahrzeuge (mit Ausnahme der WKZ 162, 262, 362) (auslaufend)' },
  {
    value: 716,
    label: '716 - Kommunale Straßen-Reinigungs- und Sprengwagen, Müll- und Fäkalienabfuhrwagen (auslaufend)',
  },
  { value: 719, label: '719 - Wagnisse, die nicht an anderer Stelle genannt sind' },
  { value: 722, label: '722 - Elektro-Fahrzeuge einschl. Elektroschlepper (auslaufend)' },
  { value: 749, label: '749 - Hersteller-Wagnisse (auslaufend)' },
  { value: 750, label: '750 - Ständige rote Kennzeichen für Krafträder (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 751, label: '751 - Ständige rote Kennzeichen für Anhänger (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 752, label: '752 - Ständige rote Kennzeichen für sonstige Kfz (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 753, label: '753 - Nichtständige rote Kennzeichen (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 755, label: '755 - Zusatzbeiträge für Änderung der Leistungsgrenze (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 756, label: '756 - Grundbeitrag (auslaufend) Kfz.-Handel und -Handwerk' },
  {
    value: 757,
    label: '757 - Zusatzbeiträge für fremde Fahrzeuge in Werkstattobhut (auslaufend) Kfz.-Handel und -Handwerk',
  },
  {
    value: 758,
    label:
      '758 - Zusatzbeiträge für eigene und fremde Fahrzeuge, die zulassungspflichtig, aber nicht zugelassen sind (auslaufend) Kfz.-Handel und -Handwerk',
  },
  { value: 759, label: '759 - Zusatzbeiträge für Überführung von Kfz (auslaufend) Kfz.-Handel und -Handwerk' },
  { value: 760, label: '760 - Behind. mit Kfz.-Steuerbefr. (WKZ 005)' },
  { value: 761, label: '761 - Behind. mit Kfz.-Steuerbefr. (WKZ 001)' },
  { value: 762, label: '762 - Behind. mit Kfz.-Steuerbefr. (WKZ 112)' },
  { value: 763, label: '763 - Behind. mit Kfz.-Steuerbefr. (WKZ 022)' },
  { value: 764, label: '764 - Behind. mit Kfz.-Steuerbefr. (WKZ 127)' },
  { value: 765, label: '765 - Behind. mit Kfz.-Steuerbefr. (WKZ 003)' },
  { value: 767, label: '767 - Behind. mit Kfz.-Steuerbefr. (WKZ 202)' },
  { value: 769, label: '769 - Behind. mit Kfz.-Steuerbefr. (WKZ 719)' },
  { value: 770, label: '770 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 005)' },
  { value: 771, label: '771 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 001)' },
  { value: 772, label: '772 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 112)' },
  { value: 773, label: '773 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 022)' },
  { value: 774, label: '774 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 127)' },
  { value: 775, label: '775 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 003)' },
  { value: 777, label: '777 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 202)' },
  { value: 779, label: '779 - Behind. 50 o/o Kfz.-Steuererm. (WKZ 719)' },
  { value: 805, label: '805 - Sonder-Kfz-Werkstattwagen' },
];
