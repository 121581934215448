// import './sidebar.css';

import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Button, Dialog, DialogTrigger, Heading, Modal, ModalOverlay } from 'react-aria-components';

import { Icon } from '../icon/icon';

type Props = PropsWithChildren & {
  toggle: ReactNode;
  title?: string;
  toggleProps?: ComponentProps<typeof Popover.Button>;
  containerClassNames?: string;
};

export const Sidebar = ({ toggle, title, containerClassNames, children }: Props) => {
  return (
    <DialogTrigger>
      <Button className={clsx('focus:outline-none focus-visible:outline-inherit', containerClassNames)}>
        {toggle}
      </Button>
      <ModalOverlay className="fixed z-[70]" isDismissable>
        <Modal className="fixed right-0 top-0 h-screen max-w-[95%] border-r border-gray-200 bg-white shadow-lg data-[entering]:animate-[slideInLeft_250ms] data-[exiting]:animate-[slideInLeft_250ms_reverse]">
          <Dialog>
            {({ close }) => (
              <>
                <Heading slot="title">
                  <div className="flex items-center justify-between gap-4 border-b border-lightGrey px-4 py-6">
                    <h3>{title}</h3>
                    <Button onPress={close}>
                      <Icon name="close" className="h-5 w-5 fill-purple" aria-label="sidebar-close" />
                    </Button>
                  </div>
                </Heading>
                <div className="px-4 py-6">{children}</div>
              </>
            )}
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
