import { ComponentProps } from 'react';

import { CorifyTableProvider } from './corify-table-provider';

export const withTableProvider =
  (Component: React.ComponentType, props: ComponentProps<typeof CorifyTableProvider>) =>
  // eslint-disable-next-line react/display-name
  (componentProps: ComponentProps<typeof Component>) => (
    <CorifyTableProvider {...props}>
      <Component {...componentProps} />
    </CorifyTableProvider>
  );
