import clsx from 'clsx';

import { Icon } from '../icon/icon';

interface Props {
  className?: string;
  color?: 'white' | 'purple';
}

export const Spinner = ({ className, color = 'purple' }: Props) => {
  return <Icon name={`progress_${color}`} className={clsx('h-5 w-5 animate-spin', className)} aria-label="loading" />;
};
