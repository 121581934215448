import { Search } from '@corify/components/inputs/search/search';
import { DropdownItem } from '@corify/components/inputs/search/types';
import { Loader } from '@corify/components/loader/loader';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyedMutator } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { GDVNotification, GDVNotificationsImportedResponse, GDVNotificationTransaction } from '../../types';
import { EnterprisePatch, useNotificationPatch } from '../use-notification-patch';
import { EnterpriseSelectOption } from './enterprise-select-option';
import {
  checkEnterpriseItem,
  EnterpriseSearchItem,
  enterpriseUniqueFilterFn,
  getMappedSearchItems,
  mapEnterpriseToDropdownItem,
  mapSimpleEnterpriseToGDVEnterprise,
} from './enterprise-select-utils';
import { NotificationEnterprisesResponse } from './types';

type Props = {
  readonly?: boolean;
  mutate?: KeyedMutator<GDVNotificationsImportedResponse>;
  selectedEnterprise: GDVNotification['selectedEnterprise'];
  vehicleNotificationId: GDVNotification['vehicleNotificationId'];
  notificationTransactionType: GDVNotification['notificationTransactionType'];
  cfmV2?: boolean;
  customBgOnSelected?: string;
};

export const EnterpriseSelect = ({
  selectedEnterprise,
  vehicleNotificationId,
  notificationTransactionType,
  readonly,
  mutate,
  cfmV2,
  customBgOnSelected,
}: Props) => {
  const { t } = useTranslation();
  const groupName = `${t('fleetManagement.enterpriseSelect.labelEnterprise')} - ${t('fleetManagement.enterpriseSelect.labelCompany')}`;
  const swrApiFetcher = useSwrApiFetcher();
  const { id: brokerId } = useSelectedClient();
  const [dataFetchInitialized, setDataFetchInitialized] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { patchNotification } = useNotificationPatch<EnterprisePatch>(vehicleNotificationId, brokerId, 'enterprise');

  const selectedEnterpriseItem = useMemo(() => {
    return mapEnterpriseToDropdownItem(groupName, mapSimpleEnterpriseToGDVEnterprise(selectedEnterprise));
  }, [groupName, selectedEnterprise]);

  const {
    data = {
      availableEnterprises: [],
    },
    isLoading,
  } = useSWRImmutable<NotificationEnterprisesResponse>(
    dataFetchInitialized
      ? apiUrl(
          addUrlParameters(endpoints.cfm.notifications.availableEnterprises, {
            brokerId,
          })
        )
      : null,
    swrApiFetcher('GET')
  );

  const items = useMemo(
    () => getMappedSearchItems(data.availableEnterprises || [], groupName),
    [data.availableEnterprises, groupName]
  );

  const handleChange = async (selectedItem?: DropdownItem<EnterpriseSearchItem['value']> | null) => {
    if (selectedItem?.value === selectedEnterpriseItem?.value) {
      return;
    }

    setError(selectedItem ? undefined : t('fleetManagement.enterpriseSelect.required'));

    await patchNotification({
      assignedEnterpriseId: selectedItem?.value ? selectedItem.value : null,
    });

    mutate && (await mutate());
  };

  const handleMenuOpen = () => setDataFetchInitialized(true);

  const handleBlur = () => {
    if (!selectedEnterpriseItem) {
      setError(t('fleetManagement.enterpriseSelect.required'));
    }
  };

  const isReadonly = readonly || notificationTransactionType !== GDVNotificationTransaction.NewRegistration;

  const additionalStyle = () => {
    if (!cfmV2 || error || (!selectedEnterpriseItem && !isReadonly)) {
      return;
    }

    if (customBgOnSelected) {
      return customBgOnSelected;
    }

    if (isReadonly) {
      return 'bg-white';
    }
  };

  return (
    <Search
      testId="select-enterprise"
      label="Enterprise Select"
      placeholder={isReadonly ? ' ' : t('fleetManagement.enterpriseSelect.placeholder')}
      inputTooltip={selectedEnterprise?.enterpriseName}
      items={items}
      selectedItem={selectedEnterpriseItem}
      isReadonly={isReadonly}
      isRequired
      clearable={!isReadonly}
      onMenuOpen={handleMenuOpen}
      showExpandIcon={!cfmV2}
      shouldHideLabel
      shouldHideError={cfmV2}
      onChange={handleChange}
      noItemMessage={(isLoading && <Loader />) || undefined}
      customRenderOption={EnterpriseSelectOption}
      matchAlgorithm={checkEnterpriseItem}
      uniqueFilterFn={enterpriseUniqueFilterFn}
      error={error}
      onBlur={handleBlur}
      adornmentLeft={cfmV2 && isReadonly ? null : 'search'}
      classNames={{ input: additionalStyle() }}
      hideDefaultBorder={cfmV2}
    />
  );
};
