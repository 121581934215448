import { DropdownItem } from '@corify/components/inputs/dropdown/dropdown';
import { CorifyTableProvider } from '@corify/components/table/provider/corify-table-provider';
import { ComponentProps } from 'react';

import { NotificationColumn, SortingColumns } from '../types';

export const REASONS = [
  'alreadyProcessed',
  'inClarification',
  'notAssigned',
  'enterpriseNotAvailable',
  'fleetNotAvailable',
  'other',
];

export const baseColumns: NotificationColumn[] = [
  'licensePlate',
  'assignedUsers',
  'notificationTransactionType',
  'holder',
  'selectedEnterprise',
  'assignedFleet',
  'assignedCustomer',
  'vehicleRiskIndex',
];

export const notificationPageOptions: DropdownItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];

export const MAX_NUMBER_OF_AVATARS = 5;

export const DEFAULT_PAGE_SIZE: number = parseInt(notificationPageOptions[1].value!);
export const DEFAULT_SORT: SortingColumns = 'selectedEnterprise';

export const DEFAULT_NOTIFICATION_TABLE_PROPS: ComponentProps<typeof CorifyTableProvider> = {
  defaultSorting: {
    sort: DEFAULT_SORT,
    sortDirection: 'ASCENDING',
  },
  defaultFiltering: {
    assignedUsers: [],
    licensePlate: '',
    holder: '',
    enterpriseName: '',
    assignedFleet: '',
    assignedCustomer: '',
    editorName: [],
    notificationTransactionType: [],
    pauseReason: [],
    riskObjectVersionState: [],
    dateFilter: { from: '', to: '' },
  },
  defaultPagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 0,
  },
};
