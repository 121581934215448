import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { endpoints } from '@corify/constants/endpoints';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { logRequestException } from '@corify/logging/log';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

export type RiskIndexPatch = {
  vehicleRiskIndex: number | null;
};
export type EnterprisePatch = {
  assignedEnterpriseId: string | null;
};

export type AssignedFleetPatch = {
  riskObjectId: string | null;
  businessVersion: number | null;
};

export type AssignedCustomerPatch = {
  assignedCustomerId: string | null;
};

export type ApproveAutomaticallyPatch = {
  approveAutomatically: boolean;
};

export const useNotificationPatch = <T>(
  notificationId: string,
  brokerId: string,
  endpointName: 'enterprise' | 'assignedFleet' | 'vehicleRiskIndex' | 'assignedCustomer' | 'approveAutomatically'
) => {
  const swrApiFetcher = useSwrApiFetcher();
  const { t } = useTranslation();

  const { trigger, isMutating: isNotificationPatching } = useSWRMutation(
    apiUrl(addUrlParameters(endpoints.cfm.notifications[endpointName], { notificationId, brokerId })),
    swrApiFetcher<T>('PATCH')
  );

  const patchNotification = async (data: T) => {
    try {
      await trigger({ payload: data as object });
    } catch (error) {
      toast(t('fleetManagement.errorWhileUpdating'), { type: 'error' });

      logRequestException(
        endpoints.cfm.notifications[endpointName],
        apiUrl(addUrlParameters(endpoints.cfm.notifications[endpointName], { notificationId, brokerId })),
        error as Error
      );
    }
  };

  return { patchNotification, isNotificationPatching };
};
