export type ErrorType = 'NOT_FOUND' | 'SESSION_EXPIRED' | 'UNEXPECTED' | 'UNAVAILABLE' | 'TIMEOUT';

export class StatusError extends Error {
  message: ErrorType;

  constructor(error: ErrorType) {
    super(error);
    this.message = error;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, StatusError.prototype);
  }
}
