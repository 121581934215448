import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { RISK_CONTRIBUTOR_ROUTES } from './routes';

const Modules = lazy(() => import('@corify/pages/modules/modules'));
const BrokerLeftNavigationLayout = lazy(() => import('@corify/layout/risk-contributor-layout'));
const BasicData = lazy(() => import('@corify/pages/basic-data/basic-data'));

export const getRiskContributorBaseRoutes = () => [
  <Route key="broker-risk-contributor" element={<BrokerLeftNavigationLayout />}>
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_BASIC_DATA_ROUTE} element={<BasicData />} />
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_MODULES_ROUTE} element={<Modules />} />
  </Route>,
];
