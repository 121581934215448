import { ReactElement, ReactNode } from 'react';

import { Tooltip } from '../../tooltip/tooltip';

export type AdornmentProps = {
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  inputTooltip?: ReactNode;
  children: ReactElement;
};

export const Adornments = ({ adornmentLeft, adornmentRight, inputTooltip, children }: AdornmentProps) => (
  <div className="relative flex items-center justify-center gap-4">
    {inputTooltip ? (
      <Tooltip overlay={inputTooltip} mouseEnterDelay={0.3}>
        {children}
      </Tooltip>
    ) : (
      children
    )}

    {adornmentLeft && (
      <div className="absolute inset-y-0 left-0 flex items-center pl-4 text-sm font-semibold text-darkGrey">
        {adornmentLeft}
      </div>
    )}
    {adornmentRight && (
      <div
        data-testid="adornment-right"
        className="absolute inset-y-0 right-0 flex items-center pr-4 text-sm font-semibold text-darkGrey"
      >
        {adornmentRight}
      </div>
    )}
  </div>
);
