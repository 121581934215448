import { Label } from '@corify/components/inputs/field-label/label';
import { cn } from '@corify/helpers/cn';
import { forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  checked?: boolean;
  isReadOnly?: boolean;
  label: string;
  onChange?: (checked: string) => void;
  isRequired?: boolean;
  tabIndex?: number;
  tooltip?: string;
  classes?: { root?: string; label?: string };
  adornmentRight?: ReactNode;
}

export const RadioField = forwardRef(
  (
    {
      checked = false,
      isReadOnly,
      label,
      isRequired,
      onChange = () => {},
      tabIndex,
      tooltip,
      classes,
      adornmentRight,
      id,
      ...props
    }: Props,
    ref: Ref<HTMLInputElement> | undefined
  ) => {
    return (
      <>
        <Label
          classes={{
            root: cn(
              'justify-start',
              !isReadOnly ? 'cursor-pointer' : 'cursor-default pointer-events-none',
              'focus-within:border-purple',
              classes?.root
            ),
            label: cn(!isReadOnly ? 'cursor-pointer' : 'cursor-default pointer-events-none', classes?.label),
          }}
          label={label}
          isRequired={isRequired}
          tooltip={tooltip}
          adornmentLeft={
            <input
              ref={ref}
              className={cn(
                'form-radio scroll-mt-[250px] border-2 border-purple bg-purpleLighter',
                'focus:ring-0 focus:ring-offset-0',
                'checked:bg-none checked:ring-purpleLighter checked:focus:ring-[3px] checked:focus:ring-inset',
                'h-4 w-4',
                'mr-2',
                {
                  'ring-[3px] ring-inset ring-purpleLighter': checked,
                  'pointer-events-none cursor-default border-darkGrey checked:bg-darkGrey': isReadOnly,
                  'cursor-pointer': !isReadOnly,
                }
              )}
              checked={checked}
              disabled={isReadOnly}
              id={id}
              onChange={() => {
                onChange(label);
              }}
              type="radio"
              tabIndex={tabIndex}
              data-tooltip-selector={id}
              {...props}
            />
          }
          adornmentRight={adornmentRight}
          data-component="radio"
        />
      </>
    );
  }
);

RadioField.displayName = 'RadioField';
