import { Roles, UserClient } from '@corify/types/user';

export const getSelectedClient = (data: Roles | undefined) => {
  if (!data) {
    return undefined;
  }

  const userRole = data.userRoles.find(userRole => userRole.clientId === data.selectedClientId);

  if (!userRole) {
    return undefined;
  }

  return {
    id: userRole.clientId,
    name: userRole.clientName,
    type: data.clientType!,
    userRoles: userRole.roles,
    brokerOfCustomer: data.brokerOfCustomer,
  } satisfies UserClient;
};
