import { useEffect, useState } from 'react';

export function useErrorBoundary(directError?: Error) {
  const [error, setError] = useState<Error | undefined>();

  const handleError = (error: Error) => {
    setError(error);
  };

  useEffect(() => {
    if (directError) {
      setError(directError);
    }
  }, [directError]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return handleError;
}
