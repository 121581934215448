import { Icon, IconName } from '@corify/components/icon/icon';
import { cn } from '@corify/helpers/cn';
import clsx from 'clsx';
import { ReactNode } from 'react';

export const HeaderCell = ({
  topContent,
  bottomContent,
  className,
  onTopContentClick,
  iconName,
}: {
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  className?: string;
  onTopContentClick?: (() => undefined) | ((event: unknown) => void) | undefined;
  iconName?: IconName;
}) => {
  return (
    <div className={cn('m-0 flex flex-col p-0', className)}>
      <div
        role="presentation"
        onKeyDown={onTopContentClick}
        onClick={onTopContentClick}
        className={clsx('flex h-[52px] flex-row items-center border-b px-4 py-1', {
          'hover:cursor-pointer': onTopContentClick !== undefined,
        })}
      >
        <div className="flex w-full flex-row items-center justify-between gap-1">
          <div className="font-semibold uppercase">{topContent}</div>
          {iconName && <Icon name={iconName} className="ml-auto h-[18px] w-[18px] text-purple" aria-label={iconName} />}
        </div>
      </div>
      <div className="flex h-[52px] w-full">{bottomContent}</div>
    </div>
  );
};
