export enum RISK_CONTRIBUTOR_ROUTES {
  RISK_CONTRIBUTOR_BASIC_DATA_ROUTE = '/clients/:clientId/customers/:customerId/basic-data',

  RISK_CONTRIBUTOR_CUSTOMER_ACCESS_ROUTE = '/clients/:clientId/customers/:customerId/accesses',

  RISK_CONTRIBUTOR_CUSTOMER_ACCESS_DETAIL_ROUTE = '/clients/:clientId/customers/:customerId/accesses/:userId',

  RISK_CONTRIBUTOR_CUSTOMERS_ROUTE = '/clients/:clientId/customers',
  RISK_CONTRIBUTOR_CUSTOMERS_STRUCTURE_ROUTE = '/clients/:clientId/customers/:customerId/company-structure',

  RISK_CONTRIBUTOR_MODULES_ROUTE = '/clients/:clientId/customers/:customerId/modules',

  RISK_CONTRIBUTOR_RISK_OBJECTS_ROUTE = '/clients/:clientId/customers/:customerId/:type',
  RISK_CONTRIBUTOR_RISK_OBJECTS_ITEM_ROUTE = '/clients/:clientId/customers/:customerId/:type/:riskObjectId/:riskClass',
  RISK_CONTRIBUTOR_RISK_OBJECTS_VERSIONS_ROUTE = '/clients/:clientId/customers/:customerId/:type/:riskObjectId/:riskClass/versions',
  RISK_CONTRIBUTOR_RISK_OBJECTS_VERSIONS_ITEM_ROUTE = '/clients/:clientId/customers/:customerId/:type/:riskObjectId/:riskClass/versions/:version',
}
