import { RowSelectionState, TableOptions } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

type TableSelectionProps<TData> = {
  tableSelectionProps: Pick<TableOptions<TData>, 'onRowSelectionChange' | 'getRowId'>;
  rowSelectionState: RowSelectionState;
  selectedRowIds: string[];
  resetSelectedIds: () => void;
};

export function useTableSelection<TData>(
  getRowId: TableSelectionProps<TData>['tableSelectionProps']['getRowId']
): TableSelectionProps<TData> {
  const [rowSelectionState, setRowSelection] = useState<RowSelectionState>({});

  const selectedRowIds = useMemo(
    () =>
      Object.entries(rowSelectionState ?? {})
        .filter(([, value]) => value)
        .map(([key]) => key),
    [rowSelectionState]
  );

  const resetSelectedIds = useCallback(() => setRowSelection({}), []);

  return {
    tableSelectionProps: {
      onRowSelectionChange: setRowSelection,
      getRowId,
    },
    rowSelectionState,
    selectedRowIds,
    resetSelectedIds,
  };
}
