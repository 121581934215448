import { Icon } from '@corify/components/icon/icon';
import { TextField } from '@corify/components/inputs/text-field/text-field';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const NotificationColumnFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <TextField
      label=""
      placeholder={t('components.filterField.placeholder')}
      shouldHideLabel
      hideError
      value={value}
      onChange={onChange}
      classes={{
        root: 'w-full',
        input: 'border-0 text-sm placeholder:text-darkGrey text-darkGrey font-normal',
      }}
      adornmentLeft={<Icon aria-hidden="true" className="h-4 w-4" name="search" aria-label="search license plate" />}
      adornmentRight={
        value && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <Icon
              aria-label={t('components.filterField.clearFilter') ?? ''}
              className="h-4 w-4"
              name="clear"
              onClick={() => onChange('')}
            />
          </div>
        )
      }
    />
  );
};
