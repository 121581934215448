import { Language } from '@corify/types/language';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const germanLanguageOption = { label: 'Deutsch', value: Language.DE } as const;
const englishLanguageOption = { label: 'English', value: Language.EN } as const;
const languageOptions = [germanLanguageOption, englishLanguageOption] as const;

export type LanguageOption = (typeof languageOptions)[number];
export type LegalContext = {
  language: string;
  changeLanguage: (language: Language) => void;
  languageOptions: readonly LanguageOption[];
};

export const LegalContext = createContext<LegalContext>({
  language: navigator.language.includes(Language.DE) ? germanLanguageOption.value : englishLanguageOption.value,
  changeLanguage: () => {},
  languageOptions,
});

export const LegalContextProvider = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.title = i18n.t('pages.legal.pageTitle');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <LegalContext.Provider
      value={{
        language: i18n.language,
        changeLanguage: i18n.changeLanguage,
        languageOptions,
      }}
    >
      {children}
    </LegalContext.Provider>
  );
};

export const useLegalContext = () => {
  const ctx = useContext(LegalContext);
  if (!ctx) {
    throw 'useLegalContext must be used within a LegalContextProvider';
  }

  return ctx;
};
