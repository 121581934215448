import { Tooltip } from '@corify/components/tooltip/tooltip';
import { cn } from '@corify/helpers/cn';
import { forwardRef, ReactNode, useState } from 'react';

interface Props {
  text: string;
  icon?: ReactNode;
  className?: string;
  variant?: 'default' | 'outlined';
  itemTooltip?: string | ReactNode;
}

export const Chip = forwardRef<HTMLDivElement, Props>(
  ({ text, icon, className, variant = 'default', itemTooltip, ...props }: Props, ref) => {
    const [isTextTruncated, setIsTextTruncated] = useState(false);
    const component = (
      <div
        aria-label={text}
        ref={ref}
        className={cn(
          'flex w-fit max-w-full items-center rounded-full border border-purple p-1 px-4 text-xs font-semibold',
          'focus:shadow-[0_0_0_1px] focus:shadow-purpleLight',
          icon && 'pr-2',
          variant === 'outlined' && 'text-purple',
          variant !== 'outlined' && 'bg-purpleLight text-white',
          className
        )}
        {...props}
      >
        <div
          ref={ref => {
            if (ref) {
              setIsTextTruncated(ref.offsetWidth < ref.scrollWidth);
            }
          }}
          className="cursor-default truncate"
        >
          {text}
        </div>
        {icon}
      </div>
    );

    return (
      <>
        {itemTooltip || isTextTruncated ? (
          <Tooltip overlay={itemTooltip ?? text} maxWidth="100%">
            {component}
          </Tooltip>
        ) : (
          component
        )}
      </>
    );
  }
);

Chip.displayName = 'Chip';
