import clsx from 'clsx';

import { HeaderLink } from './header-link';

interface Props {
  links: { active?: boolean; label: string; to: string }[];
  justify?: 'start' | 'center' | 'end';
}

export const HeaderLinks = ({ links, justify = 'start' }: Props) => {
  return (
    <div className={clsx('flex', `justify-${justify}`)}>
      {links.map(({ active, to, label }, id) => (
        <HeaderLink active={active} key={id} to={to}>
          {label}
        </HeaderLink>
      ))}
    </div>
  );
};
