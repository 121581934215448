import { useLegalContext } from '@corify/context/legal/legal-context';
import { Language } from '@corify/types/language';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as EnglishContent } from './english.md';
import { ReactComponent as GermanContent } from './german.md';

const PrivacyPolicy = () => {
  const { language } = useLegalContext();

  const CopyRightDate = () => new Date().getFullYear() + ' Corify GmbH';

  return (
    <>
      <Helmet>
        <title>{language === Language.DE ? 'Datenschutzhinweise' : 'Privacy Policy'} | corify</title>
      </Helmet>
      {language === Language.DE ? (
        <GermanContent Copyright={CopyRightDate} />
      ) : (
        <EnglishContent Copyright={CopyRightDate} />
      )}
    </>
  );
};

export default PrivacyPolicy;
