import { Icon, IconName } from '@corify/components/icon/icon';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { Button, DefaultButtonProps } from '../button';

interface DropDownSection {
  icon: IconName;
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}

interface Props extends DefaultButtonProps {
  className?: string;
  iconClassName?: string;
  title?: string;
  message?: string;
  dropDownSections: DropDownSection[];
}

export const DropDownButton = ({ isDisabled, title, message, dropDownSections }: Props) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const handleSectionClick = (item: DropDownSection) => {
    if (!item.isDisabled) {
      setOpen(false);
      item.onClick();
    }
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <span className="relative m-0 p-0" ref={ref}>
      <Button
        data-testid={kebabCase(`dropdown-button-${title}`)}
        variant="primary"
        isDisabled={isDisabled}
        onClick={isDisabled ? undefined : () => setOpen(!open)}
        className="relative m-0 p-0"
        size="small"
      >
        <span className="pl-4">{title}</span>
        <span className="h-[120%] w-[1px] bg-white"></span>
        <span className="pr-2">{open ? <Icon name="expand_less" /> : <Icon name="expand_more" />}</span>
      </Button>
      {open && dropDownSections && (
        <>
          <span className="absolute right-0 top-11 z-[60] flex flex-col rounded-md border-[1px] border-[#e5e6e6] bg-[#ffffff] p-2 shadow-[0_2px_4px_0px_rgb(224,225,255)]">
            {message && (
              <span className="mb-1 flex flex-row gap-2 bg-[#F6F6FF] px-2 py-3 text-xs">
                <span className="h-[10px] w-[10px]">
                  <Icon name="alert" />
                </span>
                <span className="leading-4">{message}</span>
              </span>
            )}

            {dropDownSections.map(item => (
              <span
                className={clsx('flex cursor-default flex-row items-center gap-3 px-2 py-4', {
                  'cursor-pointer hover:bg-[#F6F6FF]': !item.isDisabled,
                })}
                key={item.title}
                role="button"
                data-testid={kebabCase(`dropdown-button-${item.title}`)}
                tabIndex={0}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSectionClick(item);
                  }
                }}
                onClick={() => handleSectionClick(item)}
              >
                {item.icon && (
                  <Icon
                    name={item.icon}
                    className={clsx('h-[20px] w-[20px] text-[#5a37ff]', { 'opacity-30': item.isDisabled })}
                  />
                )}

                <span className={clsx('whitespace-nowrap text-sm text-[#5a37ff]', { 'opacity-30': item.isDisabled })}>
                  {item.title}
                </span>
              </span>
            ))}
          </span>
          <span className="absolute right-3 top-10 z-[60] h-[8px] w-[8px] rotate-45 border-l-[1px] border-t-[1px] border-[#e5e6e6] bg-white" />
        </>
      )}
    </span>
  );
};
