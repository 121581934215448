import { Label } from '@corify/components/inputs/field-label/label';
import { ErrorMessageContainer } from '@corify/components/validation/error/error-message-container';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { type LabelAriaProps, mergeProps, useId } from 'react-aria';
import { TextField as AriaTextField, TextFieldProps as AriaTextFieldProps } from 'react-aria-components';
import { Input } from 'react-aria-components';

import { Adornments } from './adornments';
import { getInputClassNames } from './helpers';

export interface Props extends AriaTextFieldProps {
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  classes?: {
    root?: string;
    input?: string;
  };
  errorMessage?: string;
  id?: string;
  label: string;
  labelProps?: LabelAriaProps;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  shouldHideLabel?: boolean;
  tooltip?: string;
  inputTooltip?: ReactNode;
  hideError?: boolean;
  placeholder?: string;
  compact?: boolean;
  hideDefaultBorder?: boolean;
  'data-testid'?: string;
}

export const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      adornmentLeft,
      adornmentRight,
      autoComplete = 'on',
      classes,
      errorMessage,
      hideError = false,
      label,
      shouldHideLabel = false,
      tooltip,
      inputProps: inputPropsFromProps,
      isRequired,
      isReadOnly,
      inputTooltip,
      placeholder,
      id: externalId,
      compact,
      hideDefaultBorder,
      'data-testid': dataTestId,
      ...props
    },
    ref
  ) => {
    const id = useId(externalId || inputPropsFromProps?.id);

    const mergedProps = {
      ...(dataTestId ? { 'data-testid': dataTestId } : {}),
      ...mergeProps({ placeholder, disabled: isReadOnly }, inputPropsFromProps ?? {}),
    };

    const input = (
      <Input
        data-tooltip-selector={id}
        ref={ref}
        id={id}
        className={getInputClassNames({
          errorMessage,
          isRequired,
          isReadOnly,
          inputValue: mergedProps?.value || props.value,
          adornmentLeft,
          adornmentRight,
          additionalClasses: [classes?.input, inputPropsFromProps?.className],
          compact,
          hideDefaultBorder,
        })}
        {...mergedProps}
      />
    );

    return (
      <AriaTextField
        {...props}
        className={classes?.root}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        autoComplete={autoComplete}
        validationBehavior="aria"
        // need to have aria-label to avoid aria accessibility warnings
        {...(props['aria-label'] ? {} : { 'aria-labelledby': ' ' })}
        data-component="text-field"
      >
        {!shouldHideLabel && (
          <Label
            classes={{ root: 'flex mb-1 min-h-[2rem] w-full' }}
            label={label}
            isRequired={isRequired}
            tooltip={tooltip}
            htmlFor={id}
          />
        )}
        <Adornments adornmentLeft={adornmentLeft} adornmentRight={adornmentRight} inputTooltip={inputTooltip}>
          {input}
        </Adornments>
        {!hideError && <ErrorMessageContainer error={errorMessage} />}
      </AriaTextField>
    );
  }
);

TextField.displayName = 'TextField';
