import { FC, useState } from 'react';

import { Icon } from '../icon/icon';
import { Position, Tooltip } from './tooltip';

type Params = {
  tooltip: string;
  position?: Position;
};

export const TooltipInfo: FC<Params> = ({ tooltip, position }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <Tooltip overlay={tooltip} position={position} onVisibleChange={setTooltipVisible}>
      <Icon name={tooltipVisible ? 'info_active' : 'info'} aria-label={tooltipVisible ? 'info active' : 'info'} />
    </Tooltip>
  );
};
