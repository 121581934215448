import { Button } from '@corify/components/button/button';
import { Paper } from '@corify/components/paper/paper';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { endpoints } from '@corify/constants/endpoints';
import { messages } from '@corify/constants/messages';
import { apiUrl } from '@corify/helpers/api/api';
import { logException } from '@corify/logging/log';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { UploadErrorResponse } from './types';

interface HsnTsnFilesForm {
  dictionary: File[];
}

const ModelManagerHsnTsn = () => {
  const { t } = useTranslation();
  const swrApiFetcher = useSwrApiFetcher();

  const {
    formState: { isDirty, isSubmitting },
    handleSubmit,
    register,
  } = useForm<HsnTsnFilesForm>({
    defaultValues: {
      dictionary: undefined,
    },
    mode: 'onTouched',
  });

  const { trigger } = useSWRMutation(apiUrl(endpoints.crom.hsnTsnDictionary), swrApiFetcher());

  const handleSave = async (data: HsnTsnFilesForm) => {
    const formData = new FormData();
    formData.append('hsn-tsn-dictionary', new Blob([data.dictionary[0]], { type: 'form-data' })),
      data.dictionary[0].name;

    try {
      await trigger({
        body: formData,
      });

      toast(messages.changesSuccessfullySaved, { type: 'success' });
    } catch (error: unknown) {
      const typedError = error as { body: UploadErrorResponse };

      const errorBody = typedError.body;

      if (errorBody.status === 403) {
        toast(t('errors.forbiddenWithPermissionHint', { permission: 'publish_new_dictionary' }), { type: 'error' });
      } else {
        logException(t('errors.unhandled'), { originalError: errorBody });
        toast(t('errors.saveError'), { type: 'error' });
      }
    }
  };

  return (
    <div className="grid min-h-full grid-rows-[auto_1fr]">
      <h2 className="mb-8 mr-5">Model Manager</h2>

      <div className="flex flex-col pb-6">
        <div className="flex justify-between">
          <h3 className="py-6">HSN/TSN upload</h3>
        </div>

        <Paper>
          <form data-testid="form" onSubmit={handleSubmit(handleSave)}>
            <div className="mb-8 flex flex-col gap-8">
              <div>
                <p>
                  HSN/TSN <span className="align-baseline text-corifyRed">*</span>
                </p>
                <input data-testid="dictionary" {...register('dictionary')} type="file" accept="" required />
              </div>
            </div>

            <div className="flex items-center pt-6">
              <Button
                data-testid="submit"
                variant="primary"
                isDisabled={!isDirty || isSubmitting}
                loading={isSubmitting}
                size="small"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default ModelManagerHsnTsn;
