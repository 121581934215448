import { format, isValid, parse } from 'date-fns';

export const stringDateToDate = (str: string | undefined, displayFormat: string): Date | null => {
  if (!str) {
    return null;
  }

  const parsedDate = parse(str, displayFormat, new Date());

  return isValid(parsedDate) ? parsedDate : null;
};
export const dateToStringDate = (date: Date | undefined, displayFormat: string): string =>
  isValid(date) ? format(date as Date, displayFormat) : '';
