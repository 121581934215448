import { DateRangeValue } from '@corify/components/inputs/date-range/date-range';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { endpoints } from '@corify/constants/endpoints';
import { useSelectedClient } from '@corify/context/client/client-provider';
import { useUser } from '@corify/context/user/use-user';
import { apiUrl } from '@corify/helpers/api/api';
import { addUrlParameters } from '@corify/helpers/url';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { GDVNotificationFilterResponse, GDVNotificationTransaction } from '../types';
import { REASONS } from './constants';

export type NotificationFilters = {
  assignedUsers: string[];
  licensePlate: string;
  holder: string;
  enterpriseName: string;
  assignedFleet: string;
  assignedCustomer: string;
  editorName: string[];
  notificationTransactionType: string[];
  pauseReason: string[];
  riskObjectVersionState: string[];
  dateFilter: DateRangeValue;
};

export const useNotificationFiltering = () => {
  const swrApiFetcher = useSwrApiFetcher();
  const { t } = useTranslation();
  const { id: brokerId } = useSelectedClient();
  const user = useUser();

  //   ---------------------------------------------------
  //   FILTER OPTIONS
  //   ---------------------------------------------------
  const { data } = useSWRImmutable<GDVNotificationFilterResponse>(
    `${apiUrl(
      `${addUrlParameters(endpoints.dictionaries.notificationsFilter, {
        brokerId,
      })}`
    )}?dictionaryTypes=ASSIGNED_USERS,EDITORS`,
    swrApiFetcher<GDVNotificationFilterResponse>('GET')
  );

  // ASSIGNED USERS
  const assginedUsersOptions = useMemo(() => {
    const options = data?.assignedUsers ?? [];

    user?.userId &&
      options.unshift({
        userId: user.userId,
        email: '',
        firstName: `${user?.firstName} ${user?.lastName} ${t('fleetManagement.columns.userAssignedToFleet.me')}`,
        lastName: '',
      });

    options.unshift({
      userId: 'noAssignment',
      email: '',
      firstName: t('fleetManagement.columns.userAssignedToFleet.noAssignment'),
      lastName: '',
    });

    return options.map(user => {
      return {
        label: user.email,
        value: user.userId,
        customListLabel: (
          <span className="flex w-full flex-col">
            <span className="font-semibold">{`${user.firstName} ${user.lastName}`}</span>
            <span className="text-xs">{user.email}</span>
          </span>
        ),
      };
    });
  }, [data, user, t]);

  // EDITORS
  const editorOptions = useMemo(() => {
    const options = data?.editors ?? [];

    user?.userId &&
      options.unshift({
        userId: user.userId,
        name: `${user?.firstName} ${user?.lastName} ${t('fleetManagement.columns.userAssignedToFleet.me')}`,
      });

    return options.map(editor => {
      return {
        label: editor?.name,
        value: editor?.userId,
      };
    });
  }, [data]);

  // TRANSACTION TYPE
  const transactionTypeOptions = useMemo(() => {
    const options = [
      GDVNotificationTransaction.NewRegistration,
      GDVNotificationTransaction.Change,
      GDVNotificationTransaction.Deregister,
    ];

    return options.map(transactionType => {
      return {
        label: t(`fleetManagement.columns.notificationTransaction.type.${transactionType}`),
        value: transactionType,
      };
    });
  }, [t]);

  // PAUSE REASON
  const reasonForPausingOptions = useMemo(
    () =>
      REASONS.map(reason => ({ label: t(`fleetManagement.reasonsPausing.${reason}`), value: reason })).sort(
        (reason1, reason2) => reason1.label.localeCompare(reason2.label)
      ),
    [t]
  );

  // RISK OBJECT STATE
  const currentStateOfRiskObjectOptions = useMemo(
    () => [
      {
        label: t('components.status.IN_PROGRESS'),
        value: 'IN_PROGRESS',
      },
      {
        label: t('components.status.READY_FOR_APPROVAL'),
        value: 'READY_FOR_APPROVAL',
      },
      {
        label: t('components.status.APPROVED'),
        value: 'APPROVED',
      },
    ],
    [t]
  );

  return {
    assginedUsersOptions,
    editorOptions,
    transactionTypeOptions,
    reasonForPausingOptions,
    currentStateOfRiskObjectOptions,
  };
};
