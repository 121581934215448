import { CollectionApiResponse } from '@corify/helpers/api/use-collection-fetch';
import { RiskObjectStatus } from '@corify/types/risk-object';
import { User } from '@corify/types/user';

import { NotificationCustomer } from './common/customer-select/types';
import { GDVEnterpriseSimple } from './common/enterprise-select/types';
import { NotificationFleet } from './common/fleet-select/types';
import type { PauseReason } from './common/pause-reason-select';
import { VehicleType } from './common/risk-index-select/types';

export enum GDVNotificationTransaction {
  NewRegistration = 'NEW_REGISTRATION',
  Deregister = 'DEREGISTER',
  Change = 'CHANGE',
}

export type GDVNotificationRawData = {
  rawData: string;
};

export type GDVNotification = {
  vehicleNotificationId: string;
  licensePlate: string | null;
  importTime: string;
  assignedCustomer?: NotificationCustomer;
  assignedFleet?: NotificationFleet;
  assignedUsers?: Pick<User, 'userId' | 'email' | 'firstName' | 'lastName'>[];
  vehicleType?: VehicleType | null;
  vehicleRiskIndex?: number;
  approveAutomatically: boolean;
  holder: string | null;
  importStatus: 'NEW' | 'IN_PROGRESS' | 'FAILED' | 'FINISHED' | 'PAUSED' | 'FAILED_LOADING' | 'RELOADING';
  importError?:
    | 'AMBIGUOUS_VEHICLE'
    | 'AMBIGUOUS_FLEET'
    | 'ENTERPRISE_NOT_FOUND'
    | 'FLEET_NOT_FOUND'
    | 'INVALID_RAW_VEHICLE_CONTENT'
    | 'VEHICLE_NOT_PERSISTED'
    | 'INVALID_VEHICLE_RISK_OBJECT_STATE'
    | 'UNSUPPORTED_SCENARIO'
    | 'VEHICLE_ALREADY_EXISTS'
    | 'VEHICLE_NOT_FOUND'
    | 'UNKNOWN_ERROR';
  enterpriseName: string;
  pauseReason?: PauseReason;
  selectedEnterprise: GDVEnterpriseSimple | null;
  matchedEnterprises: GDVEnterpriseSimple[];
  matchedVehicles?: GDVVehicle[];
  notificationTransactionType: GDVNotificationTransaction | null;
  vehicleRiskIndexProbabilities?: { vehicleRiskIndex: number; percentage: number }[];
};

export type GDVVehicle = {
  riskObjectId: string;
  riskObjectBusinessVersion: number;
  shortDescription: string;
};

export type GDVNotificationImported = GDVNotification & {
  editor?: { userId: string; name: string; email?: string };
  editorName?: string;
  riskObjectVersionState: RiskObjectStatus;
  riskObjectId: string;
  riskObjectBusinessVersion: number;
  brokerId: string;
};

export type GDVNotificationRequestSearchParams = {
  filter?: string;
  fromDate?: string;
  toDate?: string;
};

export type GDVNotificationRequestPayload = {
  page: {
    pageSize: number;
    pageNumber: number;
  };
  sort: {
    sort: SortingColumns;
    sortDirection: 'DESCENDING' | 'ASCENDING';
  };
  filters: {
    licensePlate?: string;
    assignedUsers?: string[];
    notificationTransactionType?: string;
    holder?: string;
    enterpriseName?: string;
    assignedFleet?: string;
    assignedCustomer?: string;
    importTimeFrom?: string;
    importTimeTo?: string;
  };
};

export type GDVNotificationsResponse = CollectionApiResponse<GDVNotification>;
export type GDVNotificationsImportedResponse = CollectionApiResponse<GDVNotificationImported>;
export type GDVNotificationFilterResponse = {
  assignedUsers?: Pick<User, 'userId' | 'email' | 'firstName' | 'lastName'>[];
  editors?: { userId: string; name: string }[];
};

export type NotificationId = GDVNotification['vehicleNotificationId'];

export type NotificationColumn =
  | 'pauseReason'
  | 'importTime'
  | 'approveAutomatically'
  | 'editors'
  | 'editorName'
  | 'riskObjectVersionState'
  | 'licensePlate'
  | 'assignedUsers'
  | 'notificationTransactionType'
  | 'holder'
  | 'selectedEnterprise'
  | 'assignedFleet'
  | 'assignedCustomer'
  | 'vehicleRiskIndex';

export type SortingColumns = Extract<
  NotificationColumn,
  | 'licensePlate'
  | 'notificationTransactionType'
  | 'holder'
  | 'selectedEnterprise'
  | 'assignedUsers'
  | 'assignedFleet'
  | 'assignedCustomer'
  | 'importTime'
  | 'pauseReason'
  | 'editors'
  | 'editorName'
  | 'riskObjectVersionState'
  | 'approveAutomatically'
>;
