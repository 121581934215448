import { useCorifyTable } from '@corify/components/table/provider/use-corify-table';
import { useTableSelection } from '@corify/components/table/use-table-selection';
import {
  GDVNotification,
  GDVNotificationImported,
  GDVNotificationsImportedResponse,
  SortingColumns,
} from '@corify/pages/fleet-management/types';
import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { NotificationFilters } from './use-notification-filtering';
import { FMDataEndpoint, useNotificationsDataV2 } from './use-notifications-data-v2';

type Props = FMDataEndpoint & {
  allowReloadInterval?: boolean;
};

export const useNotificationsHeaderV2 = ({ endpoint, allowReloadInterval }: Props) => {
  const { filtering, pagination, setPagination } = useCorifyTable<SortingColumns, NotificationFilters>();

  const {
    tableSelectionProps,
    rowSelectionState,
    resetSelectedIds,
    selectedRowIds: selectedNotificationIds,
  } = useTableSelection<GDVNotificationImported>(row => row.vehicleNotificationId);
  const [interval, setInterval] = useState<number | null>(null);
  useInterval(() => {
    setInterval(null);
    mutate?.();
  }, interval);

  const [localData, setLocalData] = useState<GDVNotificationsImportedResponse>();
  const [expandedRow, setExpandedRow] = useState<{ id: GDVNotification['vehicleNotificationId']; index: number }>({
    id: '',
    index: -1,
  });

  const {
    fetchedData: { isValidating, data, error, mutate },
    notificationRawData: { data: notificationRawData, isValidating: isLoadingRawData },
  } = useNotificationsDataV2({
    notificationId: expandedRow.id,
    endpoint,
  });

  useEffect(() => {
    if (!allowReloadInterval) {
      return;
    }

    if (isValidating) {
      setInterval(null);
    } else if (data?.items?.some(item => item.importStatus === 'RELOADING')) {
      setInterval(10000);
    }
  }, [allowReloadInterval, data?.items, isValidating]);

  const resetView = async () => {
    resetPagination?.();
    await mutate?.();
    resetSelectedIds?.();
  };

  const resetPagination = () => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  };

  useEffect(() => {
    resetPagination();
    toggleAccordion();
    resetSelectedIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering]);

  useEffect(() => {
    toggleAccordion();
    resetSelectedIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex]);

  useEffect(() => {
    if (!isValidating && data && localData !== data) {
      setLocalData(data);
    }
  }, [data, isValidating, localData]);

  const toggleAccordion = (notificationId?: GDVNotification['vehicleNotificationId']) => {
    if (!notificationId) {
      return setExpandedRow({
        id: '',
        index: -1,
      });
    }
    const index = data?.items.findIndex(item => item.vehicleNotificationId === notificationId) ?? -1;
    if (notificationId === expandedRow.id) {
      setExpandedRow({
        id: '',
        index: -1,
      });
    } else {
      setExpandedRow({
        id: notificationId,
        index,
      });
    }
  };

  return {
    expandedRow,
    isValidating,
    localData,
    error,
    data,
    isLoadingRawData,
    notificationRawData,
    toggleAccordion,
    mutate,
    resetPagination,
    selectedNotificationIds,
    resetView,
    tableSelectionProps,
    rowSelectionState,
  };
};
