import Imprint from './imprint';
import PrivacyPolicy from './privacy-policy';
import Terms from './terms';

export const LEGAL_ROUTES = [
  {
    path: '/imprint',
    component: Imprint,
    name: 'imprint',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    name: 'privacy',
  },
];
