import { ThemeProps } from '@rjsf/core';
import {
  ArrayFieldTemplateProps,
  FieldProps,
  FieldTemplateProps,
  FormContextType,
  UiSchema,
  WidgetProps,
} from '@rjsf/utils';
import { Dispatch, SetStateAction } from 'react';

import { RiskClass, RiskModuleType } from './risk-module';

export type CromUnits =
  | 'EURO'
  | 'CENTIMETER'
  | 'METER'
  | 'KILOMETER'
  | 'SQ_CENTIMETER'
  | 'SQ_METER'
  | 'SQ_KILOMETER'
  | 'LITER'
  | 'CUBIC_METER'
  | 'GRAM'
  | 'KILOGRAM'
  | 'TON'
  | 'KILOBYTE'
  | 'MEGABYTE'
  | 'GIGABYTE'
  | 'TERABYTE'
  | 'SECOND'
  | 'MINUTE'
  | 'HOUR'
  | 'DAY'
  | 'MONTH'
  | 'YEAR'
  | 'KELVIN'
  | 'FAHRENHEIT'
  | 'CELSIUS'
  | 'PERCENT';

type DisplayTypeDate = 'DATE_PICKER';
type DisplayTypeDropdown = 'DROP_DOWN_SINGLE' | 'DROP_DOWN_MULTI' | 'DROP_DOWN_PATTERN';
type DisplayTypeGeneric = 'OBJECT' | 'RADIO_BUTTON' | 'DATE_PICKER';
type DisplayTypeRiskObject = 'RISK_OBJECT_DROP_DOWN_SINGLE' | 'RISK_OBJECT_DROP_DOWN_MULTI';
type DisplayTypeBoolean = 'RADIO_BUTTON';
export type DisplayTypeText = 'TEXT_AREA' | 'TEXT_INPUT';
export type DisplayTypeNumeric = 'TEXT_INPUT_FLOAT' | 'TEXT_INPUT_INTEGER';
type DisplayTypeTable = 'SIMPLE_TABLE' | 'COMPLEX_TABLE';
type DisplayTypeFileUpload = 'FILE_UPLOAD' | 'LIST_FILE_UPLOAD';
type DisplayTypeList =
  | 'LIST_DATE_PICKER'
  | 'LIST_TEXT_AREA'
  | 'LIST_TEXT_INPUT'
  | 'LIST_TEXT_INPUT_FLOAT'
  | 'LIST_TEXT_INPUT_INTEGER';
type DisplayTypeHSNTSN = 'HSN_TSN';

export type DisplayType =
  | 'INFO_BOX'
  | DisplayTypeGeneric
  | DisplayTypeTable
  | DisplayTypeFileUpload
  | DisplayTypeList
  | DisplayTypeDropdown
  | DisplayTypeDate
  | DisplayTypeBoolean
  | DisplayTypeText
  | DisplayTypeNumeric
  | DisplayTypeRiskObject
  | DisplayTypeHSNTSN;

export type Translation = {
  country: string;
  translation: string;
};

export type GroupHeader = {
  id: string;
  translations: Translation[];
};

//TODO: We can probably remove some of optional flags here, but let's do it when we will have one JSON schema test sample data
export type CromGui = {
  startFromNewRow?: boolean;
  displayType: DisplayType;
  name?: { displayName: string; translations: Translation[] };
  orderNumber?: number;
  tooltip?: {
    text: string;
    translations: Translation[];
  };
  tabId?: string;
  tabs?: {
    id: string;
    translations: Translation[];
  }[];
  tableHeaderOrder?: number;
  shortDescription?: {
    paths: string[];
    format: string;
  };
  valueHint?: {
    text: string;
    translations: Translation[];
  };
  groupHeaderId?: string;
  referencableRiskClasses?: string[];
};

//TODO: We can probably remove some of optional flags here, but let's do it when we will have one JSON schema test sample data
type CromMeta = {
  reference?: { class: RiskClass; module: RiskModuleType };
  isRelevant?: boolean;
  relevanceForDataPrivacy?: number;
  tags?: string[];
  unit?: CromUnits;
};

export type CromType = ['object'] | 'string' | 'boolean' | 'object' | 'number' | 'array' | 'integer';
export type CromJsonSchemaProperties = Record<string, CromJsonSchema>;
export type CromJsonSchemaCondition = {
  [key: string]: {
    not?: unknown;
    properties?: unknown;
    allOf?: unknown[];
    oneOf?: unknown[];
    anyOf?: unknown[];
    else?: unknown;
    then?: unknown;
    required?: string[];
    [key: string]: any;
  };
};

//TODO: We can probably remove some of optional flags here, but let's do it when we will have one JSON schema test sample data
export type CromJsonSchema = {
  $async?: boolean;
  $defs?: { [key: string]: CromJsonSchema };
  $id?: string;
  $ref?: string;
  $schema?: string;
  cromGui?: CromGui;
  cromMeta?: CromMeta;
  default?: string | boolean | number;
  description?: string;
  enum?: string[];
  enumTranslations?: { name: string; translations: Translation[] }[];
  format?: 'date' | 'uri' | 'email';
  items?: Record<string, unknown>;
  length?: number;
  maxItems?: number;
  maxLength?: number;
  maximum?: number;
  minItems?: number;
  minLength?: number;
  minimum?: number;
  pattern?: string;
  precision?: number;
  properties?: CromJsonSchemaProperties;
  readOnly?: boolean;
  required?: string[];
  title?: string;
  type?: CromType;
  uniqueItems?: boolean;
  const?: boolean | string | number | null;
  allOf?: CromJsonSchemaCondition[];
  anyOf?: CromJsonSchemaCondition[];
  oneOf?: CromJsonSchemaCondition[];
  formatExclusiveMaximum?: string;
  formatExclusiveMinimum?: string;
  exclusiveMinimum?: number;
  exclusiveMaximum?: number;
  formatMinimum?: string;
  formatMaximum?: string;
  contains?: {
    const: boolean | string | number | null;
  };
  groupHeaders?: GroupHeader[];
};

export type CromUiJsonSchema = Record<string, UiSchema>;

type PropertyContext = {
  touched?: boolean;
  isRelevant?: boolean;
};

export type CromFormData = Record<string, any>;

export type CromFormContext = Record<string, PropertyContext> & {
  formSubmitted?: boolean;
  riskClass?: string;
  setFormContext?: Dispatch<SetStateAction<CromFormContext>>;
  rerenderForm?: () => void;
  setFormData?: Dispatch<SetStateAction<CromFormData | undefined>>;
};

export function assertCrom(condition: unknown): asserts condition is CromJsonSchema {
  if (!condition) {
    throw new Error('There is a missing CROM property');
  }
}

export type CromWidgetProps = WidgetProps<any, CromJsonSchema>;
export type CromFieldsProps<TData = any, TContext extends FormContextType = any> = FieldProps<
  TData,
  CromJsonSchema,
  TContext
>;
export type CromFieldTemplateProps = FieldTemplateProps<any, CromJsonSchema>;
export type CromThemeProps = ThemeProps<any, CromJsonSchema>;
export type CromArrayFieldTemplateProps = ArrayFieldTemplateProps<any, CromJsonSchema>;

export type GetCromSuccessResponse = {
  crom: CromJsonSchema;
  version: string;
};
