import { Dialog } from '@corify/components/dialog/dialog';
import { Icon } from '@corify/components/icon/icon';
import { Checkbox } from '@corify/components/inputs/checkbox/checkbox';
import { HeaderCell } from '@corify/components/table/components/header-cell';
import { RowExpandButton } from '@corify/components/table/components/row-expand-button';
import { withTableProvider } from '@corify/components/table/provider/with-table-provider';
import { endpoints } from '@corify/constants/endpoints';
import { NotificationPageRef } from '@corify/pages/fleet-management/common/notification-page';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { baseColumns, DEFAULT_NOTIFICATION_TABLE_PROPS } from '../common/constants';
import { NotificationPageV2 } from '../common/notification-page-v2';
import NotificationWarningAndReload from '../common/notification-warning-reload';
import { GDVNotification, NotificationColumn } from '../types';
import AvailableDataActionButton from './available-data-action-button';
import { PauseNotificationsDialog } from './pause-notifications-dialog';

const columnKeys: NotificationColumn[] = [...baseColumns, 'approveAutomatically', 'importTime'];

const AvailableDataV2 = () => {
  const { t } = useTranslation();
  const notificationPageRef = useRef<NotificationPageRef>(null);

  const [notificationsToPause, setNotificationsToPause] = useState<GDVNotification[]>([]);

  return (
    <NotificationPageV2
      ref={notificationPageRef}
      headerTitle={t('fleetManagement.navigation.availableData')}
      columnKeys={columnKeys}
      endpoint={endpoints.cfm.notifications.available}
      actionButton={({ selectedIdsForAction, resetView, data }) => (
        <AvailableDataActionButton
          selectedIdsForAction={selectedIdsForAction}
          resetView={resetView}
          onNotificationsPause={() => {
            const selectedNotifications = data?.items?.filter(item =>
              selectedIdsForAction.includes(item.vehicleNotificationId)
            );

            if (selectedNotifications?.length) {
              setNotificationsToPause(selectedNotifications);
            }
          }}
          allSelectedNotificationsReadyForImport={
            data
              ? data.items
                  .filter(item => selectedIdsForAction.includes(item.vehicleNotificationId))
                  .every(
                    (item: GDVNotification) =>
                      item.assignedCustomer &&
                      item.selectedEnterprise &&
                      typeof item?.vehicleRiskIndex == 'number' &&
                      item?.vehicleRiskIndex >= 0
                  )
              : false
          }
        />
      )}
      dialog={({ resetView }) => {
        const handlePauseDialogClose = async (success: boolean = false) => {
          setNotificationsToPause([]);

          if (success) {
            resetView();
          }
        };

        return (
          <Dialog isOpen={!!notificationsToPause.length} onClose={handlePauseDialogClose}>
            <PauseNotificationsDialog onDialogClose={handlePauseDialogClose} notifications={notificationsToPause} />
          </Dialog>
        );
      }}
      prependColumns={[
        {
          id: 'vehicleNotificationId',
          size: 191,
          header: ({ table }) => (
            <HeaderCell
              topContent={
                <Checkbox
                  label=""
                  classes={{ icon: 'ml-[40px]' }}
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.toggleAllRowsSelected}
                />
              }
            />
          ),
          cell: ({ row }) => (
            <div className="flex justify-between px-6 py-2">
              <div className="flex items-center gap-3 py-2">
                <RowExpandButton
                  onClick={() => row.toggleExpanded()}
                  isExpanded={row.getIsExpanded()}
                  ariaLabel={row.getIsExpanded() ? 'Collapse' : 'Expand'}
                />
                <Checkbox
                  label=""
                  testId={`select-${row.original.vehicleNotificationId}`}
                  checked={row.getIsSelected()}
                  classes={{ label: 'pl-0' }}
                  onChange={row.getToggleSelectedHandler()}
                />
                <Icon
                  className="h-[16px] w-[16px] cursor-pointer"
                  name="pause"
                  onClick={() => setNotificationsToPause([row.original])}
                />
                <NotificationWarningAndReload row={row.original} reloadPage={notificationPageRef.current?.reloadPage} />
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

const AvailableDataWrapV2 = withTableProvider(AvailableDataV2, DEFAULT_NOTIFICATION_TABLE_PROPS);

export default AvailableDataWrapV2;
