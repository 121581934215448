import { cn } from '@corify/helpers/cn';
import { ComponentProps } from 'react';

import { TextField } from './text-field';

type Props = Partial<ComponentProps<typeof TextField>> & {
  additionalClasses?: (string | undefined)[];
  shouldBeRightAligned?: boolean;
  inputValue: unknown;
  compact?: boolean;
  hideDefaultBorder?: boolean;
};

export const getInputClassNames = ({
  errorMessage,
  isRequired,
  isReadOnly,
  inputValue,
  adornmentLeft,
  adornmentRight,
  additionalClasses = [],
  shouldBeRightAligned = false,
  compact = false,
  hideDefaultBorder = false,
}: Props) =>
  cn(
    'h-[52px] outline-none p-4 placeholder:text-darkGrey text-sm text-black w-full border',
    {
      'hover:border-purple': !isReadOnly,
      'p-2 h-[50px]': compact,
      'border-corifyRed': errorMessage,
      'focus:bg-white bg-corifyFormError': errorMessage || (isRequired && (inputValue ?? '') === ''),
      'border-grey focus:border-purple': !errorMessage,
      'pl-12': !!adornmentLeft,
      'pr-16': !!adornmentRight,
      'text-right': shouldBeRightAligned,
      'bg-lighterGrey border-lighterGrey cursor-default': isReadOnly,
      'hover:bg-white': isRequired && !isReadOnly,
      'border-white': hideDefaultBorder && !errorMessage,
    },
    additionalClasses
  );
